import styled from "styled-components";

export const ScoreContainer = styled.div`
    background-color: #d7f5d7;
    color: #6d6d6d;
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 20px;
    display: inline-block;
    font-family: 'Arial', sans-serif;

    & strong {
        color: #000;
    }
`