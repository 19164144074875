import { Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Send } from '@styled-icons/ionicons-sharp/Send';
import { NormalSpan, SmallSpan } from 'components/Common/StyledSpans';
import ConfirmButton from 'containers/GPTChatBotContainer/Components/ConfirmButton';
import FeedbackSystem from 'containers/GPTChatBotContainer/Components/FeedbackSystem';
import { ChatGptUserType, CustomElementsName, useGPTBot } from 'containers/GPTChatBotContainer/useGPTChatBot';
import React, { useEffect, useMemo, useRef } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import styled from 'styled-components';
import { CHATGPTBOTSTATE } from 'types';
import { SIDEBAR_WIDTH } from 'utilities/constants';

const StyledChatContainer = styled.div`
    display: flex;
    background: white;
    width: 100%
    transition: 1s;
    flex-direction: column;
    padding-left: 60px;
    padding-right: 25px;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 57px);
    .chat-window {
        display: flex;
        flex-direction: column;
        height: 90%;
        width: 100%;
        border-radius: 5px
    }
    .chat-content {
    }
    .chat-bot-header {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background-color: #efefef;
        display: flex;
        align-items: center;
        font-size: .85rem;
        color: #514f4f;
        padding: 12.5px;
        font-weight: 700;
        justify-content: space-between;
    }
    .historyContainer {
        border-left: 2px solid #efefef;
        border-right: 2px solid #efefef;
        padding: 10px;
        overflow-y: scroll;
        flex-grow: 1;
    }
    .user-msg, .bot-msg {
        padding: 10px;
        border-radius: 5px;
        font-size: .9rem;
        position: relative;
        text-align: left;
        max-width: 80%;
    }
    .bot-msg {
        background-color: #2898ec;
        color: #fff;
    }
    .arrow-right, .arrow-left {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        position: absolute;
        top: 13px;
    }
    .arrow-left {
        left: -7px;
        border-right: 8px solid #2898ec;
    }
    .arrow-right {
        right: -7px;
        border-left: 8px solid #f1f1f1;
    }
    .user-msg {
        background-color: #f1f1f1;
        color: #585858;
    }
    .bot-msg-container, .user-msg-container {
        display: flex;
        margin: 15px 0;
    }
    .bot-msg-container {
        justify-content: flex-start;
    }
    .user-msg-container {
        justify-content: flex-end;
    }
    .messaging-user-symbol, .messaging-bot-symbol {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .messaging-user-symbol {
        margin-left: 12.5px;
        background-color: #d8d8d8;
    }
    .messaging-bot-symbol {
        margin-right: 12.5px;
        background-color: #3d4e8d;
        color: #fff;
    }
    .pre-line-white-space {
        white-space: pre-wrap;
    }
`;

const GetMessageJSX = (props: { from: ChatGptUserType, content: any, containsCustomElement?: boolean }) => {
    const { from, content, containsCustomElement } = props;

    return (
        <>
            {from === ChatGptUserType.USER &&
                <div className="user-msg-container">
                    <div className={containsCustomElement ? '' : 'user-msg'}>
                        {!containsCustomElement ?
                            <span className='pre-line-white-space' dangerouslySetInnerHTML={{ __html: content }} />
                            : content}
                        {!containsCustomElement && <div className="arrow-right"></div>}
                    </div>
                    <div style={{ visibility: containsCustomElement ? 'hidden' : 'visible' }}>
                        <div className="messaging-user-symbol">U</div>
                    </div>
                </div>
            }
            {from === ChatGptUserType.BOT &&
                <div className="bot-msg-container">
                    <div style={{ visibility: containsCustomElement ? 'hidden' : 'visible' }}>
                        <div className="messaging-bot-symbol">B</div>
                    </div>
                    <div className={containsCustomElement ? '' : 'bot-msg'}>
                        {!containsCustomElement ?
                            <span className='pre-line-white-space' dangerouslySetInnerHTML={{ __html: content }} />
                            : content}
                        {!containsCustomElement && <div className="arrow-left"></div>}
                    </div>
                </div>
            }
        </>
    )
}

const getCustomElementFromName = (elementName: CustomElementsName, props: any) => {
    if (elementName === CustomElementsName.ConfirmButton) {
        return (
            <ConfirmButton {...props} />
        )
    }
    if (elementName === CustomElementsName.FeedbackSystem) {
        return (
            <FeedbackSystem {...props} />
        )
    }
    return null;
}

const CHAT_WITH_GPT = 'ChatWithGPT';

const GPTChatBot = (props: any) => {

    const endOfMessages = useRef<any>();
    const { chatGPTBotState, hideBot } = useGPTBot();

    useEffect(() => {
        scrollToBottom();
    }, [chatGPTBotState.messages, chatGPTBotState.loadingBotResponse])

    const scrollToBottom = () => {
        endOfMessages?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    };

    const getPropsFromCustomElementName = (elementName: CustomElementsName, chatGPTBotState: CHATGPTBOTSTATE) => {
        if (elementName === CustomElementsName.ConfirmButton) {
            return ({
                getGPTResponce: (clickedBtnMsg: string) => {
                    props.getGPTResponce(chatGPTBotState.messages[0].content + clickedBtnMsg, false);
                }
            })
        }
        return {};
    }

    return (
        <StyledChatContainer>
            <div className="chat-window">
                <div className='chat-bot-header'>
                    <span style={{ marginTop: "-5px" }}>{chatGPTBotState.botName}</span>
                    <Button onClick={hideBot}>✖</Button>
                </div>
                <div className="historyContainer">
                    <>
                        {
                            useMemo(() => chatGPTBotState.messages?.map((message, index: number) => {
                                return (
                                    <GetMessageJSX
                                        key={CHAT_WITH_GPT + index}
                                        from={message.from}
                                        content={
                                            message.containsCustomElement ?
                                                getCustomElementFromName(message.customElementName!, {
                                                    ...getPropsFromCustomElementName(message.customElementName!, chatGPTBotState),
                                                    ...message.props
                                                })
                                                : message.content
                                        }
                                        containsCustomElement={message.containsCustomElement}
                                    />
                                )
                            }), [chatGPTBotState.messages])
                        }
                        {
                            chatGPTBotState.loadingBotResponse &&
                            <div className="bot-msg-container">
                                <div>
                                    <div className="messaging-bot-symbol">B</div>
                                </div>
                                <div className={'bot-msg'}>
                                    <BeatLoader color={'#fff'} loading={true} />
                                    <div className="arrow-left"></div>
                                </div>
                            </div>
                        }
                    </>
                    <div ref={endOfMessages}></div>
                </div>
                <Autocomplete
                    freeSolo
                    disableClearable
                    /* { query: 'a new input', frequency: '3' } */
                    options={props.freqAskedQuestion}
                    key={props.clearBotMessage}
                    onSelect={(e: any) => {
                        props.onUserInputChange(e.target.value)
                    }}
                    renderOption={(option: any) => (
                        <div key={option.faqId}>
                            <NormalSpan dangerouslySetInnerHTML={{ __html: option.query }} />
                            <SmallSpan>{`(asked by ${option.frequency} candidates)`}</SmallSpan>
                        </div>
                    )}
                    getOptionLabel={option => option.query || ''}
                    disabled={chatGPTBotState.loadingBotResponse}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="none"
                            variant="outlined"
                            placeholder="Enter your query"
                            onChange={(e) => props.onUserInputChange(e.target.value)}
                            onKeyPress={props.onKeyPressInInput}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: !chatGPTBotState.loadingBotResponse && <Send
                                    width={'30px'}
                                    fill="#5B94E3"
                                    style={{ cursor: 'pointer' }}
                                    onClick={props.onSendBtnClicked} />
                            }}
                        />
                    )}
                />
            </div>
        </StyledChatContainer >
    )
}

export default GPTChatBot;