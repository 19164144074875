import { Chip, Fade, Menu, MenuItem, Tooltip, emphasize, styled } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { ExpandMore } from '@styled-icons/material-outlined';
import * as React from 'react';

interface BreadCrumbsProps {
    items: BreadCrumbItem[];
}

export interface IOption { id: string, label: string, onClick: (id: string) => void; };

export interface BreadCrumbItem {
    id: string;
    value: string;
    options?: IOption[];
    color?: string;
}

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor = "#f5f5f5";
    return {
        backgroundColor,
        height: 20,
        fontSize: 12,
        color: "rgba(0, 0, 0, 0.87)",
        fontWeight: 400,
        overflow: 'visible',
        '&:hover': {
            overflow: 'visible',
        },
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
        '& span': {
            maxWidth: 150,
        }
    };
}) as typeof Chip;

interface IOptionComponent {
    element: any,
    handleClick: (event: any) => void;
    open: boolean;
    options: IOption[]
}

const OptionComponent = ({ open, element, handleClick, options }: IOptionComponent) => {
    return <Menu
        id="fade-menu"
        MenuListProps={{
            'aria-labelledby': 'fade-button',
        }}
        anchorEl={element}
        open={open}
        onClose={handleClick}
        TransitionComponent={Fade}
    >
        {options.map(option => <MenuItem onClick={(event: any) => { handleClick(event); option.onClick(option.id) }}>{option.label}</MenuItem>)}
    </Menu>
}


const BreadCrumbs = ({ items }: BreadCrumbsProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [options, setOptions] = React.useState<IOption[]>([]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, item: BreadCrumbItem) => {
        setAnchorEl(event.currentTarget);
        setOptions(item.options || []);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                {items.map(item =>
                    <Tooltip
                        title={item.value.length > 10 ? item.value : ''}
                        key={item.id}
                        enterDelay={item.value.length > 10 ? 500 : 0}
                    >
                        <div style={{ display: 'inline-block' }} ref={(el) => {
                            if (el) {
                                if (el.scrollWidth > el.clientWidth) {
                                    el.setAttribute('data-tooltip', item.value);
                                } else {
                                    el.removeAttribute('data-tooltip');
                                }
                            }
                        }}>
                            <StyledBreadcrumb
                                style={{ color: item.color }}
                                label={item.value}
                                deleteIcon={<ExpandMore />}
                                onDelete={item.options && item.options.length > 1 ? (event: any) => handleClick(event, item) : undefined}
                            />
                        </div>
                    </Tooltip>
                )}
            </Breadcrumbs>
            {options.length > 1 && <OptionComponent element={anchorEl} open={open} handleClick={handleClose} options={options} />}
        </div>
    );
};

export default BreadCrumbs;
