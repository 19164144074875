import { Sidebar } from 'containers';
import { useLoader } from 'context/loaderDots';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { RootState } from 'store';
import { getCandidateProfile, updateCandidateProfile } from 'store/evaluationPlatform';
import { CANDIDATE_PROFILE, EXPERTISE } from 'types/EvaluationPlatform';
import { Candidate_Id } from 'utilities/constants';
import { ExpertiseTable, FieldLabel, FieldValue, ProfileFieldContainer, ProfilePage, SectionDivider, Step1Container } from './UserProfile.styles';

type Props = {
    candidateProfile: CANDIDATE_PROFILE | undefined;
    updateCandidateProfile: Function;
    getCandidateProfile: Function;
    loading: boolean;
    error: string | undefined | null;
};

const _UserProfile = ({
    candidateProfile,
    getCandidateProfile,
    updateCandidateProfile,
    loading,
    error
}: Props) => {
    const loader = useLoader();

    useEffect(() => {
        const candidateId = getValueBrowserStorage(Candidate_Id);
        getCandidateProfile(candidateId);
    }, []);

    useEffect(() => {
        loading ? loader.showLoader() : loader.hideLoader();
    }, [loading]);

    return (
        <ProfilePage>
            <div className="content">
                <div className="main mx-auto">
                    {candidateProfile && (
                        <Step1Container>
                            <ProfileField label='Name' value={candidateProfile.fullname} />
                            <ProfileField label='Email' value={candidateProfile.email} />
                            {candidateProfile.linkedinProfile && (
                                <ProfileField label='LinkedIn Profile URL' value={candidateProfile.linkedinProfile} />
                            )}
                            {candidateProfile.expectedSalary && (
                                <ProfileField label='Expected Salary (in USD)' value={candidateProfile.expectedSalary} />
                            )}
                            {candidateProfile.expertises?.length && (
                                <ExpertiseField label='Expertise' expertise={candidateProfile.expertises} />
                            )}
                            <SectionDivider />
                        </Step1Container>
                    )}
                </div>
            </div>
        </ProfilePage>
    )
};

const mapStateToProps = (state: RootState) => {
    const { candidateProfile, loading, error } = state.evaluationPlatform;
    return {
        candidateProfile,
        loading,
        error
    }
};

const ProfileField = ({ label, value }: { label: string, value: string }) => {
    return (
        <ProfileFieldContainer>
            <FieldLabel>{label}:</FieldLabel>
            <FieldValue>{value}</FieldValue>
        </ProfileFieldContainer>
    )
};

const ExpertiseField = ({ label, expertise = [] }: { label: string, expertise: EXPERTISE[] | undefined }) => {
    return (
        <ProfileFieldContainer>
            <FieldLabel>{label}:</FieldLabel>
            <div>
                <ExpertiseTable>
                    <tr>
                        <th>Skill</th>
                        <th>Rating</th>
                    </tr>
                    {expertise.map((value) => (
                        <tr>
                            <td><FieldValue>{value.skill}</FieldValue></td>
                            <td><FieldValue>{value.rating}</FieldValue></td>
                        </tr>
                    ))}
                </ExpertiseTable>
            </div>
        </ProfileFieldContainer>
    )
};

const mapDispatchToProps = {
    updateCandidateProfile,
    getCandidateProfile
};

export const UserProfile = connect(mapStateToProps, mapDispatchToProps)(_UserProfile);