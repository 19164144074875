import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLoader } from 'context/loaderDots';
import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { evaluationPlatformService } from 'services';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { RootState } from 'store';
import { IGetTrackJobs, ITrackJob } from 'types';
import { Candidate_Id, Candidate_Track_Id, DEFAULT_TOKEN } from "utilities/constants";
import JobComponent from "./JobComponent";
import { useHistory } from "react-router-dom";
import { getTreeCheckedKeys } from "utilities/commonUtils";

const useStyles = makeStyles((theme) => ({
    parent: {
        [theme.breakpoints.down("sm")]: {
            rowGap: "20px",
        },
        [theme.breakpoints.only("xs")]: {
            gap: "20px",
        },
    },
}));

const JobsComponent = () => {
    const classes = useStyles();
    const history = useHistory();
    const loader = useLoader();
    const [jobs, setJobs] = useState<ITrackJob[]>([]);
    const { trackId } = useSelector((state: RootState) => state.payment);
    const candidateTrackId = getValueBrowserStorage(Candidate_Track_Id);
    const candidateId = getValueBrowserStorage(Candidate_Id);

    const onApplyNowClick = async (item: ITrackJob) => {
        if (candidateId) {
            if (!item.isQualified) {
                history.push(`/meetings?jobId=${item.jobId}`);
            } else {
                const input = {
                    token: DEFAULT_TOKEN,
                    candidateId,
                    jobId: item.jobId
                };
                loader.showLoader();
                await evaluationPlatformService.applyForJob(input);
                await getJobs();
                loader.hideLoader();
            }
        }
    }

    const getJobs = async () => {
        if (candidateTrackId && trackId) {
            const input: IGetTrackJobs = {
                token: DEFAULT_TOKEN,
                candidateTrackId,
                trackId
            };
            try {
                loader.showLoader();
                const response = await evaluationPlatformService.getTrackJobs(input);
                loader.hideLoader();
                setJobs(response.output.jobs);
            }
            catch (error: any) {
                console.error(error);
                loader.hideLoader();
            }

        }
    }

    useLayoutEffect(() => {
        getJobs();
    }, [])

    return <div>
        <Grid container className={classes.parent}>
            {jobs.map((item, index) => (
                <JobComponent
                    key={`Track_${index}`}
                    job={item}
                    onApply={onApplyNowClick}
                />
            ))}
        </Grid>
    </div>
}

export default JobsComponent;