import { Refresh } from '@styled-icons/boxicons-regular';
import { EllipsisVerticalCircle } from '@styled-icons/ionicons-solid';
import { IconContainer } from 'components/Common/IconContainer';
import { ChatGptUserType, ConversationViewType } from 'containers/GPTChatBotContainer/useGPTChatBot';
import { StyledButton } from 'containers/QuestionCodingPane/Coding/AnswerCTA';
import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { clarifyQuestion } from 'services/evaluationPlatform';
import { RootState, useAppDispatch } from 'store';
import { setInterviewMeConversation } from 'store/splitView';
import styled from 'styled-components';
import { IClarifyQuestion } from 'types';
import { DEFAULT_TOKEN } from 'utilities/constants';
import { IConversation, IConversationScore } from '.';
import RecordingItem from 'components/AudioRecorder/components/recordings-list/RecordingItem';

interface IInterviewMeChatbox {
    conversation: IConversation[];
    loadingBotResponse: boolean;
    handleRegenerateResponse: (message: IConversation) => void;
    removeQuestionScrollEffect?: boolean;
}

const StyledChatContainer = styled.div`
        padding: 0 10px;
        overflow-y: hidden;
        flex-grow: 1;
    .user-msg, .bot-msg {
        padding: 10px;
        border-radius: 5px;
        font-size: .9rem;
        position: relative;
        text-align: left;
        max-width: 80%;
    }
    .bot-msg {
        background: linear-gradient(72deg, rgb(239, 245, 253) 0%, rgb(238, 238, 254) 79%);
        color: rgb(4, 3, 72);
    }
    .arrow-right, .arrow-left {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        position: absolute;
        top: 13px;
    }
    .arrow-left {
        left: -7px;
        border-right: 8px solid rgb(246, 248, 255);
    }
    .arrow-right {
        right: -7px;
        border-left: 8px solid #f1f1f1;
    }
    .user-msg {
        background: linear-gradient(108deg, rgb(246, 248, 255) 0%, rgb(220, 220, 220) 70%);
        color: rgb(60, 60, 60);
    }
    .bot-msg-container, .user-msg-container {
        display: flex;
        margin: 15px 0;
    }
    .bot-msg-container {
        justify-content: flex-start;
    }
    .user-msg-container {
        justify-content: flex-end;
    }
    .messaging-user-symbol, .messaging-bot-symbol {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .messaging-user-symbol {
        margin-left: 12.5px;
        background-color: #d8d8d8;
    }
    .messaging-bot-symbol {
        margin-right: 12.5px;
        background-color: #3d4e8d;
        color: #fff;
    }
    .pre-line-white-space {
        white-space: pre-wrap;
    }
    .tab-message-styling {
        display: inline-block;
        background-color: #e4b366;
        color: white;
        padding: 10px 20px;
        border-radius: 25px;
        font-weight: bold;
        width: max-content;
    }
`;

export const InterviewMeChatBox = ({ conversation, loadingBotResponse, handleRegenerateResponse, removeQuestionScrollEffect }: IInterviewMeChatbox) => {
    const dispatch = useAppDispatch();
    const { candidateTrackId, currentAnswerId } = useSelector(
        (state: RootState) => state.splitView.bot);
    const [loadingClarify, setLoadingClarify] = useState<boolean>(false);
    const endOfMessages = useRef<any>();

    const handleClarifyQuestion = async () => {
        if (currentAnswerId && candidateTrackId) {
            const payload: IClarifyQuestion = {
                token: DEFAULT_TOKEN,
                questionAnswerId: currentAnswerId,
                candidateTrackId,
            }
            try {
                setLoadingClarify(true);
                const response = await clarifyQuestion(payload);
                const data = response.output;
                const followUpConversation: IConversation = { type: ChatGptUserType.BOT, text: data.aiAnswer, score: data.score };
                dispatch(setInterviewMeConversation([...conversation, followUpConversation]))
            } catch (error: any) {
                console.error("error with clarify API", error);
            } finally {
                setLoadingClarify(false);
            }
        }
    }

    useEffect(() => {
        let timeoutId: any = null;
        if (!removeQuestionScrollEffect) {
            timeoutId = setTimeout(() => {
                endOfMessages?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, 10)
        }
        return () => {
            timeoutId && clearTimeout(timeoutId);
        }
    }, [conversation, loadingBotResponse])

    const renderBotMessage = (message: IConversation, index: number) => {
        if (message.type !== ChatGptUserType.BOT) return null;

        const renderScore = () => {
            if (!message.score || !message.score.length) return null;

            return (
                <>
                    <span>Your score following the last response is:</span><br />
                    {message.score.map((item: IConversationScore, index: number) => (
                        <><span key={index}>
                            {index + 1}. For <strong>{item.capability},</strong> you scored <strong>{item.score}/10</strong>.
                        </span><br /></>
                    ))}
                    <br />
                </>
            );
        };

        const renderFollowUpQuestion = () => {
            if (!message.text) return null;

            return (
                <>
                    <span dangerouslySetInnerHTML={{ __html: message.text }}></span>
                </>
            );
        };

        if (message.givenAnswerType === ConversationViewType.TAB) {
            return (
                <StyledChatContainer id={message.id ?? ""}>
                    <div className='bot-msg-container tab-message-styling'>
                        {message.text}
                    </div>
                </StyledChatContainer>
            )
        }

        return (
            <StyledChatContainer id={message.id ?? ""}>
                <div key={message.text + index} className="bot-msg-container">
                    <div>
                        <div title='Interviewer' className="messaging-bot-symbol">I</div>
                    </div>
                    <div className='bot-msg'>
                        <div className='pre-line-white-space'>
                            {renderScore()}
                            {renderFollowUpQuestion()}
                            {message.comments && <span>{message.comments}</span>}
                        </div>
                        {message.isError && (
                            <StyledButton color='primary' onClick={() => handleRegenerateResponse(message)}>
                                <IconContainer icon={Refresh} /> Regenerate Response
                            </StyledButton>
                        )}
                        {(!message.hideAskExplanation && index === conversation.length - 1 || (conversation[conversation.length - 1].type === ChatGptUserType.USER && index === conversation.length - 2)) && (
                            <StyledButton paddingLeft="0" color='primary' onClick={handleClarifyQuestion}>
                                {loadingClarify ? <Spinner size='sm' className='mr-2' animation='border' />
                                    : <IconContainer icon={EllipsisVerticalCircle} />}Explain Further
                            </StyledButton>
                        )}
                        <div className="arrow-left"></div>
                    </div>
                </div>
            </StyledChatContainer>
        );
    };

    const renderUserMessage = (message: IConversation, index: number) => {
        if (message.type !== ChatGptUserType.USER) return null;

        let messageHtml = <span className='pre-line-white-space' dangerouslySetInnerHTML={{ __html: message.text ? (message.text + (message.comments ? '\n' + message.comments : '')) : (message.comments || '') }} />;

        if (message.givenAnswerType === ConversationViewType.AUDIO && message.answerAudioDetails) {
            messageHtml = <RecordingItem
                key={message.answerAudioDetails.url}
                isReadOnly={true}
                recordKey={message.answerAudioDetails.url!}
                isDeleted={false}
                audio={message.answerAudioDetails.url}
                onDelete={() => { }}
                undoDelete={() => { }}
            />
        }

        return (
            <div key={message.text + index} className="user-msg-container">
                <div className={`user-msg ${message.givenAnswerType === ConversationViewType.AUDIO ? 'w-50' : ''}`}>
                    {messageHtml}
                    <div className="arrow-right"></div>
                </div>
                <div>
                    <div className="messaging-user-symbol">U</div>
                </div>
            </div>
        );
    };

    return <StyledChatContainer id='InterviewMeChatBox_Parent_Container'>
        {conversation.map((message, index) => {
            return <>
                {message.type === ChatGptUserType.USER &&
                    renderUserMessage(message, index)}
                {message.type === ChatGptUserType.BOT &&
                    renderBotMessage(message, index)}
            </>
        })}
        {loadingBotResponse && <div className="bot-msg-container">
            <div>
                <div className="messaging-bot-symbol">B</div>
            </div>
            <div className={'bot-msg'}>
                <BeatLoader color={'rgb(4, 3, 72)'} loading={true} />
                <div className="arrow-left"></div>
            </div>
        </div>}
        <div ref={endOfMessages}></div>
    </StyledChatContainer>
}