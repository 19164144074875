import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { useQuestions } from "components/Question/useQuestions";
import { ChatGptUserType, ConversationViewType } from "containers/GPTChatBotContainer/useGPTChatBot";
import { IConversation } from "containers/InterviewMeContainer";
import { StyledButton } from "containers/QuestionCodingPane/Coding/AnswerCTA";
import AnswerPane from "containers/QuestionCodingPane/Coding/AnswerPane";
import SplitPaneWrapper from "containers/QuestionCodingPane/SplitPaneWrapper";
import { Capability, ICandidateTrackData, IQuestion } from "containers/QuestionCodingPane/types";
import { useMessagePopup } from "context/messagePopup";
import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getValueBrowserStorage } from "services/browserStorageService";
import { evaluationPlatformService, submitResponseToAI } from "services/evaluationPlatform";
import { RootState } from "store";
import styled from "styled-components";
import { InterviewMeResponse, ISubmitResponseToAI, PatchHistory } from "types";
import { answerType, Candidate_Id, Candidate_Track_Id, Content_Height, DEFAULT_TOKEN, SAVING_TEXT } from "utilities/constants";
import PitchChatBox from "./PitchChatBox";
import { setCandidateTrackId, setCurrentAnswerId, setInterviewMeConversation } from "store/splitView";
import { updateCapability } from "store/evaluationPlatform";
import { getRandomString } from "utilities/commonUtils";

const StyledChatContainer = styled.div`
    .chat-box-height {
        height: 99%;
    }
`

const QuestionTitle = () => {
    const currentTrack = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack?.[0]);

    return (
        <div className="text-left">
            <h4>Make a Pitch: {currentTrack?.title}</h4>
            <span className="text-muted">You will be asked one question per skill, and each question may have a follow-up to give you a chance to provide even more insights into your expertise.</span>
        </div>
    )
}

type IProps = {
    onlyUseMicrophone: boolean;
    handleQAndAComplete: () => void;
}

type QuestionDetails = {
    capabilityId: string,
    questionId: string,
    answerType: string,
    questionTitle: string,
    questionAnswerId: string,
}

const CandidateQAndA = ({
    onlyUseMicrophone,
    handleQAndAComplete
}: IProps) => {

    const dispatch = useDispatch();
    const message = useMessagePopup();
    const { getExperts, getAnswersList, saveAndUpdateLastActivity } = useQuestions();
    const { enqueueSnackbar } = useSnackbar();

    const candidateTrackId = getValueBrowserStorage(Candidate_Track_Id);
    const candidateId = getValueBrowserStorage(Candidate_Id)!;

    const { candidateTrackId: candidateTrackIdBotState, currentAnswerId: currentAnswerIdBotState } = useSelector((state: RootState) => state.splitView.bot);
    const { conversation } = useSelector((state: RootState) => state.splitView.bot);
    let trackId = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack?.[0]?.trackId);
    let capabilities = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack?.[0]?.capabilities);
    let saving = useSelector((state: RootState) => state.evaluationPlatform.saving);
    const questionStatusText = useSelector((state: RootState) => state.evaluationPlatform.questionStatusText);
    const { code } = useSelector((state: RootState) => state.splitView.coding);
    const { currentAnswerId: splitViewAnswerId } = useSelector((state: RootState) => state.splitView.bot);
    const { currentAnsVersionId, currentQuestionId } = useSelector((state: any) => state.evaluationPlatform);
    const selectedCapabilityId = useSelector((state: RootState) => state.evaluationPlatform.selectedCapabilityId);
    const candidateTrack: ICandidateTrackData = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack[0])
    const selectedCapability: Capability | undefined = candidateTrack?.capabilities?.find(capability => capability.capabilityId === selectedCapabilityId);
    const newCurrentQuestion: IQuestion | undefined = selectedCapability?.questions?.find((question: IQuestion) => question.question._id === currentQuestionId) || selectedCapability?.questions[0];
    const selectedIndex = currentAnsVersionId && newCurrentQuestion?.answers.findIndex((x: any) => x.answer._id === currentAnsVersionId);

    const answerPaneRef = useRef<{
        handleSaveRt: (value: string) => void,
        submitFeedbackHandler: (expertId: string, hideNotification: boolean) => void
    } | undefined>();

    const [lastBotQuestion, setLastBotQuestion] = useState<InterviewMeResponse>();
    const [showScreenBlockLoader, setShowScreenBlockLoader] = useState<boolean>(false);
    const [loadingBotResponse, setLoadingBotResponse] = useState<boolean>(false);
    const [questionPaneSize, setQuestionPaneSize] = useState<string>();
    const [currQuestionDetails, setCurrQuestionDetails] = useState<QuestionDetails>(
        { capabilityId: '', questionId: '', answerType: '', questionTitle: '', questionAnswerId: '' }
    );

    const directory = (candidateId) + '/' + (selectedCapabilityId) + '/' + currentQuestionId + '/recordings/v' + (selectedIndex || 1);

    const setConversation = (conversations: IConversation[]) => {
        dispatch(setInterviewMeConversation(conversations));
    }

    const getFullAnswer = (conversation: IConversation[]) => {
        let answerToSave = [], tabIndex = -1;
        for (let i = conversation.length - 1; i >= 0; i--) {
            if (conversation[i].givenAnswerType === ConversationViewType.TAB) {
                tabIndex = i;
                break;
            }
        }
        for (let i = tabIndex; i < conversation.length; i++) {
            if (conversation[i].type === ChatGptUserType.BOT) {
                answerToSave.push(`<p><b>Q.   ${conversation[i].text}</b></p>`);
            } else {
                answerToSave.push(`<p>A.   ${conversation[i].text}</p><br />`);
            }
        }
        return answerToSave.join("");
    }

    const handleOnSave = async (conversation: IConversation[], response: string, url?: string) => {
        let afterSaveResult: any = null;
        if (currQuestionDetails.answerType !== answerType.CODE && answerPaneRef.current) {
            setShowScreenBlockLoader(true);
            afterSaveResult = await answerPaneRef.current.handleSaveRt(getFullAnswer(conversation));
            setShowScreenBlockLoader(false);
        }
        if (!lastBotQuestion || lastBotQuestion.followUpQuestion) {
            setConversation(conversation);
            const questionAnswerId = afterSaveResult?._id ?? splitViewAnswerId ?? currQuestionDetails.questionAnswerId;
            setCurrQuestionDetails((prev) => ({ ...prev, questionAnswerId }));
            interviewCandidate(
                questionAnswerId,
                currQuestionDetails.questionTitle,
                conversation[conversation.length - 1].text,
                conversation,
                url
            );
        } else {
            askNextQuestion(conversation, currQuestionDetails);
        }
    }

    const handleCodingAnswerSave = () => {
        message.confirm("Are you sure you want to submit this answer?", () => {
            const newMessage = {
                type: ChatGptUserType.USER,
                text: code,
            };
            const updatedConversation = [...conversation, newMessage];
            handleOnSave(updatedConversation, "");
        })
    }

    const getObjForCapability = (text: string, qId: string) => {
        return {
            id: qId + getRandomString() + "Capability",
            text: text,
            type: ChatGptUserType.BOT,
            questionAnswerId: qId,
            givenAnswerType: ConversationViewType.TAB,
            isHistory: true,
            hideAskExplanation: true
        }
    }

    const getObjForQuestion = (text: string, id: string, parentId: string) => {
        return {
            id: id + getRandomString(),
            text: text,
            type: ChatGptUserType.BOT,
            questionAnswerId: id,
            givenAnswerType: ConversationViewType.TEXT,
            isHistory: true,
            hideAskExplanation: true,
            parentEvaluationId: parentId
        }
    }

    const updateCandidateActivity = (capId: string, quesId: string) => {
        saveAndUpdateLastActivity({
            selectedCapabilityId: capId,
            currentQuestionId: quesId,
            preferredAnswerMode: onlyUseMicrophone ? "AUDIO" : "TEXT"
        });
        capId && dispatch(updateCapability(capId));
    }

    const startConversation = () => {
        const botConversation: IConversation[] = [];

        // Adding capability
        const capability = capabilities[0];
        botConversation.push(getObjForCapability(capability.capabilityName, capability.capabilityId));

        const question = capability.questions[0].question;
        const answers = capability.questions[0].answers;
        let questionAnswerId = '';
        if (answers) {
            answers.sort((a: any, b: any) => {
                return b.answer.version - a.answer.version
            })
            questionAnswerId = answers[0]?.answer._id
        }

        botConversation.push(getObjForQuestion(question.title, question._id, capability.capabilityId));
        question.description && botConversation.push(getObjForQuestion(question.description, question._id, capability.capabilityId));

        setCurrQuestionDetails({
            capabilityId: capability.capabilityId,
            questionId: question._id,
            answerType: question.answerType,
            questionTitle: question.title,
            questionAnswerId: questionAnswerId
        });
        updateCandidateActivity(capability.capabilityId, question._id);
        setConversation(botConversation);
    }

    const askNextQuestion = (prevConversations: IConversation[], currQuestionDetailsObj: QuestionDetails) => {
        const { capabilityId, questionId } = currQuestionDetailsObj;
        const capabilityIdx = capabilities?.findIndex((c: any) => c.capabilityId === capabilityId);
        let capability = capabilities?.[capabilityIdx];
        let questionIdx = capability?.questions?.findIndex((q: any) => q.question._id === questionId);
        const botConversation: IConversation[] = [...prevConversations];

        if (capabilities?.length - 1 > capabilityIdx && capability?.questions?.length - 1 <= questionIdx) {
            const nextCapability = capabilities[capabilityIdx + 1];

            questionIdx = -1;
            capability = nextCapability;

            botConversation.push(getObjForCapability(nextCapability.capabilityName, nextCapability.capabilityId));
        }

        if (capability?.questions?.length - 1 > questionIdx) {
            const nextQuestion = capability?.questions?.[questionIdx + 1]?.question;
            const answers = capability?.questions?.[questionIdx + 1]?.answers;
            let questionAnswerId = '';
            if (answers) {
                answers.sort((a: any, b: any) => {
                    return b.answer.version - a.answer.version
                })
                questionAnswerId = answers[0]?.answer._id;
            }

            botConversation.push(getObjForQuestion(nextQuestion.title, nextQuestion._id, capability.capabilityId));
            nextQuestion.description && botConversation.push(getObjForQuestion(nextQuestion.description, nextQuestion._id, capability.capabilityId));
            setCurrQuestionDetails({
                capabilityId: capability.capabilityId,
                questionId: nextQuestion._id,
                answerType: nextQuestion.answerType,
                questionTitle: nextQuestion.title,
                questionAnswerId: questionAnswerId
            });
            updateCandidateActivity(capability.capabilityId, nextQuestion._id);
        }

        if (botConversation.length === prevConversations.length) {
            handleQAndAComplete();
        }
        setConversation(botConversation);
    }

    const handlePaneSizeChange = (paneSize: string) => {
        setQuestionPaneSize(paneSize);
    }

    const handleRegenerateResponse = (message: IConversation) => {
        const updatedConversation = [...conversation];
        updatedConversation.pop();
        const lastUserMessage = updatedConversation[updatedConversation.length - 1];
        setConversation(updatedConversation);

        let latestChatBotQuestion = null;
        const messageIdx = conversation.findIndex(botMsg => botMsg.isError === message.isError && botMsg.comments === message.comments);
        for (let i = messageIdx - 1; i >= 0; i--) {
            const latestConversation = conversation[i];
            if (latestConversation.type === 0) {
                latestChatBotQuestion = latestConversation.text;
                break;
            }
        }
        const question = latestChatBotQuestion ?? currQuestionDetails.questionTitle;
        if (lastUserMessage && candidateTrackId && currQuestionDetails.questionAnswerId && question) {
            interviewCandidate(currQuestionDetails.questionAnswerId, question, lastUserMessage.text, updatedConversation);
        }
    }

    const interviewCandidate = async (currentAnswerId: string, questionTitle: string, currentAnswer: string, conversation: IConversation[], audioUrl?: string) => {
        const payload: ISubmitResponseToAI = {
            token: DEFAULT_TOKEN,
            candidateTrackId: candidateTrackId!,
            questionAnswerId: currentAnswerId,
            question: questionTitle,
            prompt: currentAnswer,
            answer: currentAnswer,
            temperature: 0.7,
            promptBuilderType: 'EVALUATION',
            parentEvaluationId: lastBotQuestion?.parentEvaluationId,
            followUpQuestionId: lastBotQuestion?.followUpQuestionId,
            levelOrder: 3,
            maxSortOrder: 0,
            givenAnswerType: ConversationViewType.TEXT
        };
        if (audioUrl) {
            payload['answerAudioDetails'] = {
                url: audioUrl,
            }
            payload['givenAnswerType'] = ConversationViewType.AUDIO;
        }
        setLoadingBotResponse(true);
        let updatedConversation = [...conversation];
        try {
            const response = await submitResponseToAI(payload);
            const data = response.output as InterviewMeResponse;
            let comments = '';
            if (!data) {
                throw new Error("No data received");
            }
            if (!data.followUpQuestion) {
                try {
                    setShowScreenBlockLoader(true);
                    const res = await handleSubmitForFeedback(updatedConversation);
                    setShowScreenBlockLoader(false);
                    if (!isNil(res)) {
                        askNextQuestion(updatedConversation, currQuestionDetails);
                        setLastBotQuestion(undefined);
                    }
                } catch (e) {
                    enqueueSnackbar('Could not submit answer to expert. Please try again.', {
                        variant: 'error',
                        autoHideDuration: 2500,
                    });
                }
                return;
            }
            setLastBotQuestion(data);
            updatedConversation = [...conversation, {
                type: ChatGptUserType.BOT,
                text: data.followUpQuestion,
                score: undefined,
                comments,
                id: "followup" + getRandomString()
            }];
            setConversation(updatedConversation);
        } catch (exception) {
            console.error(exception);
            let comments = `Error evaluating your response: \n`;
            updatedConversation = [...conversation, {
                type: ChatGptUserType.BOT,
                text: "",
                comments,
                isError: true,
                id: "followup" + getRandomString()
            }];
            setConversation(updatedConversation);
        } finally {
            setLoadingBotResponse(false);
        }
    }

    const handleSubmitForFeedback = async (conversation: IConversation[]) => {
        let res = null;
        if (currQuestionDetails.questionId && selectedCapability) {
            if (currQuestionDetails.answerType !== answerType.CODE) {
                await answerPaneRef.current?.handleSaveRt(getFullAnswer(conversation));
            }
            let answers = getAnswersList(currQuestionDetails.questionId, selectedCapability.capabilityId)?.answers;
            if (answers.length > 1) {
                let lstFeedbackExpertId = answers[1].feedbacks[0].expertId;
                res = await answerPaneRef.current?.submitFeedbackHandler(lstFeedbackExpertId, true);
            } else {
                let expertList = getExperts();
                if (expertList?.length > 0) {
                    const previouseExpert = answers[0]?.answer?.lastSubmissionExpertId;
                    let randomisedExpert = previouseExpert ? previouseExpert : expertList[Math.floor(Math.random() * expertList.length)]?._id;
                    res = await answerPaneRef.current?.submitFeedbackHandler(randomisedExpert, true);
                } else {
                    enqueueSnackbar('Expert id not found.', {
                        variant: 'error',
                        autoHideDuration: 2500,
                    });
                }
            }
        }
        return res;
    };

    const getChatFromHistory = (history: PatchHistory[]) => {
        const botConversation: IConversation[] = [];
        if (capabilities && capabilities?.length > 0) {
            let lastConversation = null;
            for (let i = (history?.length ? history?.length - 1 : 0); i >= 0; i--) {
                if (history[i].history.length > 0) {
                    lastConversation = history[i];
                    break;
                }
            }
            let lastConversationCapability = null, lastConversationQuestionObj = null;

            for (let capability of capabilities) {
                for (let questionObj of (capability?.questions ?? [])) {
                    const question = questionObj.question;
                    if (question._id === lastConversation?.questionId) {
                        lastConversationCapability = { ...capability };
                        lastConversationQuestionObj = { ...questionObj };
                    }
                    const questionHistory = history.find(h => h.questionId === question._id);
                    if (questionHistory && questionHistory.history?.length > 0) {
                        for (let i = 0; i < questionHistory.history.length; i++) {
                            const history = questionHistory.history[i];
                            if (i === 0) {
                                botConversation.push(getObjForCapability(capability.capabilityName, capability.capabilityId));
                                botConversation.push(getObjForQuestion(question.title, question._id, capability.capabilityId));
                                question.description && botConversation.push(getObjForQuestion(question.description, question._id, capability.capabilityId));
                                botConversation.push({
                                    type: ChatGptUserType.USER,
                                    text: history.answer,
                                    givenAnswerType: history.givenAnswerType as ConversationViewType,
                                    answerAudioDetails: history.answerAudioDetails?.url ? {
                                        url: history.answerAudioDetails?.url
                                    } : undefined,
                                });
                            } else {
                                botConversation.push({
                                    type: ChatGptUserType.BOT,
                                    text: history.question,
                                    givenAnswerType: ConversationViewType.TEXT,
                                    id: i + getRandomString()
                                });
                                botConversation.push({
                                    type: ChatGptUserType.USER,
                                    text: history.answer,
                                    givenAnswerType: history.givenAnswerType as ConversationViewType,
                                    answerAudioDetails: history.answerAudioDetails?.url ? {
                                        url: history.answerAudioDetails?.url
                                    } : undefined,
                                });
                            }
                        }
                    }
                }
            }
            const answers = lastConversationQuestionObj?.answers;
            let questionAnswerId = '';
            if (answers) {
                answers.sort((a: any, b: any) => {
                    return b.answer.version - a.answer.version
                })
                questionAnswerId = answers[0]?.answer._id;
            }
            const currQuestionDetailsObj = {
                capabilityId: lastConversationCapability.capabilityId,
                questionId: lastConversationQuestionObj?.question._id,
                answerType: lastConversationQuestionObj?.question.answerType,
                questionTitle: lastConversationQuestionObj?.question.title,
                questionAnswerId: questionAnswerId
            }

            if (lastConversation?.followUpQuestion?.followUpQuestion) {
                botConversation.push({
                    type: ChatGptUserType.BOT,
                    text: lastConversation.followUpQuestion.followUpQuestion,
                    givenAnswerType: ConversationViewType.TEXT,
                    id: "followup" + getRandomString()
                });
                setLastBotQuestion({
                    followUpLevelOrder: lastConversation.followUpQuestion.followUpLevelOrder,
                    followUpQuestion: lastConversation.followUpQuestion.followUpQuestion,
                    followUpQuestionId: lastConversation.followUpQuestion.followUpQuestionId ?? "",
                    maxSortOrder: lastConversation.followUpQuestion.maxSortOrder,
                    parentEvaluationId: lastConversation.followUpQuestion.parentEvaluationId ?? "",
                    questionAnswerId: lastConversation.followUpQuestion.questionAnswerId ?? "",
                    score: lastConversation.followUpQuestion.score,
                    aiAnswer: lastConversation.followUpQuestion.aiAnswer,
                    scores: lastConversation.followUpQuestion.scores,
                });
                setCurrQuestionDetails({ ...currQuestionDetailsObj });
                setConversation(botConversation);
            } else {
                if (botConversation.length === 0) {
                    startConversation();
                } else {
                    askNextQuestion(botConversation, currQuestionDetailsObj);
                }
            }
        }
    }

    useEffect(() => {
        if (capabilities && capabilities?.length > 0) {
            setShowScreenBlockLoader(true);
            evaluationPlatformService.getPitchHistory({
                candidateId: candidateId,
                candidateTrackId: candidateTrackId
            })
                .then((res) => {
                    getChatFromHistory(res?.output?.answeredQuestionsHistories ?? [])
                })
                .catch(() => {
                    startConversation();
                })
                .finally(() => {
                    setShowScreenBlockLoader(false);
                })
        }
    }, [trackId])

    useEffect(() => {
        if (currQuestionDetails.questionAnswerId && currQuestionDetails.questionAnswerId !== currentAnswerIdBotState) {
            dispatch(setCurrentAnswerId(currQuestionDetails.questionAnswerId));
        }
        if (candidateTrackId !== candidateTrackIdBotState) {
            dispatch(setCandidateTrackId(candidateTrackId));
        }
    }, [currQuestionDetails, candidateTrackId])

    const hideBotResponseContainer = currQuestionDetails.answerType === answerType.CODE && !lastBotQuestion?.followUpQuestion;
    const savingAnswer = saving || questionStatusText === SAVING_TEXT;

    return (
        <StyledChatContainer>
            <OverlayLoader loading={showScreenBlockLoader || loadingBotResponse} disableOverlay={false} />
            {conversation?.length > 0 && <SplitPaneWrapper
                direction="vertical"
                initialPaneSize={"50%"}
                minSize="35%"
                onPanseSizeChange={handlePaneSizeChange}
                height={Content_Height}
            >
                <div className="position-relative chat-box-height">
                    <PitchChatBox
                        conversation={conversation}
                        loadingBotResponse={loadingBotResponse}
                        disableRespond={saving || questionStatusText === SAVING_TEXT}
                        onSendResponse={handleOnSave}
                        headerTitle={<QuestionTitle />}
                        onlyUseMicrophone={onlyUseMicrophone}
                        directory={directory}
                        hideBotResponseContainer={hideBotResponseContainer}
                        handleRegenerateResponse={handleRegenerateResponse}
                    />
                </div>
                {currQuestionDetails.questionId && <AnswerPane
                    questionPaneSize={questionPaneSize}
                    key={currentQuestionId + "-" + selectedCapabilityId}
                    onboardMode={true}
                    ref={answerPaneRef}
                    customActionBtn={[
                        hideBotResponseContainer ? <StyledButton
                            size='small'
                            variant='contained'
                            color='primary'
                            className='m-2'
                            disabled={savingAnswer || loadingBotResponse}
                            onClick={handleCodingAnswerSave}
                        >
                            {'Submit Answer'}
                        </StyledButton> : null
                    ]}
                />}
            </SplitPaneWrapper>}
        </StyledChatContainer>
    )
}

export default CandidateQAndA;