import {
    Csharp,
    Css3,
    Go,
    Html5,
    Java,
    Javascript,
    Mysql,
    Python,
    Ruby,
    Typescript,
} from '@styled-icons/simple-icons';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import { DataNode, Key } from 'rc-tree/lib/interface';
import React from 'react';
import styled from 'styled-components';
import { ChevronDown, ChevronRight } from 'styled-icons/boxicons-regular';

const languageIcons = {
    js: <Javascript className="mr-2" size="1em" />,
    java: <Java className="mr-2" size="1em" />,
    python: <Python className="mr-2" size="1em" />,
    go: <Go className="mr-2" size="1em" />,
    cs: <Csharp className="mr-2" size="1em" />,
    ts: <Typescript className="mr-2" size="1em" />,
    tsx: <Typescript className="mr-2" size="1em" />,
    sql: <Mysql className="mr-2" size="1em" />,
    css: <Css3 className="mr-2" size="1em" />,
    html: <Html5 className="mr-2" size="1em" />,
    rb: <Ruby className="mr-2" size="1em" />,
};

const StyledFolderStructure = styled.div`
  background: black;
  color: white;
  overflow-x: auto;
  height: 100%;
  .rc-tree .rc-tree-treenode {
    line-height: 1;
  }
  .rc-tree-treenode-selected {
    box-shadow: 0 0 0 1px #3376CD;
    background-color: #16385B;
  }
  .rc-tree-node-selected {
    background-color: transparent;
    box-shadow: none;
    opacity: 1;
  }
`;

const StyledFileFolderName = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledIcon = styled.div`
  margin-right: 4px;
`;

const StyledTreeContainer = styled.div`
  .rc-tree-icon {
    color: white;
  }

  .rc-tree-title {
    color: white;
    font-size: 12px;
    display: block;
  }

  .rc-tree-switcher {
    font-size: 15px;
    color: white;
  }
`;

interface ISwitcherIconProps {
    isLeaf: boolean;
    expanded: boolean;
}

interface FolderStructureProps {
    onFileSelect: (filePath: Key) => void;
    onFileRename: (filePath: string, newName: string) => void;
    onFileDelete: (filePath: string) => void;
    onFolderRename: (folderPath: string, newName: string) => void;
    onFolderDelete: (folderPath: string) => void;
}

const FolderStructure: React.FC<FolderStructureProps> = ({
    onFileSelect,
    onFileRename,
    onFileDelete,
    onFolderRename,
    onFolderDelete,
}) => {
    const treeData: DataNode[] = [
        {
            key: 'root',
            title: 'src',
            children: [
                {
                    key: 'components',
                    title: 'components',
                    children: [
                        {
                            key: 'FolderStructure.tsx',
                            title: (
                                <StyledFileFolderName>
                                    <StyledIcon>
                                        {languageIcons.tsx}
                                    </StyledIcon>
                                    FolderStructure.tsx
                                </StyledFileFolderName>
                            ),
                        },
                        // Add more files/folders as needed
                    ],
                },
            ],
        },
    ];

    const handleSelect = (selectedKeys: Key[]) => {
        onFileSelect(selectedKeys[0]);
    };

    const handleRename = (key: any, newName: string) => {
        const isFile = key.includes('.');
        if (isFile) {
            onFileRename(key, newName);
        } else {
            onFolderRename(key, newName);
        }
    };

    const handleDelete = (key: any) => {
        const isFile = key.includes('.');
        if (isFile) {
            onFileDelete(key);
        } else {
            onFolderDelete(key);
        }
    };

    return (
        <StyledFolderStructure>
            <StyledTreeContainer>
                <Tree
                    defaultExpandAll
                    treeData={treeData}
                    onSelect={handleSelect}
                    // onRename={handleRename}
                    // onDelete={handleDelete}
                    switcherIcon={({ isLeaf, expanded }: ISwitcherIconProps) =>
                        !isLeaf ? (expanded ? <ChevronDown size="1em" /> : <ChevronRight size="1em" />) : null
                    }
                />
            </StyledTreeContainer>
        </StyledFolderStructure>
    );
};

export default FolderStructure;
