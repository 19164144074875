import { MainHeader, Sidebar } from 'containers';
import { CareerConsultancyContainer } from 'containers/CareerConsultancy';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styled from 'styled-components';
import { SIDEBAR_WIDTH } from 'utilities/constants';

const StyledDashboardPage = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    z-index: 100;
    position: fixed;
    width: 100%;
  }
  .main {
    padding-top: 20px;
    display: flex;
    transition: 1s;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 80px);
  }
  
  .page-title {
    margin-left: 20px;
    color: #5b94e3;
    font-size: 20px;
    font-weight: bold;
  }
  .main-content {
    height: 100%;
  }
`;
export const CareerConsultancy = () => {
  return (
    <StyledDashboardPage>
      <div className="content">
        <div className="main">
          <CareerConsultancyContainer />
        </div>
      </div>
    </StyledDashboardPage>
  );
}
