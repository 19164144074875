import { Button } from '@material-ui/core';
import { Important } from '@styled-icons/fluentui-system-filled';
import { ChatDelete } from '@styled-icons/remix-fill/ChatDelete';
import { IconContainer } from 'components/Common/IconContainer';
import { StyledButton } from 'containers/QuestionCodingPane/Coding/AnswerCTA';
import { useMessagePopup } from 'context/messagePopup';
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { clearChatHistory } from 'services/evaluationPlatform';
import { RootState, useAppDispatch } from 'store';
import { setInterviewMeConversation, setShowInterviewMeBot, setShowInterviewMeCodeBot } from 'store/splitView';
import styled from 'styled-components';
import { IClearChatHistory } from 'types';
import { Candidate_Id, DEFAULT_TOKEN } from 'utilities/constants';

interface IInterviewMeHeader {
    isCode?: boolean;
    onClose?: () => void;
    onClearConversation?: () => void;
    staticTitle?: JSX.Element;
    hideCloseBtn?: boolean;
}

const StyledChatHeader = styled.div`
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: RGB(200, 220, 255);
    display: flex;
    align-items: center;
    font-size: .85rem;
    color: rgb(60, 60, 60);
    padding: 12.5px;
    font-weight: 700;
    justify-content: space-between;
`;

const InterviewMeHeader = ({ isCode, onClose, onClearConversation, staticTitle, hideCloseBtn }: IInterviewMeHeader) => {
    const message = useMessagePopup();
    const candidateId = getValueBrowserStorage(Candidate_Id);
    const dispatch = useAppDispatch();
    const [clearHistoryLoading, setClearHistoryLoading] = useState<boolean>(false);
    const { currentAnswerId, questionTitle } = useSelector(
        (state: RootState) => state.splitView.bot
    );
    const { conversation } = useSelector((state: RootState) => state.splitView.bot);

    const handleClearHistory = () => {
        const text = "Clearing will erase your conversation history. Are you sure you want to proceed?";
        message.confirm(text, clearHistory, ChatDelete);
    }

    const clearHistory = async () => {
        if (candidateId && currentAnswerId) {
            const payload: IClearChatHistory = {
                token: DEFAULT_TOKEN,
                questionAnswerId: currentAnswerId,
                candidateId
            };
            try {
                setClearHistoryLoading(true);
                await clearChatHistory(payload);
                dispatch(setInterviewMeConversation([]))
                onClearConversation && onClearConversation();
            } catch (error: any) {
                console.error("error with clarify API", error);
            } finally {
                setClearHistoryLoading(false);
            }
        }
    }

    const handleClose = () => {
        if (isCode) {
            dispatch(setShowInterviewMeCodeBot(false));
        } else {
            dispatch(setShowInterviewMeBot(false));
        }
        onClose && onClose();
    }

    return <StyledChatHeader>
        {staticTitle ? staticTitle : <span style={{ marginTop: "-5px" }}>{questionTitle}</span>}
        <div>
            {!!conversation.length && onClearConversation && <StyledButton title={"Clear Conversation"} paddingLeft="0" color='primary' onClick={handleClearHistory}>
                {clearHistoryLoading ? <Spinner size='sm' className='mr-2' animation='border' />
                    : <IconContainer icon={ChatDelete} />} Clear Conversation
            </StyledButton>}
            {!hideCloseBtn && <Button onClick={handleClose}>✖</Button>}
        </div>
    </StyledChatHeader>
}

export default InterviewMeHeader;