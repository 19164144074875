import RichTextEditor from 'components/Common/Editors/RichTextEditor';
import React from 'react';
import { handleMarkedText } from 'utilities/helperFunctions';

const QuestionDescription = (props: any) => (
    <div className="question__description">
        <div className="question__description__text">
            <RichTextEditor
                doNotAllowCopy={true}
                disabled={true}
                id={props.question._id}
                value={handleMarkedText(props.question.description)}
                customStyles={{ height: 'auto', resize: 'none', boxShadow: 'none' }}
            />
        </div>
    </div>
);

export default QuestionDescription;