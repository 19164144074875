import { QuestionField } from "components";
import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { talkToExpertService } from "../../services/talkToExpert";
import Modal from 'react-modal';
import { connect, useSelector } from "react-redux";
import { RootState } from "store";
import { candidateViewedExpertFeedback, setQuestionFeedbackViewed } from "store/evaluationPlatform";

interface IStructuredFeedbackComponent {
    meetingDetailId: string;
    expertName: string;
    setQuestionFeedbackViewed: Function,
    candidateViewedExpertFeedback: Function
}

export const QuestionDiv = styled.div`
    .question__description {
        width: auto !important;
    }
    .question__title {
        font-size: 18px;
    }
`;

const _StructuredFeedbackComponent = ({
    meetingDetailId, expertName, candidateViewedExpertFeedback,
    setQuestionFeedbackViewed }: IStructuredFeedbackComponent) => {
    const [structuredFeedback, setStructuredFeedback] = useState<any>();
    const [questionsIndex, setQuestionIndex] = useState<any>({});
    const candidateInfo = useSelector((state: RootState) => state.evaluationPlatform.candidate);
    const [openRecorder, setOpenRecorder] = useState<string>('-1');

    useEffect(() => {
        getStructuredFeedback();
    }, [])

    useEffect(() => {
        setQuestionIndex(setIndexMap());
    }, [structuredFeedback])

    const getStructuredFeedback = () => {
        talkToExpertService.getMeetingStructureFeedbackDetails({ meetingDetailId }).then((response) => setStructuredFeedback(response.output));
    }

    const setIndexMap = () => {
        const idxMap: any = {};
        if (structuredFeedback?.questions?.length > 0) {
            let idxCount = 0;
            structuredFeedback.questions.map((question: any) => {
                if (question.question?.questionType !== 'CONTENT') {
                    idxMap[question.question._id] = ++idxCount;
                }
            })
        }
        return idxMap;
    }

    return <div>
        <div className="question__main">
            <div className="h4 text-dark font-weight-bold">{'List of selected questions'}</div>
            <QuestionDiv>
                {structuredFeedback &&
                    structuredFeedback.questions.map((question: any, idx: number) => {
                        return (
                            <QuestionField
                                questionIndex={() => questionsIndex[question?.question._id]}
                                idx={idx}
                                key={question.question._id}
                                candidateTrackId={structuredFeedback.candidateTrackId}
                                capabilityId={question.question.capabilities[0]?.capabilityId || question.question.indirectCapabilities[0]?.capabilityId}
                                questionSet={question}
                                setAnswer={() => { }}
                                saveResponseForQuestionOfCandidateTrack={() => { }}
                                Modal={Modal}
                                setQuestionId={() => { }}
                                currentQuestionId={question.question._id}
                                submitResponseToExpert={() => { }}
                                setFeedback={() => { }}
                                handleEdit={() => { }}
                                candidateViewedExpertFeedback={candidateViewedExpertFeedback}
                                setQuestionFeedbackViewed={setQuestionFeedbackViewed}
                                candidate={candidateInfo}
                                setCurrentAnsVersionId={() => { }}
                                saveCandidateLastActivity={() => { }}
                                goToPaymentPage={() => { }}
                                triggerFeedback={() => { }}
                                setTriggerFeedback={() => { }}
                                isMeetingView={true}
                                expertName={expertName}
                                openRecorder={openRecorder}
                                setOpenRecorder={setOpenRecorder}
                                currModelIndex={question?.question._id + idx}
                                refreshStructuredFeedback={getStructuredFeedback}
                                chatWithExpert={() => { }}
                            />
                        );
                    })}
            </QuestionDiv>
        </div>
    </div>
}

const mapDispatchToProps = {
    candidateViewedExpertFeedback,
    setQuestionFeedbackViewed
}

export const StructuredFeedbackComponent = connect(null, mapDispatchToProps)(_StructuredFeedbackComponent);
