import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { ChevronDown, ChevronUp } from "@styled-icons/boxicons-regular";
import RichTextEditor from 'components/Common/Editors/RichTextEditor';
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getValueBrowserStorage } from "services/browserStorageService";
import { HintsAndSampleSolutionService } from "services/hintsAndSampleSolutions";
import styled from 'styled-components';
import { DEFAULT_TOKEN } from "utilities/constants";
import { ISampleSolution } from '../types';

const accordionStyle = {
    backgroundColor: "#f8f8f8",
    boxShadow: "none",
};

const accordionDetailStyles: any = {
    flexDirection: "column"
}

const HintsDiv = styled.div`
    .tabs {
        max-height: 130px;
        overflow: auto;
    }
`;

const SampleSolutionHeader = styled.span`
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #525252;
`;

interface IQuestionSampleSolution {
    questionId: string;
}

const QuestionSampleSolution = ({ questionId }: IQuestionSampleSolution) => {
    const [questionSampleSolutions, setQuestionSampleSolutions] = useState<ISampleSolution[]>([]);
    const [expandedSolutions, setExpandedSolutions] = useState<boolean[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getPayload = () => {
        const trackId = getValueBrowserStorage('candidateTrackId');
        if (trackId && questionId) {
            return {
                token: DEFAULT_TOKEN,
                candidateTrackId: trackId,
                questionId
            }
        } else return null;
    }

    const showSampleSolutions = () => {
        const payload = getPayload();
        if (payload) {
            setIsLoading(true);
            HintsAndSampleSolutionService.getSampleSolutionsByQuestion(payload)
                .then(res => {
                    const initialExpandedState = Array(res.output.length).fill(false);
                    initialExpandedState[0] = true; // Set the first solution to expanded
                    setExpandedSolutions(initialExpandedState);
                    setQuestionSampleSolutions(res.output);
                }).catch((error: any) => console.log(error)).finally(() => {
                    setIsLoading(false);
                })
        }
    };

    useEffect(() => {
        showSampleSolutions();
    }, []);

    const handleExpandCollapse = (index: number) => {
        setExpandedSolutions((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            newExpanded[index] = !newExpanded[index];
            return newExpanded;
        });
    };

    return (
        <>
            {isLoading && <Spinner animation='border'/>}
            <div>
                {questionSampleSolutions.map((solution, index) => (
                    <Accordion style={accordionStyle} expanded={expandedSolutions[index]} key={index} onChange={() => handleExpandCollapse(index)}>
                        <AccordionSummary
                            expandIcon={
                                expandedSolutions[index] ? (
                                    <ChevronUp size={20} />
                                ) : (
                                    <ChevronDown size={20} />
                                )
                            }
                            aria-controls={`panel${index + 1}a-content`}
                            id={`panel${index + 1}a-header`}
                        >
                            <Typography variant="h6">{solution?.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={accordionDetailStyles}>
                            <Typography>
                                <RichTextEditor
                                    doNotAllowCopy={true}
                                    disabled={true}
                                    id={`solutionRTE-${index}`}
                                    value={solution?.description}
                                    customStyles={{ height: 'auto', resize: 'none', boxShadow: 'none' }}
                                />
                            </Typography>
                            <HintPanel hints={solution?.hints} />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </>
    );

    function HintPanel({ hints }: { hints: string[] }) {
        return (
            <Tabs>
                <TabList>
                    {hints?.map((hint, hintIdx) => (
                        <Tab key={hintIdx}>Hint #{hintIdx + 1}</Tab>
                    ))}
                </TabList>
                <HintsDiv>
                    {hints?.map((hint, hintIdx) => (
                        <TabPanel key={hintIdx}>
                            <div className="tabs">
                                <RichTextEditor
                                    doNotAllowCopy={true}
                                    disabled={true}
                                    id={`hintRTE-${hint}-${hintIdx}`}
                                    value={hint}
                                    customStyles={{ height: 'auto', resize: 'none', boxShadow: 'none' }}
                                />
                            </div>
                        </TabPanel>
                    ))}
                </HintsDiv>
            </Tabs>
        );
    }
};

export default QuestionSampleSolution;
