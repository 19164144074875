import Chip from '@material-ui/core/Chip';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { QUESTION_STATUS_TEXT } from 'utilities/constants';
import { CapabilityStatus } from '../types';
import { IconContainer } from 'components/Common/IconContainer';
import { FilterRight } from '@styled-icons/bootstrap'
import { Divider } from '@material-ui/core';
import { Popover } from '@material-ui/core';


const StyledChip = styled(Chip)`
    margin: 5px !important;
`;

const SelectedStatus = styled.div`
    margin-right: 10px;
    font-weight: bold;
`;

const StatusWrapper = styled.div`
    min-width: 3rem;
`;

const StyledDivider = styled(Divider)`
    height: 30px !important;
    background-color: black !important;
    width: 2px !important;
`;

interface IProps {
    capabilityStatus: CapabilityStatus;
    setQuestionFilter: (status: string) => void;
    questionFilter: string;
}

export const STATUS_BUTTON_COLORS: { [key: string]: string } = {
    UNANSWERED: '#2C3E50',
    ANSWERED: '#E67E22',
    SEND_FOR_REVIEW: '#27AE60',
    UNDER_REVIEW: '#3498DB',
    FEEDBACK_RECEIVED: '#95A5A6',
    All: '#000000',
};

export const QuestionStatus: React.FC<IProps> = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const capabilitiyStatus = props.capabilityStatus;

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const activeStatusLabel =
        props.questionFilter === QUESTION_STATUS_TEXT.UNANSWERED
            ? `Unanswered`
            : props.questionFilter === QUESTION_STATUS_TEXT.ANSWERED
                ? `In progress`
                : props.questionFilter === QUESTION_STATUS_TEXT.SUBMITTED_FOR_REVIEW
                    ? `Submitted`
                    : props.questionFilter === QUESTION_STATUS_TEXT.UNDER_REVIEW
                        ? `Under Review`
                        : props.questionFilter === QUESTION_STATUS_TEXT.FEEDBACK_RECEIVED
                            ? `Feedback Received`
                            : props.questionFilter === 'All'
                                ? `All`
                                : '';

    return (
        <>
            <StatusWrapper className="d-flex align-items-center justify-content-end">
                <SelectedStatus>
                    {`${activeStatusLabel}`}
                </SelectedStatus>
                <StyledDivider orientation='vertical' />
                <IconContainer height='30px' icon={FilterRight} onClick={handleClick} />
            </StatusWrapper>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className="d-flex align-items-center flex-wrap justify-content-start w-100">
                    <StyledChip
                        clickable
                        label={`Unanswered: ${capabilitiyStatus?.unAnswered || 0}`}
                        onClick={() => props.setQuestionFilter(QUESTION_STATUS_TEXT.UNANSWERED)}
                        style={{
                            color: STATUS_BUTTON_COLORS[QUESTION_STATUS_TEXT.UNANSWERED],
                        }}
                        size='small'
                        variant={props.questionFilter === QUESTION_STATUS_TEXT.UNANSWERED ? 'default' : 'outlined'}
                    />
                    <StyledChip
                        clickable
                        label={`In progress: ${capabilitiyStatus?.savedAnswers || 0}`}
                        onClick={() => props.setQuestionFilter(QUESTION_STATUS_TEXT.ANSWERED)}
                        style={{
                            color: STATUS_BUTTON_COLORS[QUESTION_STATUS_TEXT.ANSWERED],
                        }}
                        size='small'
                        variant={props.questionFilter === QUESTION_STATUS_TEXT.ANSWERED ? 'default' : 'outlined'}
                    />
                    <StyledChip
                        clickable
                        label={`Submitted: ${capabilitiyStatus?.sendForReview || 0}`}
                        onClick={() => props.setQuestionFilter(QUESTION_STATUS_TEXT.SUBMITTED_FOR_REVIEW)}
                        style={{
                            color: STATUS_BUTTON_COLORS[QUESTION_STATUS_TEXT.SUBMITTED_FOR_REVIEW],
                        }}
                        size='small'
                        variant={
                            props.questionFilter === QUESTION_STATUS_TEXT.SUBMITTED_FOR_REVIEW ? 'default' : 'outlined'
                        }
                    />
                    <StyledChip
                        clickable
                        label={`Under Review: ${capabilitiyStatus?.underReview || 0}`}
                        onClick={() => props.setQuestionFilter(QUESTION_STATUS_TEXT.UNDER_REVIEW)}
                        style={{
                            color: STATUS_BUTTON_COLORS[QUESTION_STATUS_TEXT.UNDER_REVIEW],
                        }}
                        size='small'
                        variant={props.questionFilter === QUESTION_STATUS_TEXT.UNDER_REVIEW ? 'default' : 'outlined'}
                    />
                    <StyledChip
                        clickable
                        label={`Feedback Received: ${capabilitiyStatus?.feedBackRecevied || 0}`}
                        onClick={() => props.setQuestionFilter(QUESTION_STATUS_TEXT.FEEDBACK_RECEIVED)}
                        style={{
                            color: STATUS_BUTTON_COLORS[QUESTION_STATUS_TEXT.FEEDBACK_RECEIVED],
                        }}
                        size='small'
                        variant={
                            props.questionFilter === QUESTION_STATUS_TEXT.FEEDBACK_RECEIVED ? 'default' : 'outlined'
                        }
                    />
                    <StyledChip
                        clickable
                        label={`All: ${capabilitiyStatus?.numberOfQuestions || 0}`}
                        onClick={() => props.setQuestionFilter('All')}
                        style={{
                            color: STATUS_BUTTON_COLORS['All'],
                        }}
                        size='small'
                        variant={props.questionFilter === 'All' ? 'default' : 'outlined'}
                    />
                </div>
            </Popover>
        </>
    );
};