import React, { useEffect } from 'react';
import { isOpenedFromLandingPages, openedFormLandingWithFlowTypePlacement, setStorageFromLangingPageParams } from 'utilities/landingPageUtil';
import { SignupHeader, Signup } from '../../containers';
import AuthPage from 'containers/AuthPage';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';

export const SignupPage = (props: any) => {

  const history = useHistory();
  const location = useLocation();
  const _queryParams: any = queryString.parse(location.search);

  useEffect(() => {
    setStorageFromLangingPageParams(_queryParams);
    if (openedFormLandingWithFlowTypePlacement()) {
      const queryParams = location.search;
      history.push(`/placementAuth${queryParams}`);
    }
  }, [])

  return (
    <>
      {openedFormLandingWithFlowTypePlacement() ? <AuthPage /> : <>
        {!isOpenedFromLandingPages() && (
          <SignupHeader background="#315CD5" color="white" />
        )}
        <Signup history={props.history} />
      </>}
    </>
  );
};
