import { CaretDownCircle as DownIcon } from '@styled-icons/boxicons-regular/CaretDownCircle';
import { CaretUpCircle as UpIcon } from '@styled-icons/boxicons-regular/CaretUpCircle';
import { Check } from '@styled-icons/entypo/Check';
import { CodeReview } from '@styled-icons/octicons/CodeReview';
import { Loop2 } from '@styled-icons/icomoon/Loop2';
import { AlertIconOriginal, FeedbackReceivedIcon_Anim, FeedbackUnderReviewIcon_Anim } from 'assets';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { QUESTION_STATUS_COLORS } from 'theme/colors';
import QuestionStatusIcon from 'theme/icons/question/circleQuestionStatusIcon';
import { answerType, QUESTION_STATUS_TEXT } from 'utilities/constants';
import { canSubmitQuestionForReview, getAnsStatusText, isPlacementTrack } from 'utilities/helperFunctions';

const Styledfeedback = styled.img`
  width: 25px;
  height: auto;
`

const StyledLoopIcon = styled(Loop2)`
  position: absolute;
  width: 15px;
  height: auto;
  color: ${(props: any) => props.fill};
  animation-name: loop;
  animation-duration: 1s;
`;

const StyledCheckIcon = styled(Check)`
  stroke-width: 5%;
  width: 27px;
  height: auto;
  color: green;
`;

const StyledAnimatedIcon = styled((
  { component, ...props }) => React.cloneElement(component, props))`
  width: 40px;
  height: auto;
  cursor: pointer;
  ${({ animate, UnAns }: { animate: boolean, UnAns: boolean }) => {
    return animate ? {
      animationName: 'feedback',
      animationDuration: '1s',
      animationIterationCount: 'infinite'
    } : UnAns ? {
      animationName: 'UnAns',
      animationDuration: '1s',
      animationIterationCount: 'infinite'
    } : {}
  }}
`

const StyledIcon = styled((
  { component, ...props }) => React.cloneElement(component, props))`
  width: 40px;
  height: auto;
  top: 0 !important;
  margin-right: 21px;
  margin-top: -8px;
`

const selectIcon = (status: string) => {
  switch (status) {
    case QUESTION_STATUS_TEXT.UNANSWERED:
      return <QuestionStatusIcon fill={QUESTION_STATUS_COLORS.UNANSWERED} />;
    case QUESTION_STATUS_TEXT.ANSWERED:
      return <QuestionStatusIcon fill={QUESTION_STATUS_COLORS.ANSWERED} />;
    case QUESTION_STATUS_TEXT.SUBMITTED_FOR_REVIEW:
      return <QuestionStatusIcon fill={QUESTION_STATUS_COLORS.SUBMITTED_FOR_REVIEW} />;
    case QUESTION_STATUS_TEXT.UNDER_REVIEW:
      return <Styledfeedback src={FeedbackUnderReviewIcon_Anim} />;
    case QUESTION_STATUS_TEXT.FEEDBACK_RECEIVED:
      return <Styledfeedback src={FeedbackReceivedIcon_Anim} />;
    case QUESTION_STATUS_TEXT.FEEDBACK_VIEWED:
      return <QuestionStatusIcon fill={QUESTION_STATUS_COLORS.FEEDBACK_VIEWED} />;
    case QUESTION_STATUS_TEXT.LOOP_UNANSWERED:
      return (
        <div className="loop">
          <div className="loop-icon">
            <StyledLoopIcon fill={QUESTION_STATUS_COLORS.UNANSWERED} />
          </div>
          <div className="circle-icon">
            <QuestionStatusIcon fill={QUESTION_STATUS_COLORS.UNANSWERED} />
          </div>
        </div>
      );
    case QUESTION_STATUS_TEXT.LOOP_ANSWERED:
      return (
        <div className="loop">
          <div className="loop-icon">
            <StyledLoopIcon fill={QUESTION_STATUS_COLORS.ANSWERED} />
          </div>
          <div className="circle-icon">
            <QuestionStatusIcon fill={QUESTION_STATUS_COLORS.ANSWERED} />
          </div>
        </div>
      );
    case QUESTION_STATUS_TEXT.LOOP_SUBMITTED_FOR_REVIEW:
      return (
        <div className="loop">
          <div className="loop-icon">
            <StyledLoopIcon fill={QUESTION_STATUS_COLORS.SUBMITTED_FOR_REVIEW} />
          </div>
          <div className="circle-icon">
            <QuestionStatusIcon fill={QUESTION_STATUS_COLORS.SUBMITTED_FOR_REVIEW} />
          </div>
        </div>
      );
    case QUESTION_STATUS_TEXT.LOOP_UNDER_REVIEW:
      return <Styledfeedback src={FeedbackUnderReviewIcon_Anim} />;
    case QUESTION_STATUS_TEXT.LOOP_FEEDBACK_RECEIVED:
      return <Styledfeedback src={FeedbackReceivedIcon_Anim} />;
    case QUESTION_STATUS_TEXT.LOOP_FEEDBACK_VIEWED:
      return (
        <div className="loop">
          <div className="loop-icon">
            <StyledLoopIcon fill={QUESTION_STATUS_COLORS.FEEDBACK_VIEWED} />
          </div>
          <div className="circle-icon">
            <QuestionStatusIcon fill={QUESTION_STATUS_COLORS.FEEDBACK_VIEWED} />
          </div>
        </div>
      );
  }
};

const QuestionHeader = (props: any) => {
  const status = props.question.underFeedbackLoop ? `LOOP_${props.question.status}` : props.question.status;
  const animate = status === QUESTION_STATUS_TEXT.LOOP_FEEDBACK_RECEIVED || status === QUESTION_STATUS_TEXT.FEEDBACK_RECEIVED;
  const UnAns = status === QUESTION_STATUS_TEXT.UNANSWERED;
  const getIconColor = () => {
    switch (props.question.status) {
      case QUESTION_STATUS_TEXT.UNANSWERED:
        return QUESTION_STATUS_COLORS.UNANSWERED;
      case QUESTION_STATUS_TEXT.ANSWERED:
        return QUESTION_STATUS_COLORS.ANSWERED;
      case QUESTION_STATUS_TEXT.SUBMITTED_FOR_REVIEW:
        return QUESTION_STATUS_COLORS.SUBMITTED_FOR_REVIEW;
      case QUESTION_STATUS_TEXT.FEEDBACK_VIEWED:
        return QUESTION_STATUS_COLORS.FEEDBACK_VIEWED;
      case QUESTION_STATUS_TEXT.LOOP_UNANSWERED:
        return QUESTION_STATUS_COLORS.UNANSWERED
      case QUESTION_STATUS_TEXT.LOOP_ANSWERED:
        return QUESTION_STATUS_COLORS.ANSWERED
      case QUESTION_STATUS_TEXT.LOOP_SUBMITTED_FOR_REVIEW:
        return QUESTION_STATUS_COLORS.SUBMITTED_FOR_REVIEW
      case QUESTION_STATUS_TEXT.LOOP_FEEDBACK_VIEWED:
        return QUESTION_STATUS_COLORS.FEEDBACK_VIEWED
      default:
        return ""
    }
  }


  return (
    <div className="question__header">
      <ReactTooltip id="qStatus" type="dark" />
      {
        !props.showSharedAnswerModal ?
          !props.communityVersion && props.expandDescription ?
            <div data-for="qStatus" data-tip={getAnsStatusText(props.question.status)}>
              <StyledAnimatedIcon
                component={animate ? <DownIcon /> : <DownIcon fill={getIconColor()} />}
                onClick={props.handleExpandClick}
                data-for="qStatus" data-tip={getAnsStatusText(props.question.status)}
              />
            </div> :
            <div data-for="qStatus" data-tip={getAnsStatusText(props.question.status)}>
              <StyledAnimatedIcon
                component={animate ? <UpIcon /> : UnAns ? <UpIcon /> : <UpIcon fill={getIconColor()} />}
                onClick={props.handleExpandClick}
                animate={animate ? true : false}
                UnAns={UnAns ? true : false}
              />
            </div> : null
      }
      <ReactTooltip id="qStatus2" type="dark" />
      <span
        data-tip={"Feedback Received"}
        data-for="qStatus2">
        {props.question.status == QUESTION_STATUS_TEXT.FEEDBACK_RECEIVED && <StyledCheckIcon />}
      </span>
      {(canSubmitQuestionForReview(props.question) && !isPlacementTrack() && props.question?.answerType === answerType.CODE) &&
        <ReactTooltip id="submittionStatus" type="dark" />}
      <div className="question__title" style={{ width: '100%' }}>
        {(canSubmitQuestionForReview(props.question) && !isPlacementTrack() && props.question?.answerType === answerType.CODE)
          && <>
            <StyledIcon component={<CodeReview fill={"#9300fe"} />}
              data-tip={"This submmition will be reviewed by the expert"}
              data-for="submittionStatus"
            />
          </>}
        {props.idx !== undefined && props.question.questionType == 'CONTENT' ? <>
          <ReactTooltip id="content_type" type="dark" />
          <img className='treck_description_icon' src={AlertIconOriginal} data-for="content_type" data-tip="Content Type" /> {props.question.title}
        </> : `Q${props.questionIndex()}: ${props.question.title}`}
      </div>
    </div>
  );
};

export default QuestionHeader;
