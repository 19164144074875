import React from 'react';
import { isOpenedFromLandingPages } from 'utilities/landingPageUtil';
import { SignupHeader, Signup } from '../../containers';
import AuthPage from 'containers/AuthPage';
import { useHistory, useLocation } from 'react-router';

export const SignupPage = (props: any) => {

  const history = useHistory();
  const location = useLocation();

  if (isOpenedFromLandingPages()) {
    const queryParams = location.search;
    history.push(`/placement-auth${queryParams}`);
  }

  return (
    <>
      {isOpenedFromLandingPages() ? <AuthPage /> : <>
        {!isOpenedFromLandingPages() && (
          <SignupHeader background="#315CD5" color="white" />
        )}
        <Signup history={props.history} />
      </>}
    </>
  );
};
