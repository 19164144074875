import { TextField } from "@material-ui/core";
import axios from "axios";
import RecorderControls from "components/AudioRecorder/components/recorder-controls";
import RecordingItem from "components/AudioRecorder/components/recordings-list/RecordingItem";
import { Audio } from "components/AudioRecorder/types/recorder";
import generateKey from "components/AudioRecorder/utils/generate-key";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { ChatGptUserType, ConversationViewType } from "containers/GPTChatBotContainer/useGPTChatBot";
import { IConversation } from "containers/InterviewMeContainer";
import ChatAudio from "containers/InterviewMeContainer/ChatAudio";
import { InterviewMeChatBox } from "containers/InterviewMeContainer/InterviewMeChatBox";
import InterviewMeHeader from "containers/InterviewMeContainer/InterviewMeHeader";
import { StyledButton } from "containers/QuestionCodingPane/Coding/AnswerCTA";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { evaluationPlatformService } from "services";
import { getValueBrowserStorage } from "services/browserStorageService";
import styled from "styled-components";
import { convertHttpsToHttpFromUrl } from "utilities/commonUtils";
import { Candidate_Track_Id } from "utilities/constants";

const StyledChatContainer = styled.div`
    display: flex;
    background: white;
    transition: 1s;
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 57px);
    width: 100%;
    z-index: 1000;
    .chat-window {
        display: flex;
        flex-direction: column;
        height: 90%;
        width: 100%;
        border-radius: 5px;
        background: white;
    }
    .chat-content {
    }
    .historyContainer {
        border-left: 2px solid RGB(200, 220, 255);
        border-right: 2px solid RGB(200, 220, 255);
        padding: 10px;
        overflow-y: auto;
        flex-grow: 1;
    }
    .user-msg, .bot-msg {
        padding: 10px;
        border-radius: 5px;
        font-size: .9rem;
        position: relative;
        text-align: left;
        max-width: 80%;
    }
    .MuiOutlinedInput-adornedEnd {
        border: 1px solid RGB(200, 220, 255);
    }
    .bot-msg {
        background: linear-gradient(72deg, rgb(239, 245, 253) 0%, rgb(238, 238, 254) 79%);
        color: rgb(4, 3, 72);
    }
    .arrow-right, .arrow-left {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        position: absolute;
        top: 13px;
    }
    .arrow-left {
        left: -7px;
        border-right: 8px solid rgb(246, 248, 255);
    }
    .arrow-right {
        right: -7px;
        border-left: 8px solid #f1f1f1;
    }
    .user-msg {
        background: linear-gradient(108deg, rgb(246, 248, 255) 0%, rgb(220, 220, 220) 70%);
        color: rgb(60, 60, 60);
    }
    .bot-msg-container, .user-msg-container {
        display: flex;
        margin: 15px 0;
    }
    .bot-msg-container {
        justify-content: flex-start;
    }
    .user-msg-container {
        justify-content: flex-end;
    }
    .messaging-user-symbol, .messaging-bot-symbol {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .messaging-user-symbol {
        margin-left: 12.5px;
        background-color: #d8d8d8;
    }
    .messaging-bot-symbol {
        margin-right: 12.5px;
        background-color: #3d4e8d;
        color: #fff;
    }
    .pre-line-white-space {
        white-space: pre-wrap;
    }
    .submit-wrapper {
        border: 1px solid RGB(200,220,255);
        padding: 9px;
    }
    .text-field {
        padding: 1px;
        margin-left: 4rem;
    }
    .MuiOutlinedInput-multiline {
        padding: 2px 10px;
        height: 60px;
    }
`;

type IProps = {
    conversation: IConversation[];
    loadingBotResponse: boolean;
    onSendResponse: (conversation: IConversation[], response: string, audioUrl?: string) => void;
    headerTitle: JSX.Element;
    onlyUseMicrophone: boolean;
    disableRespond: boolean;
    directory: string;
    hideBotResponseContainer: boolean;
    handleRegenerateResponse: (message: IConversation) => void;
}

const PitchChatBox = ({
    conversation,
    loadingBotResponse,
    headerTitle,
    onlyUseMicrophone,
    disableRespond,
    directory,
    hideBotResponseContainer,
    onSendResponse,
    handleRegenerateResponse
}: IProps) => {

    const candidateTrackId = getValueBrowserStorage(Candidate_Track_Id)!;

    const endOfMessages = useRef<any>();
    const prevTranscript = useRef('');
    const audioRef = useRef(null);
    const textFieldRef = useRef(null);
    const restartSpeechRecognition = useRef(false);
    const currTranscribe = useRef<string | undefined>();

    const [inputMessage, setInputMessage] = useState<string>('');
    const [audioStream, setAudioStream] = useState<Audio>();
    const [loading, setLoading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    const scrollToBottom = useCallback(() => {
        endOfMessages?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, [endOfMessages]);

    const scrollToQuestion = () => {
        if (conversation?.length > 0) {
            let conversationElement: IConversation | null = null;

            for (let i = conversation.length - 1; i >= 0 && i >= conversation.length - 3; i--) {
                const chat = conversation[i];
                if(chat.type === ChatGptUserType.USER) {
                    scrollToBottom();
                    return;
                } else {
                    conversationElement = conversation[i];
                }
            }

            const element = document.getElementById(conversationElement?.id ?? "");
            const container = document.getElementById("pitch-chat-box-container-onboard"); // Make sure your container has this ID

            if (element && container) {
                const elementOffsetTop = element.offsetTop - container.offsetTop;
                container.scrollTo({
                    top: elementOffsetTop,
                    behavior: 'smooth'
                });
            }
        }
    };

    const handleTranscriptChange = (text: string) => {
        setInputMessage((prevTranscript.current ? (prevTranscript.current + "\n") : "") + text);
    }

    const handleInputChange = (event: any) => {
        setInputMessage(event.target.value);
    }

    const handleSendMessage = useCallback((inputMessage: string) => {
        if (audioRef && audioRef.current && (audioRef.current as any).isRecognizing()) {
            (audioRef.current as any).stopAudio();
            restartSpeechRecognition.current = true;
        } else {
            restartSpeechRecognition.current = false;
        }
        prevTranscript.current = '';
        setInputMessage('');
        if (inputMessage.trim() === '') return;

        const newMessage = {
            type: ChatGptUserType.USER,
            text: inputMessage,
        };

        const updatedConversation = [...conversation, newMessage];
        onSendResponse(updatedConversation, inputMessage);

    }, [conversation, candidateTrackId]);

    const handleSendAudioResponse = () => {
        if (!audioStream) {
            enqueueSnackbar('Please record audio!', { variant: 'error', autoHideDuration: 2500 });
            return;
        }
        handleUpload({ ...audioStream }, (url: string) => {
            const newMessage = {
                type: ChatGptUserType.USER,
                text: currTranscribe.current ?? "",
                answerAudioDetails: {
                    url: audioStream.audio ?? "",
                },
                givenAnswerType: ConversationViewType.AUDIO,
            };
            setAudioStream(undefined);

            const updatedConversation = [...conversation, newMessage];
            onSendResponse(updatedConversation, currTranscribe.current ?? "", url);
            currTranscribe.current = "";
        });
    }

    const onSaveRecording = (recording: any, isVideo: boolean, transcribe: string | undefined) => {
        if (!transcribe?.trim()) {
            enqueueSnackbar('Please speak something in answer.', {
                variant: 'error',
                autoHideDuration: 2500,
            });
            return;
        }
        const file = new File([recording.blob], generateKey(), { type: recording.type });
        const rec = { key: generateKey(), audio: recording.url, file }
        setAudioStream(rec);
        currTranscribe.current = transcribe;
    }

    const handleUpload = (recording: Audio, cb: (url: string) => void) => {
        if (!recording) {
            return;
        }
        const file = recording.file;
        setLoading(true);
        if (file) {
            const newFileName = file.name.replace(/ /g, '');
            const ext = file.type.split('/')[1];
            const type = file.type.split('/')[0];
            const reader = new FileReader();
            const path = directory + `/${newFileName}~${type}~.${ext}`;

            const handleActionAfterUpload = (err?: any, res?: any) => {
                console.log(err)
                setLoading(false);
                if (err) {
                    alert('Failed with error: ' + err);
                    return;
                }
                cb(path);
            }

            reader.onloadend = () => {
                const buffer = Buffer.from(reader.result as any);

                // Getting SignedInCredentials
                evaluationPlatformService.getS3SignedInCredentials({
                    path: path, candidateTrackId: candidateTrackId
                }).then((res) => {
                    let bucketUrl = res.output.url;
                    // Uploading to bucket
                    axios.put(bucketUrl, buffer)
                        .then((res) => {
                            handleActionAfterUpload();
                        }).catch(err => {
                            console.log(err);
                            axios.put(convertHttpsToHttpFromUrl(bucketUrl), buffer)
                                .then((res) => {
                                    handleActionAfterUpload();
                                }).catch(err => {
                                    handleActionAfterUpload(err);
                                })
                        })
                }).catch(err => {
                    handleActionAfterUpload(err);
                });
            };
            reader.readAsArrayBuffer(file);
        }
    };

    useEffect(() => {
        scrollToQuestion();
    }, [conversation, loadingBotResponse]);

    return (
        <StyledChatContainer>
            <OverlayLoader loading={loading} disableOverlay={false} />
            <div className="chat-window">
                <InterviewMeHeader
                    staticTitle={headerTitle}
                    hideCloseBtn
                />
                <>
                    <div className="historyContainer" id="pitch-chat-box-container-onboard">
                        <InterviewMeChatBox
                            conversation={conversation}
                            loadingBotResponse={loadingBotResponse}
                            handleRegenerateResponse={handleRegenerateResponse}
                            removeQuestionScrollEffect={true}
                        />
                        <div ref={endOfMessages}></div>
                    </div>
                    <div className='footer bg-white d-flex align-items-center justify-content-end submit-wrapper'>
                        {!onlyUseMicrophone && !hideBotResponseContainer && <div className='d-flex align-items-center justify-content-between w-100'>
                            <div className='w-100 d-flex'>
                                <ChatAudio
                                    onStop={() => prevTranscript.current = inputMessage}
                                    disabled={false}
                                    onTranscriptChange={handleTranscriptChange}
                                    ref={audioRef}
                                />
                                <TextField
                                    inputRef={textFieldRef}
                                    className='text-field w-100'
                                    value={inputMessage}
                                    margin="none"
                                    id="outlined-textarea"
                                    maxRows={2}
                                    variant="outlined"
                                    placeholder="Enter text response here"
                                    onChange={handleInputChange}
                                    multiline
                                />
                            </div>
                            <div className='d-flex align-items-center flex-column justify-content-center'>
                                <StyledButton
                                    disabled={loadingBotResponse || disableRespond || !inputMessage}
                                    size='small'
                                    variant='contained'
                                    color='primary'
                                    className='m-1 mb-0'
                                    width="9rem"
                                    onClick={() => handleSendMessage(inputMessage)}
                                    title={'Submit Answer'}
                                >
                                    Submit Answer
                                </StyledButton>
                            </div>
                        </div>}
                        {onlyUseMicrophone && !hideBotResponseContainer &&
                            <div className='d-flex align-items-center justify-content-between w-100'>
                                <div>
                                    <RecorderControls
                                        onSaveRecording={onSaveRecording}
                                        allowVoiceRecorder={true}
                                        hideVideoRecorder={true}
                                        compactView={true}
                                        generateTranscribe={true}
                                    />
                                </div>
                                <div className="flex-grow-1 px-3">
                                    {audioStream && <RecordingItem
                                        key={audioStream.key}
                                        isReadOnly={true}
                                        recordKey={audioStream.key!}
                                        isDeleted={false}
                                        audio={audioStream.audio}
                                        onDelete={() => { }}
                                        undoDelete={() => { }}
                                    />}
                                    {!audioStream && <span className="text-muted">Please record your answer</span>}
                                </div>
                                <div className='d-flex align-items-center flex-column justify-content-center'>
                                    <StyledButton
                                        disabled={!audioStream || loadingBotResponse || disableRespond}
                                        size='small'
                                        variant='contained'
                                        color='primary'
                                        className='m-1 mb-0'
                                        width="9rem"
                                        onClick={handleSendAudioResponse}
                                        title={'Submit Answer'}
                                    >
                                        Submit Answer
                                    </StyledButton>
                                </div>
                            </div>
                        }
                    </div>
                </>
            </div>
        </StyledChatContainer>
    )
}

export default PitchChatBox;