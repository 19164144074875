import React, { useEffect, useState } from 'react'
import { evaluationPlatformService } from 'services/evaluationPlatform'
import { getValueBrowserStorage } from 'services/browserStorageService';
import { IGETQUESTIONVIDEO } from 'types';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { CameraVideoFill } from '@styled-icons/bootstrap/CameraVideoFill'
import { Notepad } from '@styled-icons/boxicons-solid/Notepad'
import moment from 'moment';
import queryString from 'query-string';
import { useLocation } from 'react-router';

const StyledButton = styled.div<{ OnVideoPlay?: boolean, TitleUnderline?: boolean }>`
  display: flex;
    .videos{
        font-size: 17px;
        font-weight: 600;
        margin-right: 18px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: ${props => props?.OnVideoPlay ? 'underline #003e95' : 'none'};
    }
    .C_blue{
        color: #003e95;
    }
    .C_black{
        color: black;
    }
    .contents{
        font-size: 17px;
        font-weight: 600;
        margin-right: 18px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: ${props => props?.TitleUnderline ? 'underline #003e95' : 'none'};
    }
    .shoe_btn{
        margin-left: 10px;
    }
    .vartical_line{
        background: black;
        width: 2.5px;
        height: 28px;
        margin-right: 16px;
    }

`;

const StyledContainer = styled.div<any>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-left: ${(props: any) => props.isSplitView ? '0' : '50px'};
    margin-top: 15px;
    .display_title{
        text-align: start;
        font-family: Helvetica, Arial, sans-serif;
        font-weight: 700;
    }
    .description{
        text-align: start;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 13px;
    }
        .video_container{
            display: flex;
            margin-bottom: 25px;
        }
        .video_text_container{
            margin-left: 30px
        }
        .duration{
            text-align: start;
            font-weight: bold;
        }
        .Learn_text{
            font-size: 16px;
            margin-right: 16px;
            font-style: normal;
            color: #000000;
            font-family: 'Lato';
            font-weight: 600;
        }

`;


interface IProps {
    questionId: any;
    splitView?: boolean;
}

const QuestionVideos = (props: IProps) => {
    const { search } = useLocation();
    const _queryParams: any = queryString.parse(search);
    const candidateTrackId = getValueBrowserStorage('candidateTrackId');
    const [contents, setContents] = useState<any>([]);
    const [videos, setVideos] = useState<any>([]);
    const [sowVideos, setsowVideos] = useState<any>(false);
    const [sowContent, setSowContent] = useState<any>(false);
    const [videoPlayAndPush, setVideoPlayAndPush] = useState<any>(false);

    const getQuestionVideosAndContent = async (candidateTrackId: string | null) => {
        const input: IGETQUESTIONVIDEO = {
            candidateTrackId,
            questionId: props.questionId
        };

        try {
            const output = await evaluationPlatformService.getQuestionVideosAndContent(input);
            setVideos(output.output.videos)
            setContents(output.output.contents)

        } catch (e: any) {
            console.log('no question video list found for the candidate');

        };
    }

    useEffect(() => {
        if (props.questionId) {
            getQuestionVideosAndContent(candidateTrackId);
        }
    }, [props.questionId]);

    const handleVideo = () => { setsowVideos(!sowVideos); setSowContent(false); };

    const handleContent = () => { setSowContent(!sowContent); setsowVideos(false) };

    let Duration: any = [];
    let Minutes: any = "";

    videos?.map((element: any) => {
        Duration.push(element.duration);
    })

    let sum = 0;
    for (let i = 0; i < Duration.length; i += 1) {
        Minutes = moment.utc(moment.duration(sum += Duration[i], "seconds").asMilliseconds()).format("mm:ss");
    }

    const videoplay = () => {
        setVideoPlayAndPush(!videoPlayAndPush);
    };


    return (
        <StyledContainer isSplitView={props.splitView}>
            <div>
                <div>
                    <StyledButton OnVideoPlay={videoPlayAndPush} TitleUnderline={sowContent}>
                        {contents.length > 0 || videos.length > 0 ? <p className='Learn_text'>Learn more</p> : null}
                        {videos.length > 0 && <p onClick={() => handleVideo()} className={` videos ${sowVideos ? 'C_blue' : 'C_black'}`}>
                            <CameraVideoFill height={'1.5rem'} fill={sowVideos ? '#003e95' : 'black'} />
                            <span className='shoe_btn'> {videos.length} Videos ({Minutes} mins)</span>
                        </p>}
                        {contents.length > 0 && videos.length > 0 ? <div className='vartical_line'></div> : null}
                        {contents.length > 0 && <p onClick={() => handleContent()} className={` contents ${sowContent ? 'C_blue' : 'C_black'}`}>
                            <Notepad height={'1.5rem'} fill={sowContent ? '#003e95' : 'black'} />
                            <span className='shoe_btn'> {contents.length} Contents</span>
                        </p>}
                    </StyledButton>
                </div>
                <div>
                    {
                        sowVideos &&
                        videos?.map((element: any, index: any) => (
                            <div className='video_container' key={index}>
                                <div>
                                    <ReactPlayer
                                        url={element?.url}
                                        controls
                                        className="react-player"
                                        width="270px"
                                        height="150px"
                                        onPlay={videoplay}
                                        onPause={videoplay}
                                    />
                                </div>
                                <div className='video_text_container d-flex flex-column justify-content-between' style={{ position: 'relative' }}>
                                    <div>
                                        <h5 className='display_title'>{element?.title}</h5>
                                        <div className='description' dangerouslySetInnerHTML={{ __html: element?.description }}></div>
                                    </div>
                                    <div className='duration'>Duration: {
                                        moment.utc(moment.duration(element.duration, "seconds").asMilliseconds()).format("mm:ss")
                                    } mins</div>
                                </div>
                            </div>
                        ))
                    }
                    {
                        sowContent &&
                        contents?.map((element: any, index: any) => (
                            <div key={index}>
                                <h5 className='display_title'>{element?.title}</h5>
                                <div className='description' dangerouslySetInnerHTML={{ __html: element?.description }}></div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </StyledContainer>
    )
}

export default QuestionVideos
