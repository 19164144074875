import React from 'react';
import ReactTooltip from 'react-tooltip';
import AnswerHelpLinks from '../AnswerHelpLinks';

const QuestionLinks = (props: any) => (
    <div className="question__options">
        <AnswerVersionButtons {...props} />
        {!props.communityVersion && <AnswerHelpLinks
            capabilityId={props.capabilityId}
            question={props.question}
            setCurrentAnsVersionId={props.setCurrentAnsVersionId}
            goToPaymentPage={props.goToPaymentPage}></AnswerHelpLinks>}
    </div>
);

const AnswerVersionButtons = (props: any) => {

    let totalAnswers = props.answers?.length;

    return (
        <div className="answer-version-buttons">
            {totalAnswers > 1 ? (
                <>
                    {props.answers.map((item: any, idx: number) => {
                        return (
                            <React.Fragment key={`answerversionbutton-${idx}`}>
                                <button
                                    style={props.answerVersion === idx ? { background: '#6d6d6d', color: 'white' } : {}}
                                    onClick={() => {
                                        props.setAnswerVersion(idx);
                                        props.setIsShowAllVersions(false);
                                        props.setCurrentAnsVersionId({
                                            questionId: props.question._id,
                                            ansVersionId: props.answers[idx]?.answer._id
                                        });
                                        !props.communityVersion && props.saveCandidateLastActivity({
                                            selectedCapabilityId: props.capabilityId,
                                            currentQuestionId: props.question._id,
                                            currentAnsVersionId: props.answers[idx]?.answer._id
                                        })
                                    }}
                                    data-tip={(idx === 0 && !props.communityVersion) ? 'Your current response' : `Version${totalAnswers - idx}`}
                                    data-for="answer-version-icon"
                                >
                                    {(idx === 0 && !props.communityVersion) ? 'CURRENT' : `V${totalAnswers - idx}`}
                                </button>
                                <ReactTooltip id="answer-version-icon" type="dark" />
                            </React.Fragment>
                        );
                    })}
                    <button
                        style={props.isShowAllVersions ? { background: '#6d6d6d', color: 'white' } : {}}
                        onClick={() => {
                            props.setIsShowAllVersions(true);
                            props.setAnswerVersion(-1);
                        }}
                        data-tip="All Versions"
                        data-for="answer-version-icon"
                    >
                        All
                    </button>
                </>
            ) : null}
        </div>
    );
};

const QuestionOptions = (props: any) => {
    return (
        <div>
            <QuestionLinks {...props} />
        </div>
    );
};

export default QuestionOptions;