import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getModalDefaultSettings } from 'utilities';
import { HM_EMAIL } from 'utilities/constants';

export const CalendlyPopup = ({ open, onClose, onConfirm, details, customAnswers, makeAdminGuest }: IProps) => {
    const candidateInfo = useSelector((state: RootState) => state.evaluationPlatform.candidate);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const modalStyle = getModalDefaultSettings(isMobile ? '95%' : '50%');
    let guests: string[] = [];

    if (makeAdminGuest) {
        guests = [HM_EMAIL]
    }

    useCalendlyEventListener({
        onEventScheduled(e) {
            onConfirm(e)
        },
    });

    return (
        <div>
            <Modal
                isOpen={open}
                onRequestClose={onClose}
                style={modalStyle}>
                <InlineWidget
                    url={details.cUrl}
                    prefill={{
                        name: details?.name ?? candidateInfo?.fullname,
                        email: details?.email ?? candidateInfo?.email,
                        customAnswers: customAnswers,
                        guests: guests
                    }}
                    pageSettings={{
                        hideLandingPageDetails: true
                    }}
                    styles={{
                        height: '650px',
                        maxHeight: '650px',
                    }}
                />
            </Modal>
        </div>
    );
}

interface IProps {
    open: boolean;
    onClose: any;
    onConfirm: (e: any) => void;
    details: {
        name?: string;
        email?: string;
        cUrl: string;
    }
    customAnswers?: {
        a1: string;
    },
    makeAdminGuest: boolean;
}