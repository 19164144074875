import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { RootState } from "store";
import { Container } from 'react-bootstrap';
import { getLocalDate } from '../../utilities/constants'
import Rating from '@material-ui/lab/Rating';
import RichTextEditor from "../../components/Common/Editors/RichTextEditor";
import { talkToExpertService } from "../../services/talkToExpert";
import { StructuredFeedbackComponent } from './StructuredFeedbackComponent';
import { IFocussedModule } from './meetingTypes';
import { ModalComponent } from 'components/Common/Modal/Modal';
import { useTalkToExperts } from './useTalkToExpert';

const Main = styled.div<any>`
    padding: 2rem;

    .meeting-details {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem;

        & .detail {
            text-align: left;
            margin-bottom: 1rem;
            width:25%;
        }

        & .detail:nth-of-type(4n) {
            margin-right: 0;	
        }
        
        & .detail:nth-of-type(4n+1) {
            margin-left: 0;	
        }
        * .textWrapper {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .textWrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const MeetingCard = styled(Container)`
    background: #FFFFFF;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin: 10px 0;
    padding: 1rem;
    min-width: 100%;
    @media (min-width: 1200px) {
        max-width: 1190px;
    }
    @media (min-width: 1300px) {
        max-width: 1290px;
    }
`;

const MeetingModulesWrapper = styled.div<any>`
  height: 100px;
`;

const MeetingDetailsComponent = (props: any) => {
    const { experts } = useSelector((state: RootState) => state.talkToExpert);
    const { search } = useLocation();
    const [meetingDetails, setMeetingDetails] = useState<any>();
    const _queryParams: any = queryString.parse(search);
    const [zoomRecordingInfo, setZoomRecordingInfo] = useState<boolean>(false);
    const { getMeetingValue } = useTalkToExperts();

    useEffect(() => {
        talkToExpertService.getMeetingDetails({ meetingDetailId: _queryParams?.meetingDetailId }).then((response) => setMeetingDetails(response.output));
    }, [])

    const getExpertById = (id: string) => experts.find((x: any) => x.expertId === id)?.fullName ?? 'unknown';

    const renderdetails = (label: string, value: any, type: any) => {
        if (type === "link") {
            return <div key={label + 'detail'} className={"detail"}>
                <div className='d-flex'><b>{label}:</b></div>
                <a target="_blank" rel="noopener noreferrer" href={value} className="textWrapper d-block mr-2"><small>{value}</small></a>
            </div>
        }
        return <div key={label + 'detail'} className={"detail"}>
            <div className='d-flex'><b>{label} : </b></div>
            <div className="textWrapper"><small dangerouslySetInnerHTML={{ __html: value }}></small></div>
        </div>
    }

    const renderFocusedModules = (label: string, focussedModules: IFocussedModule[]) => {
        return <MeetingModulesWrapper className="mb-3">
            <div className='d-flex'><b>{label} : </b></div>
            <div className="overflow-auto" style={{ height: "80%" }}>
                {focussedModules.map(cat => cat.children.length ?
                    cat.children.map(subcat => subcat.children.length ?
                        subcat.children.map(cap =>
                            <span className='w-100 d-flex mb-1 border-bottom'>
                                {cat.entityTitle + " | " + subcat.entityTitle + " | " + cap.entityTitle}
                            </span>)
                        : <span className='w-100 d-flex mb-1 border-bottom'>{cat.entityTitle + " | " + subcat.entityTitle}</span>
                    ) :
                    <span className='w-100 d-flex mb-1 border-bottom'>{cat.entityTitle}</span>)}
            </div>
        </MeetingModulesWrapper>

    }

    const renderFeedBack = (label: string, value: any) => {
        return <div className={"w-100 text-left mb-2"}>
            <div><b>{label}</b></div>
            {value.rating && <div className="d-flex align-items-start">
                <Rating
                    name="rating"
                    value={value.rating}
                    disabled={true}
                />
            </div>}
            <RichTextEditor
                disabled={true}
                placeholder="Notes.."
                value={value.comment}
                onChange={() => { }}
                id={'Feedback'}
                customStyles={{ background: 'white', height: '150px', border: '1px solid #ccc', boxShadow: 'none' }}
            />
        </div>
    }

    const renderCandidateNotes = (label: string, notes: string) => {
        return <div className={"w-100 text-left mb-2"}>
            <div><b>{label}</b></div>
            <RichTextEditor
                disabled={true}
                placeholder="Notes.."
                value={notes}
                onChange={() => { }}
                id={'Feedback'}
                customStyles={{ background: 'white', height: '150px', border: '1px solid #ccc', boxShadow: 'none' }}
            />
        </div>
    }

    const meetingId = getMeetingValue('zoomMeetingId', meetingDetails);
    const meetingLink = getMeetingValue('zoomMeetingLink', meetingDetails);

    return (
        <Main>
            <MeetingCard id={'meeting'} className="mt-2">
                <div className="meeting-details">
                    {meetingDetails?.meetingTime && renderdetails("Meeting time", getLocalDate(meetingDetails.meetingTime), "")}
                    {meetingDetails?.expertId && renderdetails("Scheduled with", getExpertById(meetingDetails.expertId), "")}
                    {meetingDetails?.meetingPurpose && renderdetails("Meeting Title", meetingDetails.meetingPurpose, "")}
                    {meetingDetails?.createdAt && renderdetails("Scheduled on", getLocalDate(meetingDetails.createdAt), "")}
                    {meetingId && renderdetails("Meeting ID", meetingId, "")}
                    {meetingLink && renderdetails("Meeting link", meetingLink, "link")}
                    {meetingDetails?.zoomMeetingPassword && renderdetails("Zoom meeting passcode", meetingDetails.zoomMeetingPassword, "")}
                    {meetingDetails?.trackName && renderdetails("Track Name", meetingDetails.trackName, "")}
                    {meetingDetails?.resumeLink && renderdetails("Your Resume Link", meetingDetails.resumeUrl, "link")}
                    {meetingDetails?.candidateFeedback && renderCandidateNotes("Your Questions & Notes to expert", meetingDetails.candidateNotes)}
                    {meetingDetails?.candidateFeedback && renderFeedBack("Your meeting review", meetingDetails.candidateFeedback)}
                    {meetingDetails?.expertFeedback && renderFeedBack("Expert Feedback", meetingDetails.expertFeedback)}
                    {meetingDetails?.focusedModules && renderFocusedModules("Meeting Modules", meetingDetails.focusedModules)}
                    {(meetingDetails?.zoomMeetingRecordedVideoLink || meetingDetails?.zoomMeetingRecordedVideoLinkPassword) &&
                        <div className="w-100 text-right">
                            <button className="btn btn-sm btn-outline-primary ml-2" onClick={() => setZoomRecordingInfo(true)}>{'Zoom Meeting Recording'}</button>
                        </div>}
                </div>
            </MeetingCard>
            {meetingDetails?.focusedModules && !!meetingDetails?.focusedModules.length && <MeetingCard id={'meeting'} className="mt-2">
                <StructuredFeedbackComponent meetingDetailId={_queryParams?.meetingDetailId} expertName={getExpertById(meetingDetails.expertId)} />
            </MeetingCard>}
            {zoomRecordingInfo && <ModalComponent
                handleClose={() => setZoomRecordingInfo(false)}
                show={!!zoomRecordingInfo}
            >
                <Main>
                    <table className="table table-borderless my-4">
                        <tbody>
                            <tr>
                                <td>
                                    <div><b>{'Recorded zoom meeting video link'}</b></div>
                                </td>
                                <td>
                                    <div style={{ maxWidth: "450px" }}>
                                        <a target="_blank" rel="noopener noreferrer" href={meetingDetails.zoomMeetingRecordedVideoLink} className="textWrapper d-block mr-2"><small>{meetingDetails.zoomMeetingRecordedVideoLink}</small></a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div><b>{'Recorded zoom meeting video password'}</b></div>
                                </td>
                                <td>
                                    <div>
                                        <div className="textWrapper"><small dangerouslySetInnerHTML={{ __html: meetingDetails.zoomMeetingRecordedVideoLinkPassword }}></small></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Main>
            </ModalComponent>
            }
        </Main>
    );
}
export default MeetingDetailsComponent;