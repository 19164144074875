import { RecorderControlsProps } from 'components/AudioRecorder/types/recorder';
import React, { useRef, useMemo, useEffect } from 'react';
import Recorder from './Recorder';
import "./styles.css";
import Timer from './Timer';
import useAudioStreamToText from 'components/Common/customHooks/audioStreamToText';

export default function RecorderControls({
  onSaveRecording,
  allowVoiceRecorder,
  setIsRecording,
  hideVideoRecorder,
  compactView,
  generateTranscribe
}: RecorderControlsProps) {

  const timerRef = useRef(null);
  const transcribe = useRef<string[]>([]);

  const { startRecognition, stopRecognition, getTranscribe } = useAudioStreamToText({});

  const recognitionStop = () => {
    stopRecognition();
  }

  const recognitionStart = () => {
    startRecognition();
  }

  const stop = () => {
    if (timerRef?.current) (timerRef.current as any).resetSw(undefined, false);
    setIsRecording && setIsRecording(false);

    if (generateTranscribe) {
      transcribe.current.push(getTranscribe());
      recognitionStop();
    }
  }

  const start = () => {
    if (timerRef?.current) (timerRef.current as any).startSw();
    setIsRecording && setIsRecording(true);

    if (generateTranscribe) {
      recognitionStart();
    }
  }

  const pause = () => {
    if (timerRef?.current) (timerRef.current as any).pauseSw();

    if (generateTranscribe) {
      transcribe.current.push(getTranscribe());
      recognitionStop();
    }
  }

  const cancel = () => {
    if (timerRef?.current) (timerRef.current as any).resetSw(undefined, false);
    setIsRecording && setIsRecording(false);

    if (generateTranscribe) {
      recognitionStop();
      transcribe.current = [];
    }
  }

  const toggleIsVideo = () => {
    if (timerRef?.current) (timerRef.current as any).resetSw(undefined, false);

    if (generateTranscribe) {
      recognitionStop();
      transcribe.current = [];
    }
  }

  const handleRecordingSave = (audio: any, isVideo: boolean) => {
    onSaveRecording(audio, isVideo, transcribe.current.join(" "));
    transcribe.current = [];
  }

  useEffect(() => {
    return () => {
      setIsRecording && setIsRecording(false);
    }
  }, [])

  const recorder = useMemo(() => <Recorder
    onStop={stop}
    onStart={start}
    onPause={pause}
    onCancel={cancel}
    onSaveRecording={handleRecordingSave}
    toggleIsVideo={toggleIsVideo}
    allowVoiceRecorder={allowVoiceRecorder}
    hideVideoRecorder={hideVideoRecorder}
    compactMode={compactView}
  />, [])

  return (
    <div className="card border-0">
      <div className={`d-flex align-items-center justify-content-between flex-column ${compactView ? 'px-3' : 'p-3'}`}>
        <div className={`recorder-display`} >
          {recorder}
          <Timer ref={timerRef} />
        </div>
      </div>
    </div>
  );
}
