import { ButtonsBar } from 'components/Common/ButtonsBar';
import { ComingSoonText } from 'pages/ExpertVideoPage';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styled from 'styled-components';
import { AirtableTrackMapping, SIDEBAR_WIDTH } from 'utilities/constants';
import { Jobs } from './Jobs';
import JobsComponent from './JobsComponent';

const StyledHiringEcosystemContainer = styled.div`
  padding-top: 20px;
  transition: 1s;
  .frame {
    background: transparent;
    border: 1px solid #ccc;;
    height: 80vh;
    width: 100%;
  }
`;

const HiringEcosystemButtons = {
  InterviewBank: "InterviewBank",
  Jobs: "Jobs",
  ApplyForYou: "ApplyForYou",
  JobsForYou: "JobsForYou"
}



export const HiringEcosystemContainer = (props: any) => {
  const candidateInfo = useSelector((state: RootState) => state.evaluationPlatform.candidate);
  const trackName = candidateInfo?.lastCandidateSavedSetting?.lastCandidateTrackWorkedOn;
  const isWithOutBankBtn = trackName && AirtableTrackMapping[trackName] ? HiringEcosystemButtons.InterviewBank : HiringEcosystemButtons.Jobs
  const [selectedButton, setSelectedButton] = useState<any>(isWithOutBankBtn);
  const PageButtons = [
    { label: 'Jobs', value: HiringEcosystemButtons.Jobs },
    { label: 'Jobs for you', value: HiringEcosystemButtons.JobsForYou },
    { label: 'Apply for you', value: HiringEcosystemButtons.ApplyForYou }
  ];

  if (trackName && AirtableTrackMapping[trackName]) {
    PageButtons.unshift({ label: 'Interview Bank', value: HiringEcosystemButtons.InterviewBank })
  }

  useEffect(() => {
    setSelectedButton(isWithOutBankBtn)
  }, [trackName && AirtableTrackMapping[trackName]])

  const btnSelectHandler = (button: string) => {
    setSelectedButton(button);
  }

  const urlsuffix = "?backgroundColor=blue&viewControls=on";

  return (
    <StyledHiringEcosystemContainer {...props}>
      <ButtonsBar
        buttonsInfo={PageButtons}
        selectedPage={selectedButton}
        handleClick={btnSelectHandler}
      />
      <div>
        {selectedButton === HiringEcosystemButtons.InterviewBank && <>
          {(trackName && AirtableTrackMapping[trackName]) ?
            <iframe
              id="hiringEcosystem"
              title="Hiring Ecosystem"
              src={AirtableTrackMapping[trackName] + urlsuffix}
              frameBorder="0"
              className="frame">
            </iframe> :
            <div>No Data Found</div>}
        </>}
        {selectedButton === HiringEcosystemButtons.Jobs && <Jobs history={props.history} />}
        {selectedButton === HiringEcosystemButtons.ApplyForYou && <ComingSoonText />}
        {selectedButton === HiringEcosystemButtons.JobsForYou && <JobsComponent /> }
      </div>
    </StyledHiringEcosystemContainer>
  )
}
