import { Card, CardContent } from "@material-ui/core";
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from "@material-ui/core/styles";
import * as React from 'react';
import { CandidateJobStatus } from 'utilities/constants';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: "17rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));

interface IJobTrack {
    currentStep: number
}

export default function JobTrack({ currentStep }: IJobTrack) {
    const classes = useStyles();

    return (
        <Card className={classes.wrapper}>
            <CardContent>
                <Stepper activeStep={currentStep} alternativeLabel>
                    {currentStep === CandidateJobStatus.VETTED_REJECTED ? <Step key={CandidateJobStatus.VETTED_REJECTED}>
                        <StepLabel error={true}>{"Rejected"}</StepLabel>
                    </Step> :
                        <Step key={CandidateJobStatus.VETTED_ACCEPTED}>
                            <StepLabel>{"Vetted"}</StepLabel>
                        </Step>
                    }
                    {(currentStep !== CandidateJobStatus.VETTED_REJECTED) &&
                        <Step key={CandidateJobStatus.INTERVIEW_REQUEST}>
                            <StepLabel>{"Interview"}</StepLabel>
                        </Step>}
                    {(currentStep !== CandidateJobStatus.VETTED_REJECTED) && (currentStep === CandidateJobStatus.REJECTED_CANDIDATE
                            ? <Step key={CandidateJobStatus.REJECTED_CANDIDATE}>
                                <StepLabel error={true}>{"Rejected"}</StepLabel>
                            </Step> :
                            <Step key={CandidateJobStatus.FINALIZED_CANDIDATE}>
                                <StepLabel>{"Finalised"}</StepLabel>
                            </Step>)}
                </Stepper>
            </CardContent>
        </Card>
    );
}