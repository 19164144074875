import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ChatGptUserType } from 'containers/GPTChatBotContainer/useGPTChatBot';
import { IConversation } from 'containers/InterviewMeContainer';
import { getQuestionChatHistory } from 'services/evaluationPlatform';
import { IGetChatHistory, IQuestion } from 'types';

interface ICodingInitialState {
    language: string;
    theme: string;
    code: string;
    output: string;
}

interface ITextAreaInitialState {
    textAreaValue: string;
}

interface IBotInitialState {
    interviewMeLoading: boolean,
    showInterviewMeBot: boolean,
    showInterviewMeCodeBot: boolean,
    candidateTrackId?: string,
    currentAnswerId?: string,
    questionTitle?: string,
    currentAnswer?: string,
    codeAnswer?: string,
    currentQuestion?: IQuestion,
    conversation: IConversation[],
}
interface IInitialState {
    addSketchInProgress: boolean;
    coding: ICodingInitialState;
    bot: IBotInitialState;
    textAreaPane: ITextAreaInitialState;
}

const initialState: IInitialState = {
    addSketchInProgress: false,
    coding: {
        language: 'javascript',
        theme: 'monokai',
        code: '',
        output: '',
    },
    bot: {
        showInterviewMeBot: false,
        showInterviewMeCodeBot: false,
        interviewMeLoading: false,
        conversation: []
    },
    textAreaPane: {
        textAreaValue: "",
    }
};

export const getInterviewMeChatHistory = createAsyncThunk('chat/getInterviewMeChatHistory', async (data: IGetChatHistory) => {
    return getQuestionChatHistory(data);
});

const splitView = createSlice({
    name: 'stripePayment',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getInterviewMeChatHistory.pending, (state, action) => {
            state.bot.interviewMeLoading = true;
            state.bot.conversation = [];
        });
        builder.addCase(getInterviewMeChatHistory.fulfilled, (state, action) => {
            state.bot.interviewMeLoading = false;
            if (action && action.payload) {
                const response = action.payload;
                const conversations: IConversation[] = [];
                (response.output.history || []).forEach((data: any) => {
                    if (data.question) {
                        const bot: IConversation = { type: ChatGptUserType.BOT, text: data.question, score: data.averageScore, isHistory: true }
                        conversations.push(bot);
                    }
                    if (data.answer) {
                        const user: IConversation = { type: ChatGptUserType.USER, text: data.answer, isHistory: true };
                        conversations.push(user);
                    }
                });
                const followUp = response.output.followUpQuestion
                if (followUp && followUp.followUpQuestion) {
                    const bot: IConversation = {
                        type: ChatGptUserType.BOT,
                        text: followUp.followUpQuestion,
                        score: followUp.score,
                        followUpLevelOrder: followUp.followUpLevelOrder,
                        followUpQuestion: followUp.followUpQuestion,
                        followUpQuestionId: followUp.followUpQuestionId,
                        maxSortOrder: followUp.maxSortOrder,
                        parentEvaluationId: followUp.parentEvaluationId,
                        questionAnswerId: followUp.questionAnswerId,
                        givenScore: followUp.givenScore,
                    }
                    conversations.push(bot)
                }
                state.bot.conversation = conversations;
            }
        });
        builder.addCase(getInterviewMeChatHistory.rejected, (state, action) => {
            state.bot.interviewMeLoading = false;
        });
    },
    reducers: {
        setSketchInProgress(state, action) {
            state.addSketchInProgress = action.payload;
        },
        setLanguage(state, action) {
            state.coding.language = action.payload;
        },
        setTheme(state, action) {
            state.coding.theme = action.payload;
        },
        setCode(state, action) {
            state.coding.code = action.payload;
        },
        setCodingOutput(state, action) {
            state.coding.output = action.payload;
        },
        setShowInterviewMeBot(state, action) {
            state.bot.showInterviewMeBot = action.payload;
        },
        setShowInterviewMeCodeBot(state, action) {
            state.bot.showInterviewMeCodeBot = action.payload;
        },
        setCandidateTrackId(state, action) {
            state.bot.candidateTrackId = action.payload;
        },
        setQuestionTitle(state, action) {
            state.bot.questionTitle = action.payload;
        },
        setCurrentAnswer(state, action) {
            state.bot.currentAnswer = action.payload;
        },
        setCurrentCode(state, action) {
            state.bot.codeAnswer = action.payload;
        },
        setCurrentAnswerId(state, action) {
            state.bot.currentAnswerId = action.payload;
        },
        setCurrentQuestion(state, action) {
            state.bot.currentQuestion = action.payload;
        },
        setInterviewMeConversation(state, action) {
            state.bot.conversation = action.payload;
        },
        clearInterviewMeConversation(state) {
            state.bot.conversation = [];
        },
        setTextAreaValue(state, action) {
            state.textAreaPane.textAreaValue = action.payload;
        }
    }
});

export const {
    setSketchInProgress,
    setLanguage,
    setTheme,
    setCode,
    setCodingOutput,
    setShowInterviewMeBot,
    setShowInterviewMeCodeBot,
    setCandidateTrackId,
    setQuestionTitle,
    setCurrentAnswer,
    setCurrentCode,
    setCurrentAnswerId,
    setCurrentQuestion,
    setInterviewMeConversation,
    clearInterviewMeConversation,
    setTextAreaValue
} = splitView.actions;

export default splitView.reducer;
