import { Box, Button, Card, CardActions, CardContent, Grid, Link, Paper, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import { CheckCircleFill } from "@styled-icons/bootstrap/CheckCircleFill";
import { LinkExternal } from '@styled-icons/boxicons-regular';
import { ChevronUpCircle } from "@styled-icons/boxicons-solid";
import { BlueInterviewHelpLogoIcon } from 'assets';
import { IconContainer } from "components/Common/IconContainer";
import { ModalComponent } from "components/Common/Modal/Modal";
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { ITrackJob } from 'types';
import { CandidateJobStatus } from "utilities/constants";
import JobTrack from "./JobTrack";
import QualificationScoreComponent from "./QualificationScoreComponent";
import { LightningCharge } from "@styled-icons/bootstrap/LightningCharge";

interface IJobComponent {
    job: ITrackJob;
    onApply: (item: ITrackJob) => void
}

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: "5px",
        position: "relative",
        boxShadow:
            "0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08)",
        [theme.breakpoints.only("xs")]: {
            margin: "0",
            height: "auto",
        },
        [theme.breakpoints.down("sm")]: {
            height: "100%",
        },
    },
    grid: {
        [theme.breakpoints.only("xs")]: {
            marginLeft: "0px !important",
        },
    },
    parent: {
        [theme.breakpoints.down("sm")]: {
            rowGap: "20px",
        },
        [theme.breakpoints.only("xs")]: {
            gap: "20px",
        },
    },
    trackTitle: {
        fontWeight: "bold",
        fontSize: "16px"
    },
    center: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            flexDirection: "column",
        },
    },
    trackContainer: {
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            height: "auto",
        },
    },
    jobDescription: {
        color: "#5B94E3",
        display: "flex",
        alignItems: "center",
        fontSize: "12px"
    },
    subDetails: {
        borderStyle: "dashed !important",
        borderColor: "#e1e3ea !important",
    },
    subDetailsText: {
        color: "#5e6278",
        fontWeight: "bold",
        fontSize: "14px",
        textTransform: "capitalize"
    },
    trackInfoTitle: {
        marginBottom: '10px',
        display: "flex",
        alignItems: "center"
    },
    btn: {
        color: "#fff",
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        borderRadius: "10px",
        border: 0,
        fontWeight: 600,
    },
    successBtn: {
        backgroundColor: "#009ef7",
        fontWeight: 600,
        '&:hover': {
            backgroundColor: "#2196f3",
        }
    },
    infoBtn: {
        backgroundColor: "#50cd89",
        fontWeight: 600,
        '&:hover': {
            backgroundColor: "rgb(80 205 137)",
        }
    },
    badge: {
        width: "5rem",
        float: "right",
        display: "block",
        padding: "0 0.5rem",
        background: "#fc6",
        fontSize: "0.7rem",
        lineHeight: "1.5rem",
        fontWeight: 600,
        fontStyle: "italic",
        textTransform: "uppercase",
        pointerEvents: "none",
        boxShadow: "none",
        position: "absolute",
        right: 0,
        top: 0,
    },
    acceptedBadge: {
        background: "#50cd89",
    },
    rejectedBadge: {
        background: "#f1416c",
    },
    logoWrapper: {
        backgroundColor: "#f9f9f9",
        display: "inline-block",
        flexShrink: 0,
        position: "relative",
        borderRadius: "0.475rem",
        width: "50px !important"
    },
    cardHeader: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        minHeight: "70px",
        padding: "0 1rem",
        backgroundColor: "#00000000",
        borderRadius: "0.5rem 0.5rem 0 0",
        height: "50px",
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    logo: {
        width: "50px",
        height: "50px",
        display: "inline-block",
        borderRadius: "0.475rem",
        padding: "0.75rem",
        verticalAlign: "middle"
    },
    nameWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "0.5rem 0",
        flexWrap: "wrap",
    },
    jobTitle: {
        color: "#1b2437 !important",
        maxWidth: "100%",
        display: "inline-block",
        lineHeight: "22px",
        fontSize: "17px",
        fontWeight: 600,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    name: {
        color: "#666",
        fontWeight: 600,
        alignItems: "center",
        fontSize: "15px",
    },
    body2: {
        color: "#b5b5c3",
        fontWeight: 600,
    },
    qualificationText: {
        cursor: "pointer",
        color: "#5B94E3",
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        marginTop: "10px"
    },
    applied: {
        color: "#28a745 !important"
    }
}));

const CustomTooltip = withStyles({
    tooltip: {
        backgroundColor: "white",
        padding: 0
    }
})(Tooltip);

const JobComponent = ({ job, onApply }: IJobComponent) => {
    const classes = useStyles();

    const [open, setOpen] = useState<string | undefined>();

    const handleTooltipClose = () => {
        setOpen(undefined);
    };

    const handleTooltipOpen = (jobId: string) => {
        setOpen(jobId);
    };

    const renderQualificationScore = useMemo(() => {
        return (
            <div onClick={() => handleTooltipOpen(job.jobId)} className={classes.qualificationText + " font-weight-bold d-flex mr-1 align-items-center"}>
                {"Minimum eligibility scores"}
                <IconContainer height="12px" icon={ChevronUpCircle} />
            </div>);
    }, [job])

    return <>
        <Grid
            className={classes.grid}
            justifyContent="flex-start"
            lg={3}
            md={4}
            sm={6}
            xs={12}
        >
            <Card
                title={job.isQualified ? undefined : "To qualify for the job, higher scores are necessary in the specified capabilities"}
                className={classes.card}>
                {job.isQualified &&
                    <Paper className={classes.badge}>{'Qualified'}</Paper>}
                <Box className={classes.cardHeader + " pt-9 border-0"}>
                    <Box className={classes.logoWrapper}>
                        <img className={classes.logo} src={job.logoUrl || BlueInterviewHelpLogoIcon} />
                    </Box>
                    <Box title={job.companyProvide} className={classes.nameWrapper}>
                        <div className={classes.jobTitle + " px-3" }>{job.jobTitle}</div>
                        <span className={classes.name + " px-3"}>{job.companyProvide}</span>
                    </Box>
                </Box>
                <CardContent>
                    <Grid container direction="row" className={classes.trackContainer}>
                        <Grid item lg={12} className={classes.trackInfoTitle}>
                            {job.fastTrack && <IconContainer tooltip="Fast-track apply" height="16px" icon={LightningCharge} />}
                            <Typography
                                variant={"h5"}
                                className={classes.trackTitle + " text-left"}
                            >
                                {job.title}
                            </Typography>
                        </Grid>
                        <Grid item lg={12} className={"text-left"}>
                            <Link
                                target="_blank"
                                className={classes.jobDescription}
                                href={job.jobLink}
                                underline="hover"
                            >{'View Job Description '}<IconContainer height="12px" icon={LinkExternal} /></Link>
                        </Grid>
                        <Grid item lg={12} className={"text-left d-flex flex-wrap"}>
                            <Box className={classes.subDetails + " border rounded px-3 py-2 ml-0 m-1"}>
                                <Typography
                                    variant={"h6"}
                                    className={classes.subDetailsText + " text-left"}
                                >
                                    {job.jobType}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    className={classes.body2}
                                >{"Job Type"}</Typography>
                            </Box>
                            <Box className={classes.subDetails + " border rounded px-3 py-2 m-1"}>
                                <Typography
                                    variant={"h6"}
                                    className={classes.subDetailsText + " text-left"}
                                >
                                    {job.openPositions}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className={classes.body2}
                                    color="textSecondary"
                                >{"Open Positions"}</Typography>
                            </Box>
                            <Box className={classes.subDetails + " border rounded px-3 py-2 m-1"}>
                                <Typography
                                    variant={"h6"}
                                    className={classes.subDetailsText + " text-left"}
                                >
                                    {moment(job.createdAt).format("MMM DD, YY")}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className={classes.body2}
                                    color="textSecondary"
                                >{"Created On"}</Typography>
                            </Box>
                            <Box className={classes.subDetails + " border rounded px-3 py-2 mr-0 m-1"}>
                                <Typography
                                    variant={"h6"}
                                    className={classes.subDetailsText + " text-left"}
                                >
                                    {job.totalVettedCandidates}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className={classes.body2}
                                    color="textSecondary"
                                >{"Job Applicants"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={12} className={"text-left d-flex flex-wrap"}>
                            {renderQualificationScore}
                        </Grid>
                        <Grid item lg={12} className={((CandidateJobStatus[job.candidateJobStatus as any] as any) >= 0 ? "visible" : "invisible") + " text-left d-flex flex-wrap"}>
                            <CustomTooltip
                                placement="top-end"
                                arrow
                                title={
                                    <JobTrack currentStep={CandidateJobStatus[job.candidateJobStatus as any] as any} />
                                }
                            >
                                <div className={classes.qualificationText + " font-weight-bold d-flex mr-1 my-3 align-items-center"}>
                                    {"Track your job application"}
                                    <IconContainer height="12px" icon={ChevronUpCircle} />
                                </div>
                            </CustomTooltip>
                        </Grid>
                    </Grid>
                </CardContent>
                <Grid
                    container
                    direction="row"
                    className={classes.center}
                    spacing={2}
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <CardActions className="w-100">
                            <Button
                                disabled={(CandidateJobStatus[job.candidateJobStatus as any] as any) >= 0}
                                component="button"
                                variant="contained"
                                disableRipple
                                onClick={() => onApply(job)}
                                className={(!job.isQualified ? classes.infoBtn : classes.successBtn) +" " +classes.btn +" w-100 " + ((CandidateJobStatus[job.candidateJobStatus as any] as any) >= 0 && classes.applied)}
                            >
                                {(CandidateJobStatus[job.candidateJobStatus as any] as any) >= 0 && <IconContainer height="14px" color={"#28a745"} icon={CheckCircleFill} />}
                                {!job.isQualified ? 'Get qualified' : ((CandidateJobStatus[job.candidateJobStatus as any] as any) >= 0 ? 'Applied' : 'Apply for job')}
                            </Button>
                        </CardActions>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
        {open && <ModalComponent
            size="sm"
            show={open == job.jobId}
            handleClose={() => setOpen(undefined)}
            showCloseIcon={true}
            header="Minimum Eligibility Scores"
        >
            <QualificationScoreComponent attributes={job.attributes} />
        </ModalComponent>}
    </>
}

export default JobComponent;