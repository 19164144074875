import { IconContainer } from 'components/Common/IconContainer';
import React from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'styled-icons/boxicons-regular';


const PaginationContainer = styled.div`
    min-width: 8rem;
    padding: 10px 0;
`;

interface IQuestionPagination {
    handlePreviousQuestion: () => void;
    handleNextQuestion: () => void;
    currentQuestionInx: number;
    totalQuestions: number;
}

const QuestionPagination = ({ handlePreviousQuestion,
    handleNextQuestion, currentQuestionInx, totalQuestions }: IQuestionPagination) => {
    return <PaginationContainer className="d-flex align-items-center ml-auto">
        <IconContainer height={"24px"} icon={ChevronLeft} onClick={handlePreviousQuestion}>
        </IconContainer>
        {(currentQuestionInx + 1) + ' of ' + totalQuestions}
        <IconContainer height={"24px"} icon={ChevronRight} onClick={handleNextQuestion}>
        </IconContainer>
    </PaginationContainer>
}

export default QuestionPagination;