import { AccordionSummary, Badge, Chip } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { ChevronDown, ChevronUp } from "@styled-icons/boxicons-regular";
import React, { useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap";
import 'react-tabs/style/react-tabs.css';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { evaluationPlatformService } from 'services/evaluationPlatform';
import { IQuestion, SharedQuestionData } from "../types";
import SharedAnswerComponent from "./SharedAnswerComponent";
import "./Question.css"

const accordionStyle = {
    backgroundColor: "#f8f8f8",
    boxShadow: "none"
};
interface ISharedAnswers {
    currentQuestion: IQuestion;
}

const SharedAnswers = ({ currentQuestion }: ISharedAnswers) => {
    const [question, setQuestion] = useState<SharedQuestionData>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const candidateTrackId = getValueBrowserStorage('candidateTrackId')
    const [expandedCandidates, setExpandedCandidates] = useState<boolean[]>([]);

    const getSharedAnswers = async () => {
        if (candidateTrackId) {
            try {
                setIsLoading(true);
                const payload = {
                    questionId: currentQuestion.question._id,
                    candidateTrackId,
                }
                const data = await evaluationPlatformService.getSharedQuestions(payload);
                if (data && data.output && data.output.sharedQuestion) {
                    const sharedQuestion: SharedQuestionData = data.output.sharedQuestion;
                    const initialExpandedState = [...Array(sharedQuestion.candidate)].map(() => false);
                    initialExpandedState[0] = true;
                    const reversedAnswers = sharedQuestion.candidate.map(candidate => ({
                        ...candidate,
                        answers: candidate.answers.reverse(),
                    }));
                    sharedQuestion.candidate = reversedAnswers;
                    setExpandedCandidates(initialExpandedState);
                    setQuestion(sharedQuestion);
                }
            } catch (error) {
                console.error("API call failed:", error);
            } finally {
                setIsLoading(false);
            }
        }
    }

    const handleExpandCollapse = (index: number) => {
        setExpandedCandidates((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            newExpanded[index] = !newExpanded[index];
            return newExpanded;
        });
    };

    function maskFullName(fullName: string) {
        if (!fullName) {
            return null;
        }

        const names = fullName.split(' ');
        if (names.length === 2) {
            const maskedFirstName = names[0].length > 2 ? names[0][0] + names[0][1] + '*'.repeat(names[0].length - 2) : names[0];
            return maskedFirstName;
        } else {
            return fullName.length > 2 ? fullName[0] + fullName[1] + '*'.repeat(fullName.length - 2) : fullName;
        }
    }

    useEffect(() => {
        getSharedAnswers();
    }, [])

    return <>
        {isLoading && <Spinner animation='border' />}
        {question ? question.candidate?.map((candidate, index) => (
            <Box margin={2} key={index}>
                <Accordion style={accordionStyle} expanded={expandedCandidates[index]} onChange={() => handleExpandCollapse(index)}>
                    <AccordionSummary
                        expandIcon={
                            expandedCandidates[index] ? (
                                <ChevronUp size={20} />
                            ) : (
                                <ChevronDown size={20} />
                            )
                        }
                        aria-controls={`panel${index + 1}a-content`}
                        id={`panel${index + 1}a-header`}
                    >
                        <div className="d-flex justify-content-start align-items-center small">
                            <Typography color="primary" variant="overline">
                                {maskFullName(candidate?.fullName)}
                            </Typography>
                            <Chip className="ml-2 chip-xs" size='small' label={candidate.title} color="default" />
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SharedAnswerComponent
                            currentQuestion={currentQuestion}
                            sharedAnswers={candidate.answers}
                            candidateId={candidate.candidateId}
                        />
                    </AccordionDetails>
                </Accordion>
            </Box >
        )) : <div className='mx-2 my-4 text-muted text-dark small'>No Shared Answers Available</div>}
    </>
}

export default SharedAnswers;