import { TracksDescription } from 'components';
import CapabilityVideos from 'components/Question/CapabilityVideos';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store';
import { setCapability } from 'store/evaluationPlatform';
import { ColorCode, Entity, TrackEnrollType } from 'utilities/constants';
import { isPlacementTrack } from 'utilities/helperFunctions';
import { arraysAreEqualByKey } from '../../../utilities/helperFunctions';
import BreadCrumbs, { BreadCrumbItem } from '../Components/BreadCrumbs';
import CapabilityTitle from '../Components/CapabilityTitle';
import QuestionPagination from '../Components/QuestionPagination';
import { QuestionStatus } from '../Components/QuestionStatus';
import { Capability, ICandidateTrackData, IQuestion } from '../types';
import CustomQuestionTabs from './CustomQuestionTabs';
import QuestionDescription from './QuestionDescription';
import QuestionEvaluation from './QuestionEvaluation';
import QuestionHint from './QuestionHint';
import QuestionSampleSolution from './QuestionSampleSolution';
import SharedAnswers from './SharedAnswers';
import { setShowInterviewMeBot, setShowInterviewMeCodeBot } from 'store/splitView';
interface Props {
    initialTabIndex?: number;
    onQuestionChange: (question: IQuestion) => void;
}

const getFilteredQuestions = (capability: Capability, status: string) => {
    const questions = capability.questions.filter(q => q.question.status === status);
    return status === "All" ? capability.questions : questions;
}

const QuestionPane: React.FC<Props> = ({ initialTabIndex = 0, onQuestionChange }) => {
    const dispatch = useAppDispatch();
    const { currentQuestionId } = useSelector((state: RootState) => state.evaluationPlatform);
    const [questionFilter, setQuestionFilter] = useState('All');
    const enrollmentType = useSelector((state: any) => state.evaluationPlatform.currentTrack?.candidateTrack[0]?.trackEnrollType);
    const [selectedCategoryInx, setSelectedCategoryInx] = useState<number>(-1);
    const candidateTrack: ICandidateTrackData = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack[0]);
    const selectedCapabilityId = useSelector((state: RootState) => state.evaluationPlatform.selectedCapabilityId);
    const [filteredQuestions, setFilteredQuestions] = useState<IQuestion[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const selectedCapability: Capability | undefined = useMemo(() => {
        return candidateTrack?.capabilities?.find(
            capability => capability.capabilityId === selectedCapabilityId
        )
    }, [candidateTrack, selectedCapabilityId])
    const currentQuestion = filteredQuestions[currentQuestionIndex]?.question;

    const [tabValue, setTabValue] = useState(initialTabIndex);

    useEffect(() => {
        if (selectedCapability) {
            let questions = questionFilter !== "All" ? getFilteredQuestions(selectedCapability, questionFilter) : selectedCapability.questions;
            const questionIndex = questions.findIndex((question: IQuestion) => question.question._id === currentQuestionId);
            const inx = questionIndex > -1 ? questionIndex : 0;
            setCurrentQuestionIndex(questionIndex > -1 ? questionIndex : 0);
            setTabValue(tabValue ?? (questionIndex > -1 ? 0 : 5));
            onQuestionChange(questions[inx]);
        }
    }, [currentQuestionId, selectedCapability]);


    useEffect(() => {
        if (selectedCapability) {
            let questions = questionFilter !== "All" ? getFilteredQuestions(selectedCapability, questionFilter) : selectedCapability.questions;
            if (filteredQuestions?.length && !arraysAreEqualByKey(questions.map(q => q.question), filteredQuestions.map(q => q.question), "_id")) {
                setFilteredQuestions(questions);
                if (questions.length) {
                    const newIndex = questions.findIndex((question: IQuestion) => question.question._id === currentQuestionId);
                    const questionIndex = newIndex > -1 ? newIndex : 0;
                    onQuestionChange(questions[questionIndex]);
                }
            }
        }
    }, [questionFilter, selectedCapability]);

    useEffect(() => {
        if (!filteredQuestions?.length && selectedCapability?.questions.length) {
            setFilteredQuestions(selectedCapability?.questions)
        }

    }, [selectedCapability?.questions])

    const handleTabChange = (_: any, newValue: number) => {
        setTabValue(newValue);
    };

    const handlePreviousQuestion = () => {
        if (selectedCapability && currentQuestionIndex > 0) {
            onQuestionChange(filteredQuestions[currentQuestionIndex - 1]);
            setTabValue(0);
        }
    };

    const handleNextQuestion = () => {
        if (selectedCapability && currentQuestionIndex < filteredQuestions.length - 1) {
            onQuestionChange(filteredQuestions[currentQuestionIndex + 1]);
            setTabValue(0);
        }
    };

    const handleCategorySelect = (id: string) => {
        const categoryInx = candidateTrack?.category?.findIndex(cat => cat.categoryId === id);
        if (categoryInx > -1) {
            dispatch(setCapability(candidateTrack?.category[categoryInx].subCategories[0].capabilities[0].capabilityId));
            setSelectedCategoryInx(categoryInx);
        }
    }

    const handleSubCategorySelect = (id: string) => {
        const categoryInx = candidateTrack?.category?.findIndex(cat => cat.categoryName === selectedCapability?.category);
        if (categoryInx > -1) {
            const subCategoryInx = candidateTrack?.category[categoryInx]?.subCategories.findIndex(sub => sub.subCategoryId === id);
            if (subCategoryInx > -1) {
                dispatch(setCapability(candidateTrack?.category[categoryInx]?.subCategories[subCategoryInx].capabilities[0].capabilityId));
            }
            setSelectedCategoryInx(categoryInx);
        }
    }

    const handleCapabilitySelect = (id: string) => {
        dispatch(setCapability(id));
    }

    const handleQuestionSelect = (id: string) => {
        if (selectedCapability) {
            const questionInx = filteredQuestions.findIndex(ques => ques.question._id === id);
            if (questionInx > -1) {
                onQuestionChange(filteredQuestions[questionInx]);
                setTabValue(0);
            }
        }
    }

    const categoryInx = candidateTrack?.category?.findIndex(cat => selectedCapability && cat.categoryName === selectedCapability.category);
    const subCategoryInx = categoryInx > -1 ? candidateTrack?.category[categoryInx]?.subCategories.findIndex(sub => selectedCapability && sub.subCategoryName === selectedCapability.subCategory) : -1;

    const questionId = currentQuestion?._id;

    const breadCrumbItems: BreadCrumbItem[] = [
        {
            id: '1',
            color: ColorCode[Entity.CATEGORY],
            value: selectedCapability?.category || "",
            options: candidateTrack?.category?.map(cat => ({
                id: cat.categoryId,
                label: cat.categoryName,
                onClick: handleCategorySelect
            }))
        },
        {
            id: '2',
            color: ColorCode[Entity.SUBCATEGORY],
            value: selectedCapability?.subCategory || "",
            options: categoryInx > -1 ? candidateTrack?.category[categoryInx]?.subCategories?.map(sub => ({
                id: sub.subCategoryId,
                label: sub.subCategoryName,
                onClick: handleSubCategorySelect
            })) : []
        },
        {
            id: '3',
            color: ColorCode[Entity.CAPABILITY],
            value: selectedCapability?.capabilityName || "",
            options: subCategoryInx > -1 ? candidateTrack?.category[categoryInx]?.subCategories[subCategoryInx]?.capabilities?.map(cap => ({
                id: cap.capabilityId,
                label: cap.capabilityName,
                onClick: handleCapabilitySelect
            })) : []
        },
        {
            id: '4',
            color: ColorCode[Entity.QUESTION],
            value: filteredQuestions[currentQuestionIndex]?.question.title || "",
            options: filteredQuestions.map(ques => ({ id: ques.question._id, label: ques.question.title, onClick: handleQuestionSelect })) || []
        },
    ];

    const memoizedBreadCrumbs = useMemo(() => (
        <div className='d-flex px-2 align-items-start justify-content-between'>
            <BreadCrumbs items={breadCrumbItems} />
            {selectedCapability && <QuestionStatus
                capabilityStatus={selectedCapability.capabilityStatus}
                setQuestionFilter={setQuestionFilter}
                questionFilter={questionFilter}
            />}
        </div>
    ), [breadCrumbItems, selectedCapability, setQuestionFilter, questionFilter]);

    const memoizedCustomQuestionTabs = useMemo(() => (
        <CustomQuestionTabs
            tabValue={tabValue}
            hintsAvailable={!!filteredQuestions[currentQuestionIndex]?.question.hintsAvailable}
            sampleSolutionsAvailable={!!filteredQuestions[currentQuestionIndex]?.question.sampleSolutionsAvailable}
            handleTabChange={handleTabChange}
            sharedAnswersAvailable={!!filteredQuestions[currentQuestionIndex]}
            evaluationAvailable={filteredQuestions[currentQuestionIndex] && enrollmentType !== TrackEnrollType.FORPLACEMENT}
        >
            <QuestionPagination
                handlePreviousQuestion={handlePreviousQuestion}
                handleNextQuestion={handleNextQuestion}
                currentQuestionInx={currentQuestionIndex}
                totalQuestions={filteredQuestions?.length || 0}
            />
        </CustomQuestionTabs>
    ), [tabValue, filteredQuestions, currentQuestionIndex, handleTabChange, enrollmentType, selectedCapability]);

    const memoizedTracksDescription = useMemo(() => (
        tabValue === 5 && selectedCapability && (
            <>
                <CapabilityTitle capabilityName={selectedCapability?.capabilityName || ""} currentCapability={selectedCapability} />
                <TracksDescription
                    description={selectedCapability.description}
                    hideIcon={true}
                />
                {!isPlacementTrack() && <CapabilityVideos splitView={true} />}
            </>
        )
    ), [tabValue, selectedCapability]);

    const memoizedQuestionDescription = useMemo(() => (
        tabValue === 0 && (
            <QuestionDescription question={{
                number: currentQuestionIndex + 1,
                id: filteredQuestions[currentQuestionIndex]?.question._id!,
                title: filteredQuestions[currentQuestionIndex]?.question.title || "",
                description: filteredQuestions[currentQuestionIndex]?.question.description || "",
            }} />
        )
    ), [tabValue, currentQuestionIndex, filteredQuestions]);

    const memoizedQuestionHint = useMemo(() => (
        tabValue === 1 && questionId && (
            <QuestionHint
                questionId={questionId}
            />
        )
    ), [tabValue, questionId]);

    const memoizedQuestionSampleSolution = useMemo(() => (
        tabValue === 2 && questionId && (
            <QuestionSampleSolution
                questionId={questionId}
            />
        )
    ), [tabValue, questionId]);

    const memoizedSharedAnswers = useMemo(() => (
        tabValue === 3 && filteredQuestions[currentQuestionIndex] && (
            <SharedAnswers currentQuestion={filteredQuestions[currentQuestionIndex]} />
        )
    ), [tabValue, filteredQuestions, currentQuestionIndex]);

    const memoizedQuestionEvaluation = useMemo(() => (
        tabValue === 4 && questionId && (
            <QuestionEvaluation
                questionId={questionId}
            />
        )
    ), [tabValue, questionId]);

    return (
        <div>
            <div id={'searchComPortalDiv'}></div>
            {memoizedBreadCrumbs}
            {memoizedCustomQuestionTabs}
            <div>
                {memoizedTracksDescription}
                {memoizedQuestionDescription}
                {memoizedQuestionHint}
                {memoizedQuestionSampleSolution}
                {memoizedSharedAnswers}
                {memoizedQuestionEvaluation}
            </div>
        </div>
    );

};

export default memo(QuestionPane);
