import { Tab } from '@material-ui/core';
import styled from 'styled-components';

const CompactTab = styled(Tab) <{ isSelected: boolean }>`
  && {
    min-width: auto;
    display: flex;
    align-items: center;
    padding: 2px 6px;
    min-height: 30px;
    font-weight: bold;
    border-bottom: 3px solid transparent;
    color: ${({ isSelected }) => (isSelected ? 'black' : 'inherit')};
    &:hover {
      background-color: transparent;
    }
    &.Mui-selected,
    &.Mui-selected:hover {
      border-bottom: 3px solid black;
    }
    & span {
      font-size: 12px;
    }
  }
`;

export default CompactTab;