import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { evaluationPlatformService } from "services";
import { getValueBrowserStorage } from "services/browserStorageService";
import { RootState } from "store";
import styled from "styled-components";
import { Candidate_Id, Candidate_Track_Id } from "utilities/constants";
import AppliedJobCard from "../Pitch/Components/AppliedJobCard";
import { ScoreContainer } from "./Components/StyledContainers";
import { JobDetailsType } from "types";
import { useSnackbar } from "notistack";
import { round } from "lodash";

type IProps = {
    handleJobSelect: (job: JobDetailsType) => void
}

const StyledWrapper = styled.div`
    padding: 2rem;

    .applied-job-container {
        display: flex;
        overflow: auto;
        gap: 1rem;
        padding: 10px;
    }

    .complete-profile-section {
        border: 1px solid #bfd1ff;
        border-radius: 1rem;
        text-align: start;
        padding: 2rem 6rem;
    }

    .completion-percent {
        flex-basis: 15%;   
    }

    .completion-percent-line {
        flex-basis: 60%;   
    }
`

type TrackType = {
    job: JobDetailsType,
    trackName: string,
    aiScore: number
    trackId: string;
}

const JobStatus = (props: IProps) => {

    const candidateTrackId = getValueBrowserStorage(Candidate_Track_Id)!;
    const candidateId = getValueBrowserStorage(Candidate_Id)!;

    const { enqueueSnackbar } = useSnackbar();

    const [trackDetails, setTrackDetails] = useState<TrackType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (candidateTrackId && candidateId) {
            setLoading(true);
            evaluationPlatformService.getDetailsForCandidatebyCandidateTrackId({
                candidateTrackId: candidateTrackId,
                candidateId,
                allTracks: true
            })
                .then((res) => {
                    if (res?.output?.candidateTrack) {
                        const lastTrackWorkedOn = res?.output?.candidate?.lastCandidateSavedSetting?.lastCandidateTrackIDWorkedOn;
                        const trackDetails: TrackType[] = [];
                        for (let canTrack of res?.output?.candidateTrack) {
                            const jobMatchingScore = canTrack.jobMatchingScore?.matching_score;
                            const jobEntityResponse = canTrack.jobEntityResponse;
                            let numberOfQuestions = 0, noOfQuestionAttempted = 0, sendForReview = 0, feedBackRecevied = 0;
                            for (const cap of (canTrack?.capabilities ?? [])) {
                                const counts = cap?.capabilityStatus;
                                noOfQuestionAttempted += (counts?.noOfQuestionAttempted ?? 0);
                                numberOfQuestions += (counts?.numberOfQuestions ?? 0);
                            }
                            trackDetails.push({
                                job: {
                                    ...jobEntityResponse,
                                    jobMatchingScore,
                                    capabilitiesCounts: {
                                        numberOfQuestions,
                                        noOfQuestionAttempted,
                                        sendForReview,
                                        feedBackRecevied
                                    }
                                },
                                aiScore: canTrack?.aiEvaluationScore,
                                trackName: canTrack?.title,
                                trackId: canTrack?. candidateTrackId
                            });
                        }
                        const index = trackDetails.findIndex((track) => track.trackId === lastTrackWorkedOn);
                        if(index > 0) {     // if 0 then its already at first index
                            trackDetails.unshift(trackDetails.splice(index, 1)[0]);
                        }
                        setTrackDetails(trackDetails);
                    }
                    setLoading(false);
                })
                .catch((e) => {
                    enqueueSnackbar('Error occurred while fetching jobs details.', { variant: 'error', autoHideDuration: 2500 });
                    setLoading(false);
                })
        }
    }, [])

    return (
        <StyledWrapper>
            <OverlayLoader loading={loading} />
            {
                trackDetails.map((trackDetail, idx) => {
                    return (
                        <React.Fragment key={trackDetail.trackName + idx}>
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h3>{trackDetail.trackName}</h3>
                                <ScoreContainer>
                                    <span>Pitch Score: <strong>{round(trackDetail.aiScore ?? 0)}/10</strong></span>
                                </ScoreContainer>
                            </div>
                            <div className="applied-job-container mb-4">
                                <AppliedJobCard
                                    jobDetail={trackDetail.job}
                                    handleClick={() => props.handleJobSelect(trackDetail.job)}
                                    capabilitiesCounts={trackDetail.job.capabilitiesCounts}
                                />
                            </div>
                        </React.Fragment>
                    )
                })
            }
            {/* <div style={{ padding: '10px' }}>
                <div className="complete-profile-section d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <div className="completion-percent mr-3">
                            <h1>60%</h1>
                            <h5>of your Profile is Complete</h5>
                        </div>
                        <div className="completion-percent-line my-auto">
                            <LinearProgress variant="determinate" value={44} className="mb-3" />
                            <h5 className="mb-2">Boost your Chances of Landing the Right Job</h5>
                            <span>
                                A detailed profile helps match you with opportunities that align with your skills and goals,
                                making you stand out to potential employers.
                            </span>
                        </div>
                    </div>
                    <Button variant="contained" color="primary">
                        Complete your Profile
                    </Button>
                </div>
            </div> */}
        </StyledWrapper>
    )
}

export default JobStatus;