import { AwsUploader } from 'components/Uploader/AwsUploader';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { Expert_Login, QUESTION_STATUS_TEXT } from 'utilities/constants';
import { Answer, IQuestion } from '../types';
import { AttachmentPickerObject, AttachmentsList } from 'components/Uploader/AttachmentsList';
import { evaluationPlatformService } from 'services';
import { getFolderPathAfterDomainName } from 'utilities/commonUtils';
import { Spinner } from 'react-bootstrap';

interface IUploadPane {
    currentAnswer?: Answer;
    currentQuestion: IQuestion;
    answerVersion: number;
    isReadOnly?: boolean;
    candidateId?: string;
}

const UploadPane = ({ currentAnswer, currentQuestion, answerVersion, isReadOnly, candidateId }: IUploadPane) => {
    const isExpertLogin = getValueBrowserStorage(Expert_Login) !== null;
    const [s3Objs, setS3Objs] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [attachments, setAttachments] = useState<AttachmentPickerObject[]>([]);
    const trackId = getValueBrowserStorage('candidateTrackId');
    const selectedCapabilityId = useSelector((state: any) => state.evaluationPlatform.selectedCapabilityId);
    const directory = (candidateId || (getValueBrowserStorage('candidateId') as string) )+ "/" + selectedCapabilityId + "/" + currentQuestion.question._id + "/attachment/v" + (currentQuestion.answers.length - answerVersion);

    const isDisabled =
        isExpertLogin || isReadOnly ||
        (currentAnswer &&
            ((currentQuestion.question.status !== QUESTION_STATUS_TEXT.UNANSWERED ||
                answerVersion > 0) &&
                ((currentQuestion.question.status !== QUESTION_STATUS_TEXT.UNANSWERED &&
                    currentQuestion.question.status !== QUESTION_STATUS_TEXT.ANSWERED) ||
                    answerVersion > 0)));

    const handleImageUpload = () => {
        getQuestionAttachments();
    }

    const getQuestionAttachments = () => {
        const files: any = [];
        const objs: any = [];
        setLoading(true);
        evaluationPlatformService.getS3FolderFiles({
            candidateTrackId: trackId!, path: directory!
        }).then(res => {
            res.output.files.map((url: string, index: number) => {
                if (!url.includes("~audio~") && !url.includes("~video~")) {
                    files.push({
                        label: '' + files.length,
                        id: url + index,
                        background: url,
                        onClick: () => handleImageUpload(),
                    });
                    objs.push(url);
                }
            })
            setAttachments([...files]);
            setS3Objs(objs);
        }).catch(e => {
            console.log('failed loading attachments from aws', e);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleAttachmentRemove = (index: number) => {
        if (trackId) {
            evaluationPlatformService.deleteS3File({
                candidateTrackId: trackId,
                path: getFolderPathAfterDomainName(s3Objs[index])
            }).then(res => console.log("File removed"))
                .catch(err => console.log('Unable to remove object', err))
                .finally(() => getQuestionAttachments())
        }
    }

    useEffect(() => {
        if (currentAnswer) {
            getQuestionAttachments();
        }
    }, [currentAnswer])

    return <div>
        <AttachmentsList headingText={""} items={attachments} onRemove={!isDisabled ? (index: number) => handleAttachmentRemove(index) : undefined} isReadOnly={isDisabled} />
        {loading && <div className='d-flex'>
            <Spinner className='mr-2' style={{ height: "1rem", width: "1rem" }} animation="border" />
            Loading...
        </div>}
        {!isDisabled && <AwsUploader
            onUpload={handleImageUpload}
            directory={directory!}
            candidateTrackId={trackId!}
        />}
    </div>
}

export default UploadPane;