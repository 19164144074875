import React from 'react';
import CompactTab from '../Components/CompactTabs';
import CustomTabs from '../Components/CustomTabs';
import styled from 'styled-components';

interface Props {
  tabValue: number;
  hintsAvailable: boolean;
  sampleSolutionsAvailable: boolean;
  sharedAnswersAvailable: boolean;
  evaluationAvailable: boolean;
  children?: React.ReactNode;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const CustomTabWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const CustomQuestionTabs: React.FC<Props> = ({
  tabValue,
  hintsAvailable,
  sampleSolutionsAvailable,
  sharedAnswersAvailable,
  evaluationAvailable,
  handleTabChange,
  children,
}) => {
  return (
    <CustomTabWrapper>
      <CustomTabs tabValue={tabValue} handleTabChange={handleTabChange}>
        <CompactTab value={5} label="About Capability" isSelected={tabValue === 6} />
        <CompactTab value={0} label="Question" isSelected={tabValue === 0} />
        {hintsAvailable && <CompactTab value={1} label="Hints" isSelected={tabValue === 1} />}
        {sampleSolutionsAvailable && <CompactTab value={2} label="Sample Solutions" isSelected={tabValue === 2} />}
        {sharedAnswersAvailable && <CompactTab value={3} label="Shared Answers" isSelected={tabValue === 3} />}
        {evaluationAvailable && <CompactTab value={4} label="Evaluations" isSelected={tabValue === 4} />}
      </CustomTabs>
      {children}
    </CustomTabWrapper>
  );
};

export default CustomQuestionTabs;
