import { MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { Play } from '@styled-icons/ionicons-solid';
import {
    Csharp,
    Css3,
    Go,
    Html5,
    Java,
    Javascript,
    Mysql,
    Python,
    Ruby,
    Typescript
} from "@styled-icons/simple-icons";
import { Hippo } from "@styled-icons/fa-solid/Hippo";
import { IconContainer } from 'components/Common/IconContainer';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import AceEditor from 'react-ace';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { evaluationPlatformService } from 'services';
import { RootState, useAppDispatch } from 'store';
import { setCodingOutput, setLanguage, setTheme } from 'store/splitView';
import styled from 'styled-components';
import { DEFAULT_TOKEN } from 'utilities/constants';
import { ICandidateTrackData } from '../types';

const StyledSelect = styled(Select)`
    font-size: 10px !important;
    color: white !important;
    &::before {
        border-bottom: none !important;
    }
    svg {
        color: white;
    }
`;

const StyledTopBar = styled.div`
    height: 30px;
`;

const supportedThemes = [
    { theme: 'Monokai', code: 'monokai' },
    { theme: 'GitHub', code: 'github' },
    { theme: 'Tomorrow Night', code: 'tomorrow_night' },
    { theme: 'Xcode', code: 'xcode' },
];

export const CompilerEditorLanguageMap: any = {
    javascript: "nodejs",
    java: "java",
    python: "python3",
    go: "go",
    csharp: "csharp",
    typescript: "typescript",
    mysql: "mysql",
    css: "css",
    html: "html",
    ruby: "ruby",
    cobol: "cobol",
}

const supportedLanguages = [
    { language: 'NodeJS', code: 'javascript' },
    { language: 'Java', code: 'java' },
    { language: 'Python', code: 'python' },
    { language: 'Go', code: 'go' },
    { language: 'Ruby', code: 'ruby' },
    { language: 'C#', code: 'csharp' },
    { language: 'TypeScript', code: 'typescript' },
    { language: 'MySQL', code: 'mysql' },
    { language: 'CSS', code: 'css' },
    { language: 'COBOL', code: 'cobol' },
];

const languageIcons = {
    javascript: <Javascript className="mr-2" size="1em" />,
    java: <Java className="mr-2" size="1em" />,
    python: <Python className="mr-2" size="1em" />,
    go: <Go className="mr-2" size="1em" />,
    csharp: <Csharp className="mr-2" size="1em" />,
    typescript: <Typescript className="mr-2" size="1em" />,
    mysql: <Mysql className="mr-2" size="1em" />,
    css: <Css3 className="mr-2" size="1em" />,
    html: <Html5 className="mr-2" size="1em" />,
    ruby: <Ruby className="mr-2" size="1em" />,
    cobol: <Hippo className="mr-2" size="1em" />,
};

const TerminalComponent = forwardRef((_, ref) => {
    const dispatch = useAppDispatch();
    const candidateTrack: ICandidateTrackData = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack[0])
    const { language, theme, code } = useSelector((state: RootState) => state.splitView.coding);
    const [executing, setExecuting] = useState<boolean>(false);
    const [output, setOutput] = useState("");
    const [commandLineArgs, setCommandLineArgs] = useState("");
    const [stdinInput, setStdinInput] = useState("");
    const [activeTab, setActiveTab] = useState<number>(0);
    const [terminalHeight, setTerminalHeight] = useState<string>("100px");
    const [localTheme, setLocalTheme] = useState(supportedThemes.find(t => t.code === theme));
    const [localLanguage, setLocalLanguage] = useState(supportedLanguages.find(l => l.code === language));

    const handleThemeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedTheme = event.target.value as string;
        const selectedOption = supportedThemes.find(theme => theme.code === selectedTheme);

        if (selectedOption) {
            setLocalTheme(selectedOption);
            dispatch(setTheme(selectedOption.code));
        }
    };

    const calculateTerminalHeight = () => {
        const codeEditorElement = document.getElementById("terminal-container");
        if (codeEditorElement && codeEditorElement.parentElement) {
            const parentElement = codeEditorElement.parentElement;
            const height = parentElement.getBoundingClientRect().height;
            setTerminalHeight(`${height - 30}px`);
        }
    }

    const handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedLanguage = event.target.value as string;
        const selectedOption = supportedLanguages.find(lang => lang.code === selectedLanguage);

        if (selectedOption) {
            setLocalLanguage(selectedOption);
            dispatch(setLanguage(selectedOption.code));
        }
    };

    // Function to handle tab selection
    const handleTabChange = (_: any, tabValue: number) => {
        setActiveTab(tabValue);
    };

    const handleCommandLineArgsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCommandLineArgs(event.target.value);
    };

    const handleStdinInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setStdinInput(event.target.value);
    };

    const executeCode = async () => {
        setOutput("Compiling ...\n");
        try {
            if (code) {
                const payload = {
                    script: code,
                    //hasInputFiles: false,
                    //args: commandLineArgs,
                    //libs: [],
                    //projectKey: 1001,
                    language: CompilerEditorLanguageMap[language],
                    //stdin: stdinInput,
                    //versionIndex: 4,
                    candidateTrackId: candidateTrack.candidateTrackId,
                    token: DEFAULT_TOKEN
                };
                setExecuting(true);
                const response = await evaluationPlatformService.submitCodeToJDoodle(payload)
                setExecuting(false);

                if (response.output) {
                    handleOutput(response.output.output, response.output.cpuTime, response.output.memory);
                    dispatch(setCodingOutput(response.output.output));
                } else {
                    setOutput("No output received.");
                }
            }
        } catch (error) {
            setOutput("Error executing code");
            console.error("Error executing code:", error);
            setExecuting(false);
        }
    };

    const handleOutput = (output: string, executiontime?: string, memory?: string) => {
        let formattedOutput = '';

        if (executiontime) {
            formattedOutput += `Execution Time: ${executiontime}\n`;
        }

        if (memory) {
            formattedOutput += `Memory Usage: ${memory}\n\n`;
        }

        formattedOutput += output;

        setOutput(formattedOutput);
    };


    useImperativeHandle(ref, () => ({
        onDrag: () => {
            calculateTerminalHeight();
        },
    }));

    useEffect(() => {
        const selectedLang = supportedLanguages.find(l => l.code === language);
        setLocalLanguage(selectedLang);
    }, [language]);

    useEffect(() => {
        calculateTerminalHeight();
    }, [])

    return (
        <div>
            <StyledTopBar className='d-flex justify-content-between bg-black align-items-center'>
                <div className='pl-2 text-xs text-light'>Terminal</div>
                <div className="d-flex align-items-center justify-content-end">
                    <StyledSelect
                        className='mr-2'
                        autoWidth={true}
                        onChange={handleThemeChange}
                        defaultValue={localTheme?.code}
                    >
                        {supportedThemes.map(theme => (
                            <MenuItem key={theme.code} value={theme.code}>
                                {theme.theme}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                    <StyledSelect
                        onChange={handleLanguageChange}
                        defaultValue={localLanguage?.code}
                    >
                        <MenuItem value={undefined}>{"Select a language"}</MenuItem>
                        {supportedLanguages.map(lang => (
                            <MenuItem key={lang.code} value={lang.code}>
                                {(languageIcons as any)[lang.code]}{lang.language}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                    <div onClick={executeCode} className='text-xs text-primary d-flex align-items-center cursor-pointer'>
                        <span>Run</span>
                        {executing ? <Spinner className='mr-2 text-primary hw-12' animation="border" /> : <IconContainer height='12px' tooltip='Run Code' icon={Play} />}
                    </div>
                </div>
            </StyledTopBar>
            {activeTab === 0 && (
                <div className="d-flex flex-column" id='terminal-editor'>
                    <AceEditor
                        mode="text"
                        theme="terminal"
                        value={output}
                        width="100%"
                        height={terminalHeight}
                        fontSize={10}
                        showGutter={false}
                    />
                </div>

            )}
            {activeTab === 1 && (
                <div className="d-flex flex-column align-items-start mx-2 w-100">
                    <div className='text-left'>
                        <label className='small fw-bold' htmlFor='command-line-args'>CommandLine Arguments</label>
                        <input
                            type="text"
                            id="command-line-args"
                            value={commandLineArgs}
                            onChange={handleCommandLineArgsChange}
                            className="form-control mr-2"
                        />
                    </div>
                    <div className='text-left'>
                        <label className='small fw-bold' htmlFor='stdin-input'>Stdin Input</label>
                        <textarea
                            rows={2}
                            id="stdin-input"
                            onChange={handleStdinInputChange}
                            value={stdinInput}
                            className="form-control"
                        ></textarea>
                    </div>
                </div>
            )}
        </div>
    );
});

export default TerminalComponent;
