import { PlanProducts } from 'containers/PlanAndPayment/PlanProducts';
import React, { useState } from 'react';

export const PaymentPage = () => {

  return (
    <>
      <PlanProducts />
    </>
  );
};