import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store';


const ClassesReport = () => {
    const apiReportUrl = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack?.[0].reportUrl);
    const reportUrl = apiReportUrl && new URL(apiReportUrl);
    const urlSuffix = "?backgroundColor=yellow&viewControls=on";
    if (reportUrl) {
        reportUrl.pathname = `embed${reportUrl.pathname}`;
    };

    return (
        <div>
            {
                reportUrl ? <iframe
                    id="Classes"
                    title="Classes"
                    src={reportUrl.toString() + urlSuffix}
                    frameBorder="0"
                    style={{
                        background: "transparent",
                        border: "1px solid #ccc",
                        height: "80vh",
                        width: "100%"
                    }}>
                </iframe> : <div className="report-message">Talk to your class instructors for your feedback report.</div>
            }
        </div>
    )
}

export default ClassesReport
