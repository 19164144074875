import React, { useEffect, useState, createContext, useContext, useRef, MutableRefObject } from "react";
import { Toast } from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import { getEnrollmentType } from "utilities";
import { TrackEnrollType } from "utilities/constants";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledToast = styled(Toast)`
    position: fixed;
    z-index: 10000;
    bottom: 0;
    right: 0;
    color: white;
    background-color: #cc6666;
`;


interface NoCopyPasteState {
    showToaster: boolean;
}

interface ComponentRef {
    current: {
        updateVal: (value: string) => void;
        getCurrentVal: () => string;
    };
}

interface ActiveElementContextProps {
    activeElement: HTMLElement | null;
    setActiveElement: (element: HTMLElement | null) => void;
}

const ActiveElementContext = createContext<ActiveElementContextProps | undefined>(
    undefined
);

const ActiveElementProvider: React.FC = ({ children }) => {
    const [activeElement, setActiveElement] = useState<HTMLElement | null>(null);

    useEffect(() => {
        console.log(activeElement);
    }, [activeElement]);

    return (
        <ActiveElementContext.Provider value={{ activeElement, setActiveElement }}>
            {children}
        </ActiveElementContext.Provider>
    );
};

const useActiveElementContext = () => {
    const context = useContext(ActiveElementContext);
    if (!context) {
        throw new Error(
            "useActiveElementContext must be used within an ActiveElementProvider"
        );
    }
    return context;
};

const withSecurityFeatures = <
    P extends any
>(
    WrappedComponent: React.ComponentType<P>
) => {
    return function NoCopyPasteComponent(props: Omit<P, 'useActiveElementContext'>) {
        const allowCopyPaste = getEnrollmentType() === TrackEnrollType.FORPLACEMENT;
        const componentRef = useRef<ComponentRef | null>(null);
        const { activeElement } = useActiveElementContext();

        const [showToaster, setShowToaster] = useState(false);

        const handleCopy = (e: React.ClipboardEvent<HTMLDivElement>) => {
            if (!allowCopyPaste) return;
            e.preventDefault();
            setShowToaster(true);
        };

        function processAceEditor(textareaNode: HTMLTextAreaElement | null, componentRef: MutableRefObject<ComponentRef | null>) {
            if (!textareaNode || !componentRef || !componentRef.current) return;

            const parent = textareaNode.parentElement;
            if (!parent) return;

            const siblingNodes = parent.getElementsByClassName('ace_text-layer')[0]?.children;
            if (!siblingNodes) return;

            const currentTransform = textareaNode.style.transform;
            const currentStyleHeight = currentTransform ? currentTransform.split(',')[1]?.trim().replace(')', '') : '';

            const values: string[] = [];
            for (let i = 0; i < siblingNodes.length; i++) {
                const node = siblingNodes[i] as HTMLElement;
                const innerValue = node.innerText;
                const modifiedValue = node.style.height === currentStyleHeight ? innerValue.replace(textareaNode.value, '') : innerValue;
                values.push(modifiedValue);
            }

            (componentRef.current as any).updateVal(values.join('\n'));
            console.log((componentRef.current as any).getCurrentVal(), values.join('\n'));
        }


        const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
            if (!allowCopyPaste) return;
            e.preventDefault();
            handleCopy(e);

            const clipboardData = e.clipboardData;
            if (clipboardData && activeElement) {
                if (activeElement.tagName === "INPUT") {
                    (activeElement as HTMLInputElement).value = (activeElement as HTMLInputElement).value;
                } else if (activeElement.isContentEditable) {
                    activeElement.innerHTML = activeElement.innerHTML;
                } else if (clipboardData && activeElement && activeElement.tagName === "DIV") {
                    const textareaNode: HTMLTextAreaElement | undefined = Array.from(activeElement.childNodes).find(
                        (node) => node instanceof HTMLTextAreaElement
                    ) as HTMLTextAreaElement;
                    if (textareaNode) {
                        processAceEditor(textareaNode, componentRef);
                    }
                }
            }
        };

        return (
            <>
                <StyledToast onClose={() => setShowToaster(false)} show={showToaster} delay={5000} autohide>
                    <StyledToast.Body>For a secure learning and professional environment, copying and pasting are disabled.</StyledToast.Body>
                </StyledToast>
                <div onCopy={handleCopy} onPaste={handlePaste}>
                    <WrappedComponent ref={componentRef} {...props as any} />
                </div>
            </>
        );
    };
};

export { ActiveElementProvider, useActiveElementContext, withSecurityFeatures };
