import { Button, Switch } from "@material-ui/core";
import React from "react";

const AudioPermission = (props: {
    useMicrophone: boolean;
    setUseMicrophoneForPitch: (useMicrophone: boolean) => void;
}) => {
    return (
        <div>
            <h2 className="mb-4">
                You will now have the opportunity to  <br />
                make your pitch for 2 similar Jobs.
            </h2>
            <h5>
                Answer a few Questions...
            </h5>
            <div className="mb-4">
                <span className="text-muted">
                    To speed up the process, we recommend using the mic for your <br />
                </span>
                <span className="text-muted">
                    responses-it's quicker and lets your personality shine through.
                </span>
            </div>
            <div className="mb-4 d-flex justify-content-center align-items-center">
                <h5 className="mr-3">
                    Allow this application to use my microphone
                </h5>
                <Switch onChange={(e) => props.setUseMicrophoneForPitch(e.target.checked)} checked={props.useMicrophone} />
            </div>
            {/* <Button
                variant="contained"
                color="primary"
                className="mr-4"
                type={"button"}>
                Make a Pitch
            </Button> */}
        </div>
    )
}

export default AudioPermission;