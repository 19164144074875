import Button from '@material-ui/core/Button';
import React from 'react';


const ConfirmButton = (props: any) => {

    const handleClick = (val: string) => {
        props.getGPTResponce(". " + val);
    }

    return (
        <div className='d-flex'>
            {props.prompts?.map((val: string, idx: number) => (
                <Button
                    key={val + idx}
                    variant="contained"
                    color="secondary"
                    className='m-1'
                    onClick={() => handleClick(val)}>
                    {val}
                </Button>
            ))}
        </div>

    )
}

export default ConfirmButton;