import { Robot as Bot } from '@styled-icons/fa-solid/Robot';
import CapabilityVideos from 'components/Question/CapabilityVideos/index';
import { useLoader } from 'context/loaderDots';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Tooltip from 'react-tooltip-lite';
import { getAvailableSharedQuestion, setLandBotVisibility } from 'store/evaluationPlatform';
import styled from 'styled-components';
import { QuestionField, StatusButtonBar, TracksDescription } from '../../components';
import { isPlacementTrack } from 'utilities/helperFunctions';

const StyledQuestionContainer = styled.div`
  display: flex;
  background: white;
  width: 100%;
  transition: 1s;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 100px;
  align-items: center;
  .botIcon{
    width: 30px;
    display: inline-block;
    margin-top: -9px;
    cursor: pointer;
  }
  .botIcon path {
    fill: #5b94e3;
  }
  .inline-block {
    display: inline-block;
  }
  .capability__title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .question__status__bar {
    margin-top: 28px;
    padding-left: 48px;
    width: -webkit-fill-available;
  }
  .question__main {
    width: -webkit-fill-available;
  }
`;

interface IProps {
  candidateTrackId: string;
  capability: any;
  setCapability: any;
  selectedCapabilityId: string;
  setAnswer: any;
  saveResponseForQuestionOfCandidateTrack: any;
  Modal: any;
  setQuestionId: any;
  currentQuestionId: string;
  submitResponseToExpert: any;
  chatWithExpert: any;
  setFeedback: any;
  handleEdit: any;
  setQuestionFeedbackViewed: Function;
  candidateViewedExpertFeedback?: Function;
  candidate: any;
  setCurrentAnsVersionId: Function,
  saveCandidateLastActivity: Function,
  goToPaymentPage: Function,
  triggerFeedback: any;
  setTriggerFeedback: any;
  trackPlan?: string;
}

const CapabilityTitle = ({ capabilityName, currentCapability }: { capabilityName: string, currentCapability: any }) => {
  const dispatch = useDispatch();

  const botSelectedForCapability = () => {
    dispatch(setLandBotVisibility(true));
  }

  return (
    <div className="capability__title">
      {capabilityName}
      {(currentCapability?.boat === true) && <Tooltip content={"Chat with Bot"} className="inline-block">
        <Bot className='botIcon ml-2' onClick={botSelectedForCapability} />
      </Tooltip>}
    </div>
  )
};

const _QuestionContainer: React.FC<IProps> = (props) => {
  let capabilityId = props.selectedCapabilityId || '';
  let currentCapability = props.capability?.filter((item: any) => item.capabilityId === capabilityId)[0];
  const loader = useLoader();
  const description = currentCapability?.description;
  const capabilityName = currentCapability?.capabilityName;
  const capabilitiyStatus = currentCapability?.capabilityStatus;
  let capScrollPosition = currentCapability?.scrollPosition?.scrollTop || 0;
  const [questions, setQuestions] = useState(currentCapability?.questions);
  const [questionFilter, setQuestionFilter] = useState('All');
  const [minimizeRecording, setMinimizeRecording] = useState<string | undefined>('-1');
  const [openRecorder, setOpenRecorder] = useState<string>('-1');
  const [capabilitiesQuestion, setCapabilitiesQuestion] = useState();
  const dispatch = useDispatch();
  const enrollmentType = useSelector((state: any) => state.evaluationPlatform.currentTrack?.candidateTrack[0]?.trackEnrollType);


  useEffect(() => {
    setQuestionFilter('All');
    setQuestions(currentCapability?.questions);
    // eslint-disable-next-line
  }, [props.selectedCapabilityId, currentCapability]);

  useEffect(() => document.documentElement.scrollTo(0, capScrollPosition), [props.selectedCapabilityId])

  const getQuestionIdx = (question: any) => {
    return currentCapability?.questions.map((question: any) => question.question._id).indexOf(question.question._id);
  }

  const getSharedQuestions = (questions: any) => {
    const payload = {
      questionIds: questions.map((q: any) => q.question._id),
      candidateTrackId: props.candidateTrackId,
    };
    dispatch(getAvailableSharedQuestion(payload));
  }

  useEffect(() => {
    questions && getSharedQuestions(questions);
  }, [questions])

  useEffect(() => {
    loader.showLoader();
    setTimeout(() => filterCapabilities(loader.hideLoader), 0);
  }, [questions, questionFilter, minimizeRecording, openRecorder])

  const handleMiniMizeRecording = (idx: string) => {
    if (typeof (idx) === 'string') {
      setMinimizeRecording(idx)
      document.body.style.overflow = "auto";
    }
  }

  const handleOpenRecording = (idx: string) => {
    if (typeof (idx) === 'string') {
      setOpenRecorder(idx);
    }
  }

  const getIndex = (indexValue: number) => {
    let id = 0
    questions
      .filter((item: any) => {
        if (questionFilter !== 'All') return item.question?.status === questionFilter;
        return true;
      }).forEach((x: any, index: number) => {
        if (x?.question?.questionType !== "CONTENT" && indexValue >= index) {
          id += 1
        }
      })
    return id
  }

  const filterCapabilities = (cb: Function) => {
    let filteredResult = questions;
    if (questionFilter !== 'All') {
      filteredResult = questions && questions.filter((item: any) => item.question?.status === questionFilter)
    }
    const result =
      (
        filteredResult && filteredResult.map((question: any, idx: number) => {
          return (
            <QuestionField
              idx={getQuestionIdx(question)}
              questionIndex={() => getIndex(idx)}
              key={question?._id ? question?._id : idx}
              candidateTrackId={props.candidateTrackId}
              capabilityId={capabilityId}
              questionSet={question}
              setAnswer={props.setAnswer}
              saveResponseForQuestionOfCandidateTrack={props.saveResponseForQuestionOfCandidateTrack}
              Modal={props.Modal}
              setQuestionId={props.setQuestionId}
              currentQuestionId={props.currentQuestionId}
              submitResponseToExpert={props.submitResponseToExpert}
              chatWithExpert={props.chatWithExpert}
              setFeedback={props.setFeedback}
              handleEdit={props.handleEdit}
              setQuestionFeedbackViewed={props.setQuestionFeedbackViewed}
              candidateViewedExpertFeedback={props.candidateViewedExpertFeedback}
              candidate={props.candidate}
              setCurrentAnsVersionId={props.setCurrentAnsVersionId}
              saveCandidateLastActivity={props.saveCandidateLastActivity}
              goToPaymentPage={props.goToPaymentPage}
              triggerFeedback={props.triggerFeedback}
              setTriggerFeedback={props.setTriggerFeedback}
              handleAnswerShare={() => getSharedQuestions(questions)}
              setMinimizeRecording={(ind?: string) => handleMiniMizeRecording(ind ? ind : question?.question._id + idx)}
              minimizedModelIndex={minimizeRecording}
              currModelIndex={question?.question._id + idx}
              openRecorder={openRecorder}
              setOpenRecorder={(ind: string) => handleOpenRecording(ind)}
            />
          );
        }))
    setCapabilitiesQuestion(result);
    cb();
  }

  return (
    <StyledQuestionContainer {...props}>
      <CapabilityTitle capabilityName={capabilityName} currentCapability={currentCapability} />
      <div id={'searchComPortalDiv'}></div>
      {enrollmentType !== "FOR_PLACEMENT" && <TracksDescription description={description} />}
      {!isPlacementTrack() && <CapabilityVideos />}
      <div className="question__status__bar">
        <StatusButtonBar
          capabilitiyStatus={capabilitiyStatus}
          setQuestionFilter={setQuestionFilter}
          questionFilter={questionFilter}
        />
      </div>
      <div className="question__main">
        {capabilitiesQuestion}
      </div>
    </StyledQuestionContainer>
  );
};

const mapStateToProps = (state: any) => ({
  trackPlan: state.payment?.trackPlan,
});

export const QuestionContainer = connect(mapStateToProps)(_QuestionContainer);
