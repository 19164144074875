import { SuccessPayment } from 'containers/PlanAndPayment/PaymentAcknowledgement/successPayment';
import { useLoader } from 'context/loaderDots';
import { useMessagePopup } from 'context/messagePopup';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getValueBrowserStorage, setValueBrowserStorage } from 'services/browserStorageService';
import {
  createCandidateTrackForCandidate,
  getDetailsForCandidatebyCandidateTrackId,
  getTracksForCandidate,
  saveCandidateLastActivity,
} from 'store/evaluationPlatform';
import { setTrackInfoForPayment } from 'store/payment';
import { notEmpty } from 'utilities';
import { Candidate_Track_Id, DEFAULT_TOKEN, FLOW_TYPE, Flowtype, PlanType, Plan_Session_Id, TrackEnrollType } from 'utilities/constants';
import { TracksPanel } from '../../containers';
import { RootState } from 'store';

const TrackPage = (props: any) => {
  const profileCompleted = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidate?.profileCompleted);
  const loader = useLoader();
  const [enrollAfterPaymentTrackId, setEnrollAfterPaymentTrackId] = useState('');
  const { completePaymentAcknowledgement } = SuccessPayment();
  const message = useMessagePopup();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const checkAndEnrolFreeTrack = useCallback(() => {
    loader.showLoader();
    const candidateId = getValueBrowserStorage('candidateId');
    const trackId = getValueBrowserStorage('trackId');
    const request = { candidateId, trackId, token: DEFAULT_TOKEN };
    const trackNotTaken = props.tracksData?.trackNotTaken || [];
    const isMustBuy = trackNotTaken.find((trackObj: any) => trackObj.trackId === trackId && trackObj.trackEnrollType === TrackEnrollType.mustBuy);
    if (isMustBuy) {
      message.fail('Oh no! Payment Failed');
      loader.hideLoader();
    } else {
      props.createCandidateTrackForCandidate(request).then((createResponse: any) => {
        loader.hideLoader();
        props.getTracksForCandidate({
          token: DEFAULT_TOKEN,
          candidateId: candidateId,
          trackEnrollTypes: [TrackEnrollType.canEnroll, TrackEnrollType.mustBuy]
        });
      });
    };
  }, [loader, props]);

  const goToQuestionPage = () => {
    props.history.push('/question');
  }

  const handleAddOrGetDetailOfTrack = (addOrGetDetailFunction: Function, payload: any) => {
    loader.showLoader();
    addOrGetDetailFunction(payload)
      .then((res: any) => {
        if (res.payload?.apiStatus === 'SUCCESS') {
          dispatch(saveCandidateLastActivity({}));
          setValueBrowserStorage(Candidate_Track_Id, res.payload.output.candidateTrack[0].candidateTrackId);
          if (payload.trackId && res.payload.output.candidateTrack[0].trackEnrollType === TrackEnrollType.mustBuy) {
            dispatch(setTrackInfoForPayment({
              trackId: payload.trackId,
              trackPlan: PlanType.Evaluation,
              trackName: res.payload.output.candidateTrack[0].title,
              planState: "active"
            }));
          }
          goToQuestionPage();
          loader.hideLoader();
          message.close();
        } else {
          enqueueSnackbar(res.error?.message, {
            variant: 'error',
            autoHideDuration: 2500,
          });
          loader.hideLoader();
        }
      })
      .catch((err: any) => {
        enqueueSnackbar(err?.message, {
          variant: 'error',
          autoHideDuration: 2500,
        });
        loader.hideLoader();
      });
  };

  const completeOngoingPayment = useCallback(() => {
    completePaymentAcknowledgement(Plan_Session_Id).then((prodData: any) => {
      if (notEmpty(prodData)) {
        message.load('Payment Successful...');
        const trackId = prodData.responseJson.metadata.track;
        const flowType = getValueBrowserStorage(Flowtype);
        if (flowType === FLOW_TYPE.buy) {
          const trackNotTaken = props.tracksData?.trackNotTaken || [];
          const isTrackNotTaken = trackNotTaken.find((trackObj: any) => trackObj.trackId === trackId);
          if (isTrackNotTaken) {
            setEnrollAfterPaymentTrackId(trackId);
          } else {
            dispatch(setTrackInfoForPayment({
              trackPlan: prodData.responseJson.metadata.subscriptionProduct == "true"
                ? "SUBSCRIPTION" : prodData.responseJson.metadata.contractProduct == "true" ? "UNLIMITED" : "FREE"
            }));
            handleAddOrGetDetailOfTrack(props.getDetailsForCandidatebyCandidateTrackId, {
              token: DEFAULT_TOKEN,
              candidateTrackId: getValueBrowserStorage(Candidate_Track_Id),
            })
          }
        } else {
          setEnrollAfterPaymentTrackId(trackId);
        }
      }
    }).catch((error: any) => {
      if (error.type === FLOW_TYPE.buy) {
        checkAndEnrolFreeTrack();
      }
    })
  }, [completePaymentAcknowledgement, message, props.tracksData, dispatch, checkAndEnrolFreeTrack]);

  useEffect(() => {
    completeOngoingPayment();
    // if (notEmpty(props.tracksData) || props.completePayment) {
    // }
  }, [props.tracksData, completeOngoingPayment]);

  useEffect(() => {
    const savedCandidateId = getValueBrowserStorage('candidateId');
    if (savedCandidateId !== null && savedCandidateId !== 'undefined') {
      props.getTracksForCandidate({
        token: DEFAULT_TOKEN,
        candidateId: savedCandidateId,
        trackEnrollTypes: [TrackEnrollType.canEnroll, TrackEnrollType.mustBuy]
      });
    } else {
      props.getTracksForCandidate({
        token: DEFAULT_TOKEN,
        candidateId: props.candidate?._id,
        trackEnrollTypes: [TrackEnrollType.canEnroll, TrackEnrollType.mustBuy]
      });
    }
  }, []);

  return (
    <>
      <TracksPanel
        createCandidateTrackForCandidate={props.createCandidateTrackForCandidate}
        getDetailsForCandidatebyCandidateTrackId={props.getDetailsForCandidatebyCandidateTrackId}
        candidate={props.candidate}
        tracksData={props.tracksData}
        className="dashboard"
        history={props.history}
        disable={!!props?.history?.location?.state?.disable}
        enrollAfterPaymentTrackId={enrollAfterPaymentTrackId}
        handleAddOrGetDetailOfTrack={handleAddOrGetDetailOfTrack}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  candidate: state.evaluationPlatform.candidate,
  tracksData: state.evaluationPlatform.tracksData,
  loading: state.evaluationPlatform.loading,
});

const mapDispatchToProps = {
  getTracksForCandidate,
  createCandidateTrackForCandidate,
  getDetailsForCandidatebyCandidateTrackId,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackPage);
