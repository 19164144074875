import { MainHeader, Sidebar } from 'containers';
import { ClassesContainer } from 'containers/ClassesContainer';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styled from 'styled-components';

const StyledClassesPage = styled.div`
.content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    z-index: 100;
    position: fixed;
    width: 100%;
  }
}
`;

export const Classes: React.FC<any> = (props) => {
  return (
        <StyledClassesPage>
            <div className='content'>
                <ClassesContainer/>
            </div>
        </StyledClassesPage>
    )
}
