import { RoundButton } from 'components';
import { useLoader } from 'context/loaderDots';
import { useMessagePopup } from 'context/messagePopup';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { evaluationPlatformService } from 'services';
import { getValueBrowserStorage } from 'services/browserStorageService';
import styled from 'styled-components';
import { IVerifyEditing } from 'types';
import { DEFAULT_TOKEN, Expert_Login, QUESTION_STATUS_TEXT } from 'utilities/constants';
import {
  canSubmitQuestionForReview,
  getDateTimeInLocalFormat, getExpertName,
  notEmpty
} from 'utilities/helperFunctions';
import { ExpertSelectionModal } from './ExpertSelectionModal';
import { isPlacementTrack } from "utilities/helperFunctions";
import StatusText from 'components/Common/StatusText';

const StyledAnswerBottom = styled.div`
  display: flex;
  flex-direction: column;
  .answer-buttonarea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    button:focus{
      outline: none;
      border: black 2px solid;
    }
    margin-top: 32px;
    .save-button-inside {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .save-button-inside span {
      margin-right: 5px;
    }
  }
  .answer-statusarea {
    margin-top: 30px;
    font-family: Khula;
    font-style: normal;
    font-size: 16px;
    text-align: center;
    color: #5f5f5f;
  }
  .Bottom_submit_btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface IProps {
  id: string;
  candidateTrackId: string;
  capabilityId: string;
  questionId: string;
  answer: any;
  questionStatusText: string;
  setQuestionStatusText: any;
  submitResponseToExpert: any;
  question: any;
  setFeedback: any;
  handleEdit: any;
  answerStatus: string;
  triggerFeedback: any;
  setTriggerFeedback: any;
  fullQuestion: any;
  hasNeedToSave: any;
  expertModle: any;
  setExpertModel: any;
  submitFeedbackToExpert: Function;
  handleSelectExpert: Function;
  handleAiChatBtn: Function;
  answerVersion: number;
}

const QuestionButtons = (props: IProps) => {
  const [isShowChooseExpert, setShowChooseExpert] = useState(false);

  const expertLogin = (getValueBrowserStorage(Expert_Login) == null) ? false : true;

  useEffect(() => {
    if (notEmpty(props.triggerFeedback)
      && props.triggerFeedback.questionId === props.questionId
      && props.triggerFeedback.answerId === props.answer.answer._id) {
      props.submitFeedbackToExpert(props.triggerFeedback.expertId);
    }
  }, [props.triggerFeedback]);

  useEffect(() => {
    if (props.expertModle) {
      if (props?.questionStatusText.includes("Saved successfully")) {
        setShowChooseExpert(true);
      }
    }
  }, [props.expertModle, props?.questionStatusText])

  return (
    <div>
      <ExpertSelectionModal
        isShowChooseExpert={isShowChooseExpert}
        setShowChooseExpert={setShowChooseExpert}
        questionId={props.questionId}
        answerId={props.answer?.answer?._id}
        submitFeedbackToExpert={props.submitFeedbackToExpert}
        setExpertModel={props.setExpertModel}
      />
      <div className="answer-buttonarea">
        {
          (!expertLogin) ? (
            <RoundButton
              onMouseDown={(e: any) => e.preventDefault()}
              onClick={() => props.handleSelectExpert()}>
              {canSubmitQuestionForReview(props.question) ? (props.fullQuestion.questionType == 'CONTENT' ? 'Send your question' : 'Submit for feedback') : 'Submit'}
            </RoundButton>) : ''
        }
      </div>
    </div>
  );
};

const AiChatButton = (props: IProps) => {
  return (
    <div className='answer-buttonarea'>
      <div>
        <RoundButton onClick={() => props.handleAiChatBtn()} color={'#5b94e3'} className='ml-2'>
          Ask AI
        </RoundButton>
      </div>
    </div>
  )
}

const EditButton = (props: any) => {
  const loader = useLoader();
  const messagePopup = useMessagePopup();
  const handleEditing = () => {
    props.setTriggerFeedback({});
    loader.showLoader();
    const capabilityIds: string[] =
      props.answer?.answer && props.answer.answer?.capabilityIds ? [...props.answer.answer.capabilityIds] : [];
    if (capabilityIds.indexOf(props.capabilityId) < 0) capabilityIds.push(props.capabilityId);
    const payload: IVerifyEditing = {
      token: DEFAULT_TOKEN,
      candidateTrackId: props.candidateTrackId,
      capabilityId: props.capabilityId,
      questionId: props.questionId,
      questionAnswerId: props.answer?.answer._id,
    };
    evaluationPlatformService.verifyEditing(payload).then((res: any) => {
      if (res.output.enableAnswer) {
        props.handleEdit({
          ...payload,
          capabilityIds,
          capabilities: props.question?.capabilities,
          enableAnswer: res.output.enableAnswer,
        });
      } else {
        props.handleEdit({
          ...payload,
          capabilityIds,
          capabilities: props.question?.capabilities,
          expertId: res.output.expertId,
          enableAnswer: res.output.enableAnswer,
        });
        messagePopup.fail(res.apiMessage);
      }
    }).catch(err => messagePopup.fail('Something went wrong please try again.'))
      .finally(() => loader.hideLoader());
  };

  return (<>
    <div className="answer-buttonarea">
      <RoundButton onClick={handleEditing}>Edit</RoundButton>
    </div>
  </>);
};

export const AnswerBottom: React.FC<IProps> = (props) => {
  const expertName = canSubmitQuestionForReview(props.question) ? getExpertName(props.answer?.feedbacks[0]?.expertId) : '';
  const ansDate = getDateTimeInLocalFormat(props?.answer?.answer?.updateAt);
  const expertLogin = (getValueBrowserStorage(Expert_Login) == null) ? false : true;
  const feedbackDate = getDateTimeInLocalFormat(props?.answer?.feedbacks[0]?.feedbackAt);
  useEffect(() => {
    if (props.answerStatus === QUESTION_STATUS_TEXT.SUBMITTED_FOR_REVIEW) {
      props.setQuestionStatusText(`Submitted ${expertName ? `for Review to ${expertName} ` : ''} on ${ansDate} `);
    } else if (props.answerStatus === QUESTION_STATUS_TEXT.UNDER_REVIEW) {
      props.setQuestionStatusText(`Your response is under review by ${expertName}`);
    } else if (props.answerStatus === QUESTION_STATUS_TEXT.FEEDBACK_VIEWED) {
      props.setQuestionStatusText(`You responded on ${ansDate}, feedback received on ${feedbackDate}, provided by ${expertName}`);
    } else if (props.answerStatus === QUESTION_STATUS_TEXT.ANSWERED) {
      props.setQuestionStatusText(`Saved successfully at ${ansDate}`);
    }
    if (props.answerStatus === QUESTION_STATUS_TEXT.UNANSWERED) {
      props.setQuestionStatusText('');
    }
    // else if (props.answerStatus === QUESTION_STATUS_TEXT.ANSWERED) {
    // else if (props.answerStatus === QUESTION_STATUS_TEXT.ANSWERED) {
    //   props.setQuestionStatusText('Your answer is under review');
    // }
    // eslint-disable-next-line
  }, [props.answerStatus, props.answer]);

  return (
    <StyledAnswerBottom>
      <StatusText questionStatusText={props.questionStatusText} additionalText={
        (!expertName && props.answerStatus === QUESTION_STATUS_TEXT.SUBMITTED_FOR_REVIEW) ? '(No review will be provided)' : ''} />
      {/* {/* Submit feedback button temp visible */}
      <div className='Bottom_submit_btn'>
        {props.answerStatus === 'UNANSWERED' && <QuestionButtons {...props} />}
        {props.answerStatus === 'ANSWERED' && <QuestionButtons {...props} />}
        {/* {props.answerStatus === 'UNANSWERED' || (props.answerStatus === 'ANSWERED' && <QuestionButtons {...props} />)} */}
        {props.answerStatus === 'SEND_FOR_REVIEW' && (!expertLogin) && <EditButton {...props} />}
        {!isPlacementTrack() && props.answerVersion === 0 && <AiChatButton {...props} />}
      </div>
    </StyledAnswerBottom>
  );
};
