import { Tabs } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface Props {
  tabValue: number;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  children: React.ReactNode;
}

const CompactTabs = styled(Tabs)`
  && {
    display: flex;
    align-items: center;
    .MuiTabs-flexContainer {
      overflow: hidden;
      border-bottom: none;
      flex-wrap: wrap;
    }
    .MuiTabs-indicator {
      display: none;
    }
  }
`;

const CustomTabs: React.FC<Props> = ({
  tabValue,
  handleTabChange,
  children }) => {
  return (
    <CompactTabs value={tabValue} onChange={handleTabChange} variant="scrollable">
      {children}
    </CompactTabs>
  );
};

export default CustomTabs;
