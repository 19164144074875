import { Avatar, Badge } from "@material-ui/core";
import { WhiteInterviewHelpLogoIcon } from "assets";
import { Logo } from "components";
import React from "react";
import styled from "styled-components";
import { Bell } from "@styled-icons/bootstrap/Bell"
import { OverlayTrigger, Popover } from "react-bootstrap";
import { authService } from "services";
import { useDispatch } from "react-redux";
import { getLogoutAction } from "store/logout";

const StyledWrapper = styled.div`
    background: #325cd7;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 1rem;
    height: 72px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .icons-container {
        display: flex;
        gap: 1rem;
        cursor: pointer;
    }
`

const OnboardHeader = (props: {
    profileImgUrl: string;
    name: string;
    email: string;
}) => {

    const dispatch = useDispatch();

    const logOut = () => {
        authService.removeAuthFromStorage();
        dispatch(getLogoutAction());
    }

    return (
        <StyledWrapper>
            <Logo
                className="ml-2"
                logoImage={WhiteInterviewHelpLogoIcon}
                imageSize="35px"
                logoText={"InterviewHelp Placement Partner"}
                fontWeight="bold"
                textSize="20px"
                color={'white'}
            />
            <OverlayTrigger
                placement="bottom"
                trigger="click"
                rootClose
                overlay={(
                    <Popover id="onboard-header-action" style={{minWidth: 'max-content'}}>
                        <Popover.Title className="py-3 d-flex justify-content-start align-items-center">
                            <Avatar alt="profile-logo" src={props.profileImgUrl} className="mr-2" />
                            <div>
                                <b className="d-block">{props.name}</b>
                                <span className="d-block">{props.email}</span>
                            </div>
                        </Popover.Title>
                        <Popover.Content className="py-2 text-center">
                            <div className="btn btn-link p-0" onClick={logOut}>
                                Sign out
                            </div>
                        </Popover.Content>
                    </Popover>
                )}>
                <div className="icons-container">
                    <Badge color="secondary" badgeContent={2} >
                        <Bell width={'30px'} color="#ffffff" />
                    </Badge>
                    <Avatar alt="profile-logo" src={props.profileImgUrl} />
                </div>
            </OverlayTrigger>

        </StyledWrapper>
    )
}

export default OnboardHeader;