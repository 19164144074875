import { css } from '@emotion/core';
import { MainHeader, Sidebar } from 'containers';
import { ScorePage, TrackStatusPage } from 'pages';
import { CustomPlan } from 'pages/CustomPlan'
import { YourSuccessPathPage } from 'pages/YourSuccessPathPage';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { RootState } from 'store';
import { getDashboardInfo, getDetailsForCandidatebyCandidateTrackId, getStatusScore, setCapability, setQuestionId, getDashboardAiScore } from 'store/evaluationPlatform';
import styled from 'styled-components';
import { DashboardView, DEFAULT_TOKEN, Is_Hiring_Manager, SIDEBAR_WIDTH } from 'utilities/constants';
import { isPlacementTrack } from 'utilities/helperFunctions';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { PrivateRoute } from 'utilities/routers/PrivateRoute';
import ClassesReport from 'pages/ClassesReport';

const StyledDashboardPage = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    z-index: 100;
    position: fixed;
    width: 100%;
  }
  .button-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: solid 2px #efefef;
  }
  .page-title {
    margin-left: 20px;
    color: #5b94e3;
    font-size: 20px;
    font-weight: bold;
  }
  .main-content {
    height: 100%;
    background-color: #eff2f5;
    justify-content: center;
    align-items: center;
    position: relative;
  }
`;

const StyledMainPage = styled.div`
  padding-top: 20px;
    transition: 1s;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 80px);

  .report-message{
    position : absolute;
    top: 50%;
    left: 35%;
    font-size: 18px;
    color: grey;
  }
`;

const PageSelectButton = styled.button<any>`
  margin-left: 50px;
  border-radius: 20px;
  width: fit-content;
  height: 36px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  }
  transition: 0.3s;
  background: ${(props) => (props.selected && props.custom ? '#5B94E3' : '#e9e9e9')};
  color: ${(props) => (props.selected && props.custom ? 'white' : 'black')};
  padding: 9px 15px;
  cursor: pointer;
  font-family: Khula;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  overflow: hidden;
`;

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  display: block;
`;


const DashboardPage: React.FC<any> = (props) => {
  const [selectedPage, selectPage] = useState('');
  let candidateTrackId = useSelector((state: any) => state.evaluationPlatform?.currentTrack?.candidate?.lastCandidateSavedSetting?.lastCandidateTrackIDWorkedOn);
  const enrollmentType = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack[0]?.trackEnrollType);
  const [dashboardData, setDashBoardData] = useState<any>({});
  const [statusScore, setStatusScore] = useState<any>({});
  const [aiScore, setAiScore] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const isHiringManager = getValueBrowserStorage(Is_Hiring_Manager) === 'true';
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    activePage();
  }, [location]);

  const activePage = () => {
    switch (location.pathname) {
      case `${props.match.path}`: selectPage('CustomPlan'); break;
      case `${props.match.path}/trackStatus`: selectPage('TrackStatus'); break;
      case `${props.match.path}/score`: selectPage('Score'); break;
      case `${props.match.path}/classesReport`: selectPage('ClassesReport'); break;
      case `${props.match.path}/successPath`: selectPage('SuccessPath'); break;
    }
  }

  const pushHistory = (pathname: string) => {
    history.push({ pathname })
  }

  const ButtonsBar = () => {
    if (!enrollmentType) return <></>;
    return (<div className="button-bar">
      <span className="page-title">Dashboard</span>
      {!isHiringManager && <PageSelectButton
        onClick={() => pushHistory(`${props.match.path}`)}
        selected={selectedPage === 'CustomPlan'}
        custom
        selectedPage
      >
        Your Custom Plan
      </PageSelectButton>}
      {false && <PageSelectButton
        disabled={true}
        selected={selectedPage === DashboardView.SuccessPath}
        onClick={() => pushHistory(`${props.match.path}/successPath`)}
      >
        Your Success Path
      </PageSelectButton>}
      {!isHiringManager && <PageSelectButton
        selected={selectedPage === 'TrackStatus'}
        onClick={() => pushHistory(`${props.match.path}/trackStatus`)}
        custom
        selectedPage
      >
        {!isPlacementTrack() ? 'Track Status' : 'Questions Status'}
      </PageSelectButton>}
      <PageSelectButton
        selected={selectedPage === 'Score'}
        onClick={() => pushHistory(`${props.match.path}/score`)}
        custom
        selectedPage
      >
        Score
      </PageSelectButton>
      {!isPlacementTrack() && !isHiringManager && <PageSelectButton
        selected={selectedPage === 'ClassesReport'}
        onClick={() => pushHistory(`${props.match.path}/classesReport`)}
        custom
        selectedPage
      >
        Classes Report
      </PageSelectButton>}
    </div>);
  };


  useEffect(() => {
    if (!candidateTrackId) {
      candidateTrackId = getValueBrowserStorage('candidateTrackId')
    };
    const payload = {
      token: DEFAULT_TOKEN,
      candidateTrackId,
    };
    Promise.all([
      props.getDashboardInfo(payload),
      props.getStatusScore(payload),
      props.getDashboardAiScore(candidateTrackId),
      props.getDetailsForCandidatebyCandidateTrackId(payload),
    ])
      .then((res: any) => {
        if (res[0].payload) {
          setDashBoardData(res[0].payload.output)
          setStatusScore(res[1].payload.output)
          setAiScore(res[2].payload.output)
        }
      }).finally(() => {
        setLoading(false)
      })
  }, []);


  return (
    <StyledDashboardPage>
      <div className="content">
        <StyledMainPage>
          <ButtonsBar />
          {loading ?
            <BeatLoader css={override} color={'#3694D7'} loading={props.loading} /> :
            <div className="main-content">
              <PrivateRoute path={`${props.match.path}`} component={CustomPlan}
                data={{
                  setQuestionId: props.setQuestionId,
                  setCapability: props.setCapability,
                  statusScore: statusScore
                }}
                exact
              />
              <PrivateRoute path={`${props.match.path}/trackStatus`} component={TrackStatusPage}
                data={{
                  setQuestionId: props.setQuestionId,
                  setCapability: props.setCapability,
                  dashboardData: dashboardData,
                }}
              />
              <PrivateRoute path={`${props.match.path}/score`} component={ScorePage}
                data={{
                  setQuestionId: props.setQuestionId,
                  setCapability: props.setCapability,
                  statusScore: statusScore,
                  aiScore: aiScore
                }}
                exact />
              {/* <PrivateRoute path={`${props.match.path}/successPath`} component={YourSuccessPathPage} exact /> */}
              <PrivateRoute path={`${props.match.path}/classesReport`} component={ClassesReport} exact />

            </div>
          }
        </StyledMainPage>
      </div>
    </StyledDashboardPage>
  );
};

export default connect(null, {
  getStatusScore,
  getDashboardInfo,
  getDetailsForCandidatebyCandidateTrackId,
  setCapability,
  setQuestionId,
  getDashboardAiScore
})(DashboardPage)
