import { Card, CardContent, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from 'react';
import { JobAttribute } from "types";

interface IQualificationScoreComponent {
    attributes: JobAttribute[]
}

const useStyles = makeStyles((theme) => ({
    text: {
        fontSize: "12px",
        lineHeight: "16px",
        padding: "4px 8px",
        marginRight: "8px",
        marginTop: "8px",
        borderRadius: "4px",
        height: "max-content",
        width: "max-content",
        fontWeight: 600
    },
    category: {
        background: "rgb(222, 231, 248)",
        color: "rgb(49, 92, 213)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    subCategory: {
        background: "rgb(211, 244, 213)",
        color: "rgb(37, 153, 45)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    score: {
        lineHeight: "16px",
        padding: "4px 8px",
        marginRight: "8px",
        marginTop: "8px",
        borderRadius: "4px",
        height: "max-content",
        fontSize: "14px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        color: "rgb(255, 255, 255)",
        width: "2rem",
    },
    scorePassed: {
        background: "rgb(127, 187, 185)",
    },
    scoreFailed: {
        background: "#dc3545"
    },
    scoreRequired: {
        background: "rgb(91 148 227)"
    },
    card: {
        boxShadow: "none"
    },
    table: {
        borderColor: "#f4f4f4"
    },
    tableRow: {
        borderBottomWidth: "1px",
        borderBottomStyle: "dashed",
        borderBottomColor: "#f4f4f4"
    },
    td: {
        borderTop: "0px !important",
    },
}));

const QualificationScoreComponent = ({ attributes }: IQualificationScoreComponent) => {
    const classes = useStyles();

    return <Card className={classes.card}>
        <CardContent>
            <table className={classes.table + " table align-middle my-0"}>
                <thead>
                    <th>{"Category"}</th>
                    <th>{"Sub Category"}</th>
                    <th>{"Your Score"}</th>
                    <th>{"Score Required"}</th>
                </thead>
                <tbody>
                    {attributes.map(attribute => {
                        return attribute.children?.map(a => <tr className={classes.tableRow}>
                            <td className={classes.td}>
                                <span className={classes.category + " " + classes.text}>{attribute.entityTitle}</span>
                            </td>
                            <td className={classes.td}>
                                <span className={classes.subCategory + " " + classes.text}>{a.entityTitle}</span>
                            </td>
                            <td className={classes.td}>
                                <span className={classes.score + " " + ((a.score && a.score >= (a.requiredScore || 0)) ? classes.scorePassed : classes.scoreFailed)}>{a.score || "-"}</span>
                            </td>
                            <td className={classes.td}>
                                <span className={classes.score + " " + classes.scoreRequired}>{a.requiredScore || 0}</span>
                            </td>
                        </tr>
                        )
                    })}
                </tbody>
            </table>
        </CardContent>
    </Card>
}

export default QualificationScoreComponent;