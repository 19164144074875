import { IconButton } from "@material-ui/core";
import { ArrowRight } from "@styled-icons/bootstrap/ArrowRight";
import { Upload } from "@styled-icons/boxicons-regular/Upload";
import axios from "axios";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { round } from "lodash";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import BeatLoader from 'react-spinners/BeatLoader';
import { toast } from "react-toastify";
import { evaluationPlatformService } from "services";
import { getValueBrowserStorage } from "services/browserStorageService";
import { RootState } from "store";
import styled from "styled-components";
import { ResumeData } from "types/OnboardCandidate";
import { convertHttpsToHttpFromUrl } from "utilities/commonUtils";
import { Candidate_Track_Id, DefaultToastSettings, Track_Id } from "utilities/constants";

const StyledWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .MuiButtonBase-root.MuiIconButton-root {
        background: #325cd7;
        color: #ffffff;
        border-radius: 20%;
    }
    .upload-icon-container {
        display: flex;
        justify-content: center;
        gap: 1rem;
    }

    .loading-text-container {
        max-width: 25rem;
    }

    .ellipsis-text {
    
    }
`

const ResumeUpload = (props: {
    loading: boolean;
    handleSubmit: (value: ResumeData) => void;
}) => {
    const candidate = useSelector((state: RootState) => state.evaluationPlatform.candidate);
    const currentTrack = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack?.[0]);
    const { enqueueSnackbar } = useSnackbar();

    const candidateTrackId = getValueBrowserStorage(Candidate_Track_Id) ?? "";
    const trackId = getValueBrowserStorage(Track_Id) ?? "";
    const directory = `${(getValueBrowserStorage('candidateId') as string)}/Resumes/${trackId}`;

    const [loading, setLoading] = useState<boolean>(false);
    const [resume, setResume] = useState<File>();

    const inputEleRef = useRef<HTMLInputElement | null>(null);
    const uploadedResumeUrl = useRef<string>('');

    const handleUploadResume = () => {
        inputEleRef.current?.click();
    }

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const maxSize = 5 * 1024 * 1024;
        const file = event.target.files?.[0];
        event.target.value = '';
        if (file?.size && file.size > maxSize) {
            toast.error('File size should be less then 5MB', DefaultToastSettings);
            return;
        }
        setResume(file);
        handleUpload(file);
    }

    const handleUpload = (file: File | undefined) => {
        if (!file) return
        setLoading(true);
        if (file) {
            const newFileName = file?.name?.trim();
            const reader = new FileReader();

            const handleActionAfterUpload = (err?: any) => {
                if (err) {
                    setLoading(false);
                    alert('Failed with error: ' + err);
                    enqueueSnackbar('Failed with error: ' + err, { variant: 'success', autoHideDuration: 2500 });
                    return;
                }
                const location = directory + '/' + file.name;
                uploadedResumeUrl.current = location;
                setLoading(false);
            }

            reader.onloadend = () => {
                const buffer = Buffer.from(reader.result as any);

                // Getting SignedInCredentials
                evaluationPlatformService.getS3SignedInCredentials({
                    path: `${directory}/${newFileName}`, candidateTrackId
                }).then((res) => {
                    let bucketUrl = res.output.url;
                    // Uploading to bucket
                    axios.put(bucketUrl, buffer)
                        .then((res) => {
                            handleActionAfterUpload();
                        }).catch(err => {
                            console.log(err);
                            axios.put(convertHttpsToHttpFromUrl(bucketUrl), buffer)
                                .then((res) => {
                                    handleActionAfterUpload();
                                }).catch(err => {
                                    handleActionAfterUpload(err);
                                })
                        })
                }).catch(err => {
                    handleActionAfterUpload(err);
                });
            };

            reader.readAsArrayBuffer(file);
        }
    };

    const handleSubmit = () => {
        if (!resume) {
            toast.error('Please select resume.', DefaultToastSettings);
            return;
        }
        props.handleSubmit({
            url: uploadedResumeUrl.current
        });
    }

    return (
        <StyledWrapper>
            <OverlayLoader loading={loading} disableOverlay={true} />
            {
                props.loading ? <div className="loading-text-container m-auto px-2">
                    <BeatLoader
                        loading={true}
                        color="#325cd5"
                    />
                    <h5 className="mt-4">
                        We're processing your resume to tailor your journey. Please hold tight while our Al analyzes your skills and experiences to guide you toward your next career move.
                    </h5>
                </div> : <>
                    <h2 className="mb-4">
                        Hello {candidate?.fullname}, Welcome to <br />
                        InterviewHelp Placement Planner!
                    </h2>
                    <h5>
                        Ready to apply to "{currentTrack?.title}"?
                    </h5>
                    <h5 className="text-muted mb-4">Upload your resume to get started!</h5>
                    <div className="upload-icon-container">
                        <IconButton onClick={handleUploadResume}>
                            <Upload width={'1.5rem'} />
                        </IconButton>
                        <div className="text-left">
                            <span className="d-block small text-muted">{resume ? "Resume" : "Upload Resume"}</span>
                            {resume ? <>
                                <span className="text-muted">{resume.name}</span><span className="text-muted">{` | File Size: ${round(resume.size / (1024 * 1024), 2)} MB`}</span>
                            </>
                                : <span className="text-muted">Format: pdf | File Size: Up to 5MB</span>}
                        </div>
                        {resume && <IconButton className="ml-4" onClick={() => handleSubmit()}>
                            <ArrowRight width={'1.5rem'} />
                        </IconButton>}
                    </div>
                    <input type="file" ref={inputEleRef} onChange={(e) => handleFileUpload(e)} hidden accept=".pdf" />
                </>
            }
        </StyledWrapper>
    )
}

export default ResumeUpload;