import React, { useMemo, useState } from 'react';

import { useQuestions } from 'components/Question/useQuestions';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Content_Height } from 'utilities/constants';
import AnswerPane from './Coding/AnswerPane';
import QuestionPane from './Question/QuestionPane';
import SplitPaneWrapper from './SplitPaneWrapper';
import { IQuestion } from './types';

export interface IQuestionCodingPane {
}

const QuestionCodingPane = ({ }: IQuestionCodingPane) => {
    const question = useQuestions();
    const selectedCapabilityId = useSelector((state: RootState) => state.evaluationPlatform.selectedCapabilityId);
    const [currentQuestion, setCurrentQuestion] = useState<IQuestion>();
    const [questionPaneSize, setQuestionPaneSize] = useState<string>();


    const handleQuestionChange = (currentQuestion?: IQuestion) => {
        setCurrentQuestion(currentQuestion);
        question.saveAndUpdateLastActivity({
            selectedCapabilityId: selectedCapabilityId,
            currentQuestionId: currentQuestion?.question?._id || "",
        })
    }

    const handlePaneSizeChange = (paneSize: string) => {
        setQuestionPaneSize(paneSize);
    }

    const renderQuestionPane = useMemo(() => {
        return <QuestionPane onQuestionChange={handleQuestionChange} />;
    }, [handleQuestionChange])

    return (
        <SplitPaneWrapper onPanseSizeChange={handlePaneSizeChange} direction="vertical" height={Content_Height}>
            {renderQuestionPane}
            {currentQuestion && <AnswerPane
                questionPaneSize={questionPaneSize}
                key={selectedCapabilityId + currentQuestion?.question._id}
            />}
        </SplitPaneWrapper>
    );
};

const QuestionsAndSplitView = () => {
    return (
        <>
            <QuestionCodingPane />
            {/* <ScreenRecorder /> */}
        </>
    )
}

export default QuestionsAndSplitView;
