import React from 'react';

interface IProps {
  fill?: string;
}

export default (props: IProps) => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M0 5.94531C0 4.28846 1.34315 2.94531 3 2.94531H3.41699C5.07384 2.94531 6.41698 4.28846 6.41698 5.94531V6.3623C6.41698 8.01915 5.07384 9.3623 3.41698 9.3623H3C1.34314 9.3623 0 8.01915 0 6.3623V5.94531Z"
        fill={props.fill || 'black'}
      />
      <path
        d="M8.4563 5.94531C8.4563 4.28846 9.79944 2.94531 11.4563 2.94531H26C27.6569 2.94531 29 4.28846 29 5.94531V6.3623C29 8.01915 27.6569 9.3623 26 9.3623H11.4563C9.79945 9.3623 8.4563 8.01915 8.4563 6.3623V5.94531Z"
        fill={props.fill || 'black'}
      />
      <path
        d="M0 14.2715C0 12.6146 1.34315 11.2715 3 11.2715H3.41699C5.07384 11.2715 6.41698 12.6146 6.41698 14.2715V14.6885C6.41698 16.3453 5.07384 17.6885 3.41698 17.6885H3C1.34314 17.6885 0 16.3453 0 14.6885V14.2715Z"
        fill={props.fill || 'black'}
      />
      <path
        d="M8.4563 14.2715C8.4563 12.6146 9.79944 11.2715 11.4563 11.2715H26C27.6569 11.2715 29 12.6146 29 14.2715V14.6885C29 16.3453 27.6569 17.6885 26 17.6885H11.4563C9.79945 17.6885 8.4563 16.3453 8.4563 14.6885V14.2715Z"
        fill={props.fill || 'black'}
      />
      <path
        d="M0 22.5977C0 20.9408 1.34315 19.5977 3 19.5977H3.41699C5.07384 19.5977 6.41698 20.9408 6.41698 22.5977V23.0149C6.41698 24.6717 5.07384 26.0149 3.41698 26.0149H3C1.34314 26.0149 0 24.6717 0 23.0149V22.5977Z"
        fill={props.fill || 'black'}
      />
      <path
        d="M8.4563 22.5977C8.4563 20.9408 9.79944 19.5977 11.4563 19.5977H26C27.6569 19.5977 29 20.9408 29 22.5977V23.0149C29 24.6717 27.6569 26.0149 26 26.0149H11.4563C9.79945 26.0149 8.4563 24.6717 8.4563 23.0149V22.5977Z"
        fill={props.fill || 'black'}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="29" height="29" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
