import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { PlusLg } from '@styled-icons/bootstrap/PlusLg';
import { ExpandMore } from '@styled-icons/material-outlined/ExpandMore';
import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import { getValueBrowserStorage } from "services/browserStorageService";
import styled from "styled-components";
import { CandidateExperienceExpertiseType } from "types/OnboardCandidate";
import { Track_Id } from "utilities/constants";
import * as Yup from 'yup';
import Experience from "./Experience";
import Expertise from "./Expertise";
import FilesUpload from "./FilesUpload";

const DefaultProjectsSchema = Yup.object().shape({
    // keyProject: Yup.string().required('Key project is required'),
    impact: Yup.string().required('Impact is required'),
});

const DefaultExperienceSchema = Yup.object().shape({
    companyName: Yup.string().required('Company name is required'),
    currentJob: Yup.boolean(),
    profile: Yup.string().required('Profile is required'),
    projects: Yup.array().nullable().of(DefaultProjectsSchema),

    startDate: Yup.date()
        .required('Start date is required')
        .typeError('Invalid date format'),

    endDate: Yup.date()
        .nullable()  // Allows endDate to be null if currentJob is true
        .when('currentJob', {
            is: false,
            then: Yup.date()
                .required('End date is required')
                .min(Yup.ref('startDate'), 'End date must be later than or equal to start date'),
            otherwise: Yup.date().nullable(),
        }),
});

const DefaultExpertiseSchema = Yup.object().shape({
    rating: Yup.number().required('Rating is required'),
    skill: Yup.string().required('Skill is required'),
});

const CandidateExperienceExpertiseSchema = Yup.object().shape({
    experiences: Yup.array().of(DefaultExperienceSchema),
    expertises: Yup.array().of(DefaultExpertiseSchema),
});

const StyledWrapper = styled.div`
    .MuiAccordionDetails-root {
        display: block;
        text-align: left;
    }

    hr {
        background: #bfd1ff;
        margin: 2.5rem 0;
    }

    .small-input-element {
        max-width: 120px;
    }
`

const CandidateExperienceExpertise = (props: {
    handleSubmit: (value: CandidateExperienceExpertiseType, moveToNext: boolean) => void;
    candidateExpertiseSubmitBtnRef: React.MutableRefObject<HTMLInputElement | null>;
    initialValue: CandidateExperienceExpertiseType;
    handleFormDataChange: (value: CandidateExperienceExpertiseType) => void;
}) => {

    const handleSubmit = (value: CandidateExperienceExpertiseType) => {
        props.handleSubmit({ ...value }, true);
    }

    const handleSave = (value: CandidateExperienceExpertiseType) => {
        props.handleSubmit({ ...value }, false);
    }

    return (
        <StyledWrapper>
            <Formik
                initialValues={{ ...props.initialValue }}
                validationSchema={CandidateExperienceExpertiseSchema}
                onSubmit={handleSubmit}
            >
                <Form>
                    <CandidateExperienceExpertiseForm
                        handleFormDataChange={props.handleFormDataChange}
                        handleSave={handleSave}
                    />
                    <input type="submit" hidden ref={props.candidateExpertiseSubmitBtnRef} />
                </Form>
            </Formik>
        </StyledWrapper>
    );
}

const CandidateExperienceExpertiseForm = (props: {
    handleFormDataChange: (value: CandidateExperienceExpertiseType) => void;
    handleSave: (value: CandidateExperienceExpertiseType) => void;
}) => {

    const { values, errors, submitCount, setFieldValue } = useFormikContext<CandidateExperienceExpertiseType>();
    const formError: any = errors;
    const addProjectBtnRef = useRef<HTMLInputElement | null>(null);

    const trackId = getValueBrowserStorage(Track_Id) ?? "";
    const directoryLicenses = `${(getValueBrowserStorage('candidateId') as string)}/Licenses/${trackId}`;
    const directoryCertificates = `${(getValueBrowserStorage('candidateId') as string)}/Certificates/${trackId}`;

    useEffect(() => {
        props.handleFormDataChange(values);
    }, [values])

    return (
        <>
            <Accordion
                defaultExpanded={true}
                className="mb-4"
            >
                <AccordionSummary
                    expandIcon={<ExpandMore width={'25px'} />}
                    aria-controls="candidate-experience"
                    id="candidate-experience-id"

                >
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <h5>
                            Add Your Experience (Optional)
                        </h5>
                        <div className="btn btn-link" onClick={(e) => {
                            e.stopPropagation();
                            addProjectBtnRef.current?.click()
                        }}>
                            <PlusLg width={'15px'} className="mr-2" style={{ marginTop: '-4px' }} />
                            <span>Add another Experience</span>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Experience
                        addProjectBtnRef={addProjectBtnRef}
                        formError={formError}
                        setFieldValue={setFieldValue}
                        submitCount={submitCount}
                        values={values}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion
                defaultExpanded={true}
                className="mb-4"
            >
                <AccordionSummary
                    expandIcon={<ExpandMore width={'25px'} />}
                    aria-controls="candidate-expertise"
                    id="candidate-expertise-id"

                >
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <h5>
                            Add Your Skills/ Areas of Expertise (Optional)
                        </h5>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {values.expertises?.length > 0 && <div className="row mb-2">
                        <div className="col-9">
                            <span className="text-muted small">Your Skills</span>
                        </div>
                        <div className="col-2">
                            <span className="text-muted small">Level of Expertise</span>
                        </div>
                    </div>}
                    <Expertise
                        formError={formError}
                        setFieldValue={setFieldValue}
                        submitCount={submitCount}
                        values={values}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion
                defaultExpanded={true}
                className="mb-4"
            >
                <AccordionSummary
                    expandIcon={<ExpandMore width={'25px'} />}
                    aria-controls="candidate-licenses"
                    id="candidate-licenses-id"

                >
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <h5>
                            Add Your Relevant Licenses (Optional)
                        </h5>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <FilesUpload
                        files={values.licenses}
                        maxFileSizeInBytes={5 * 1024 * 1024}
                        setFieldValue={setFieldValue}
                        fieldName="licenses"
                        directory={directoryLicenses}
                        saveFiles={(files) => {
                            props.handleSave({ ...values, "licenses": files })
                        }}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion
                defaultExpanded={true}
                className="mb-4"
            >
                <AccordionSummary
                    expandIcon={<ExpandMore width={'25px'} />}
                    aria-controls="candidate-certifications"
                    id="candidate-certifications-id"

                >
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <h5>
                            Add Your Relevant Certificates (Optional)
                        </h5>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <FilesUpload
                        files={values.certifications}
                        maxFileSizeInBytes={5 * 1024 * 1024}
                        setFieldValue={setFieldValue}
                        fieldName="certifications"
                        directory={directoryCertificates}
                        saveFiles={(files) => {
                            props.handleSave({ ...values, "certifications": files })
                        }}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    )
}



export default CandidateExperienceExpertise;