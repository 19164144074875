import { RoundButton } from 'components';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { RootState } from 'store';
import { getNotificationMsg } from 'store/evaluationPlatform';
import styled from 'styled-components';
import {
  ISaveResponseForQuestionOfCandidateTracks as IPayload
} from 'types';
import { DEFAULT_TOKEN, Expert_Login, MENUS, PlanType, QUESTION_STATUS_TEXT } from 'utilities/constants';
import { getQuestionStatus } from 'utilities/landingPageUtil';
import { useQuestions } from '../useQuestions';
import { ShareQuestion } from './AnswerSet/AnswerField/ShareQuestion';
import QuestionAnswers from './Components/QuestionAnswers';
import QuestionDescription from './Components/QuestionDescription';
import QuestionHeader from './Components/QuestionHeader';
import QuestionOptions from './Components/QuestionOptions';
import QuestionVideos from './Components/QuestionVideos';
import { isPlacementTrack } from 'utilities/helperFunctions';

const StyledQuestion = styled.div<any>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${props => props.communityVersion ? "0px" : "43px"};
  padding-bottom: 30px;
  @keyframes feedback {
    0% {
      color: black;
    }
    50% {
      color: #5b94e3;
    }
    100% {
      color: black;
    }
  }
  @keyframes loop {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(36deg);
    }
    20% {
      transform: rotate(72deg);
    }
    30% {
      transform: rotate(108deg);
    }
    40% {
      transform: rotate(144deg);
    }
    50% {
      transform: rotate(180deg);
    }
    60% {
      transform: rotate(216deg);
    }
    70% {
      transform: rotate(252deg);
    }
    80% {
      transform: rotate(288deg);
    }
    90% {
      transform: rotate(324deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .question__header {
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position : relative;
    .treck_description_icon{
      width: 33px;
      height: auto;
      cursor: pointer;
      margin-right: 13px;
    }
    svg {
      position: relative;
      top: 0;
    }
    img {
        cursor: pointer
        margin-top: 5px;
    }
    .loop-icon {
      position: absolute;
    }

    .circle-icon {
      svg {
        width: 8px;
        left: 3px;
      }
    }
  }
  .question__title {
    text-align: start;
    margin-left: 21px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: #000000;
  }
  .question__description {
    margin-left: 52px;
    margin-top: 15px;
    text-align: left;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
  }
  .question__description__text {
    .ql-disabled{
      background: white !important;
    }
    .ql-editor{
      padding-left: 0;
      min-height: auto !important;
    }
    margin-right: 26px;
  }
  .question__options {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .hint-solution-link {
    display: flex;
    flex-direction: row;
  }
  .solution__link {
    margin-right: 10px;
  }
  .hint__link {
    margin-right: 40px;
  }
  .question__answers {
    margin-left: 54px;
    margin-top: 35px;
  }
  .answer-version-buttons {
    margin-left: 54px;
  }
  .answer-version-buttons button {
    margin-right: 5px;
  }
  .try-again-button {
    margin-left: 54px;
  }
`;

interface IProps {
  idx?: number;
  questionIndex?: any;
  candidateTrackId: string;
  capabilityId: string;
  setAnswer: Function;
  saveResponseForQuestionOfCandidateTrack: Function;
  Modal?: any;
  setQuestionId: Function;
  currentQuestionId: string;
  submitResponseToExpert: Function;
  setFeedback: Function;
  handleEdit: Function;
  setQuestionFeedbackViewed: Function;
  candidateViewedExpertFeedback?: Function;
  questionSet: {
    question: any;
    answers: any;
  };
  isMeetingView?: boolean;
  candidate: any;
  setCurrentAnsVersionId: Function,
  saveCandidateLastActivity: Function,
  goToPaymentPage: Function,
  triggerFeedback: any;
  setTriggerFeedback: any;
  communityVersion?: boolean;
  handleAnswerShare?: () => void;
  expertName?: string;
  setMinimizeRecording?: Function;
  minimizedModelIndex?: string;
  currModelIndex?: string;
  openRecorder: string;
  setOpenRecorder: any;
  refreshStructuredFeedback?: Function;
  showSharedAnswerModal?: boolean;
  chatWithExpert: any;
}

const TryAgainButton = (props: any) => (
  <div className="try-again-button">
    <RoundButton onClick={props.handleTryAgain}>Try again</RoundButton>
  </div>
);

export const QuestionField: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [expandDescription, setExpandDescription] = useState(props.communityVersion === true);
  const scollRef = useRef<any>()
  const [question, setQuestion] = useState(props.questionSet?.question);
  const [answers, setAnswers] = useState(props.questionSet?.answers);
  const lastSelectedAnswerId = useSelector((state: any) => state.evaluationPlatform.currentAnsVersionId)
  const lastSelectedQuestionId = useSelector((state: any) => state.evaluationPlatform.currentQuestionId)
  const selectedIndex = answers.findIndex((x: any) => x.answer._id === lastSelectedAnswerId)
  const [answerVersion, setAnswerVersion] = useState((selectedIndex && selectedIndex !== -1) ? selectedIndex : 0);
  const [isSaved, setIsSaved] = useState(false);
  const [questionStatusText, setQuestionStatusText] = useState('');
  const candidateId = useSelector((state: any) => state.evaluationPlatform?.candidate?._id);
  const questionStatus = getQuestionStatus(question);
  const [isShowAllVersions, setIsShowAllVersions] = useState(false);
  const expertLogin = (getValueBrowserStorage(Expert_Login) == null) ? false : true;
  const { trackPlan, trackId } = useSelector((state: RootState) => state.payment);
  const feedbackId = answers[0]?.feedbacks[0]?._id;
  const { saveAndUpdateLastActivity } = useQuestions();
  const handleExpandClick = () => {
    setExpandDescription(!expandDescription);
    if (!expandDescription) {
      saveAndUpdateLastActivity({
        selectedCapabilityId: props.capabilityId,
        currentQuestionId: question._id,
      });
    }
    if (
      questionStatus === QUESTION_STATUS_TEXT.FEEDBACK_RECEIVED ||
      questionStatus === QUESTION_STATUS_TEXT.LOOP_FEEDBACK_RECEIVED
    ) {
      const questionAnswerId = answers[0].answer._id;
      const feedbackId = answers[0].feedbacks[0]._id;
      if (props.candidateViewedExpertFeedback) {
        props
          .candidateViewedExpertFeedback({
            token: DEFAULT_TOKEN,
            candidateTrackId: props.candidateTrackId,
            questionId: question._id,
            candidateId,
            questionAnswerId,
            feedbackId,
          }).then((res: any) => {
            if (res.payload) {
              props.setQuestionFeedbackViewed({
                capabilities: question.capabilities,
                questionId: question._id,
                questionAnswerId: questionAnswerId,
              });
              props.refreshStructuredFeedback && props.refreshStructuredFeedback();
              dispatch(getNotificationMsg({ trackId, "menu": MENUS.TRACK })); // Update TRACK notifications
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    }
  };

  const handleTryAgain = () => {
    let capabilityIds: string[] = [...answers[0].answer.capabilityIds];
    capabilityIds.push(props.capabilityId);
    capabilityIds = _.uniq(capabilityIds);

    const payload: IPayload = {
      token: DEFAULT_TOKEN,
      candidateTrackId: props.candidateTrackId,
      capabilityIds,
      questionId: question._id,
      answer: '',
      codeAnswer: '',
      codeType: ''
    };
    props
      .saveResponseForQuestionOfCandidateTrack(payload)
      .then((res: any) => {
        if (res.payload) {
          const answer = res.payload.output;
          const data = {
            _id: answer.questionAnswerId,
            questionId: answer.questionId,
            answer: answer.answer,
            codeAnswer: '',
            codeType: '',
            candidateTrackId: answer.candidateTrackId,
            updateAt: new Date(answer.updateAt).toUTCString(),
            sketchAvailable: answer.sketchAvailable
          };
          props.setCurrentAnsVersionId({
            questionId: answer.questionId,
            ansVersionId: answer.questionAnswerId
          })
          props.setAnswer({
            capabilities: question.capabilities,
            questionId: question._id,
            answer: data,
            isUpdate: false,
          });
        } else {
        }
      })
      .catch((err: any) => {
        console.log('creating new response error', err);
      });
  };
  useEffect(() => {
    setQuestion(props.questionSet?.question);
    setAnswers(props.questionSet?.answers);
    setAnswerVersion((selectedIndex && selectedIndex !== -1) ? selectedIndex : 0);
    setIsShowAllVersions(false);
  }, [props.capabilityId, props.questionSet]);
  useEffect(() => {
    if (question._id === lastSelectedQuestionId && !feedbackId) {
      setExpandDescription(true);
    }
  }, [props.capabilityId])

  useEffect(() => {
    if (question._id === lastSelectedQuestionId) {
      setTimeout(() => {
        scollRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" })
      }, 0);
    }
  }, [lastSelectedQuestionId])// eslint-disable-line

  useEffect(() => {
    if (feedbackId) {
      setExpandDescription(false);
    }
  }, [feedbackId]);

  const canShareResponceWithCommunity = (): boolean => {
    const feedbackStatus = answers[answers?.length - 1]?.feedbacks?.[0]?.feedbackStatus;
    return feedbackStatus === 'EXPERT_GIVES_FEEDBACK' || feedbackStatus === 'FEEDBACK_VIEWED_BY_CANDIDATE';
  }

  return (
    <StyledQuestion communityVersion={props.communityVersion} ref={scollRef}>
      <>
        <QuestionHeader
          communityVersion={props.communityVersion}
          idx={props.idx}
          questionIndex={() => props.questionIndex()}
          question={question}
          expandDescription={expandDescription}
          handleExpandClick={handleExpandClick}
          showSharedAnswerModal={props.showSharedAnswerModal}
        />
        {!props.communityVersion && <QuestionDescription
          expandDescription={expandDescription}
          question={question}
        />}
      </>
      {props.showSharedAnswerModal || expandDescription ? (
        <>
          {!isPlacementTrack() && <QuestionVideos questionId={question._id} />}
          <QuestionOptions
            communityVersion={props.communityVersion}
            capabilityId={props.capabilityId}
            Modal={props.Modal}
            question={question}
            setQuestionId={props.setQuestionId}
            currentQuestionId={props.currentQuestionId}
            setAnswerVersion={setAnswerVersion}
            answers={answers}
            answerVersion={answerVersion}
            isShowAllVersions={isShowAllVersions}
            setIsShowAllVersions={setIsShowAllVersions}
            setCurrentAnsVersionId={props.setCurrentAnsVersionId}
            saveCandidateLastActivity={props.saveCandidateLastActivity}
            goToPaymentPage={props.goToPaymentPage}
          />
          </>
      ) : null}
      {props.showSharedAnswerModal || expandDescription ? (
        <QuestionAnswers
          isMeetingView={props.isMeetingView}
          communityVersion={props.communityVersion}
          question={question}
          answers={answers}
          candidateTrackId={props.candidateTrackId}
          capabilityId={props.capabilityId}
          setAnswer={props.setAnswer}
          saveResponseForQuestionOfCandidateTrack={props.saveResponseForQuestionOfCandidateTrack}
          submitResponseToExpert={props.submitResponseToExpert}
          chatWithExpert={props.chatWithExpert}
          setFeedback={props.setFeedback}
          handleEdit={props.handleEdit}
          answerVersion={answerVersion}
          isSaved={isSaved}
          questionStatusText={questionStatusText}
          setIsSaved={setIsSaved}
          setQuestionId={props.setQuestionId}
          setQuestionStatusText={setQuestionStatusText}
          isShowAllVersions={isShowAllVersions}
          candidate={props.candidate}
          triggerFeedback={props.triggerFeedback}
          setTriggerFeedback={props.setTriggerFeedback}
          handleAnswerShare={props.handleAnswerShare}
          expertName={props.expertName}
          setMinimizeRecording={props.setMinimizeRecording}
          minimizedModelIndex={props.minimizedModelIndex}
          currModelIndex={props.currModelIndex}
          openRecorder={props.openRecorder}
          setOpenRecorder={props.setOpenRecorder}
        />
      ) : null}
      {!!answers?.length && !props.isMeetingView && canShareResponceWithCommunity() && !props.communityVersion && <ShareQuestion answers={answers} questionId={question._id} onShare={props.handleAnswerShare} />}
      {expandDescription && (!props.isMeetingView && ((!expertLogin) && trackPlan.toLowerCase() !== PlanType.Evaluation.toLowerCase()
        && (questionStatus === QUESTION_STATUS_TEXT.FEEDBACK_VIEWED ||
          questionStatus === QUESTION_STATUS_TEXT.LOOP_FEEDBACK_VIEWED))
        && (
          <TryAgainButton handleTryAgain={handleTryAgain} />
        ))}
    </StyledQuestion>
  );
};
