import { EXPERIENCE, EXPERTISE, OptionsDataType, PROJECT } from "types";
import { CandidateExperienceExpertiseType, CandidateProfileType } from "types/OnboardCandidate";

export const workTypes = [
    { key: 'FULL_TIME', value: 'Full - Time' },
    { key: 'PART_TIME', value: 'Part - Time' },
    { key: 'BOTH_PARTTIME_AND_FULLTIME', value: 'Both' },
];

export const workingMode = [
    { key: 'ONSITE', value: 'On-site' },
    { key: 'REMOTE', value: 'Remote' },
    { key: 'HYBRID', value: 'Hybrid' },
]

export const DefaultProjects: PROJECT = {
    keyProject: "",
    impact: "",
}

export const DefaultExperience: EXPERIENCE = {
    companyName: '',
    currentJob: false,
    profile: '',
    projects: [{ ...DefaultProjects }]
}

export const DefaultExpertise: EXPERTISE = {
    rating: 0,
    skill: ''
}

export const DefaultExperienceExperience: CandidateExperienceExpertiseType = {
    experiences: [],
    expertises: [],
    certifications: [],
    licenses: []
}

export const DefaultCandidateValue: CandidateProfileType & { selectedLocations: OptionsDataType[] } = {
    candidateId: "",
    profileImgUrl: "",
    currency: "USD",
    email: "",
    fullname: "",
    linkedinProfile: "",
    country: "",
    equity: "",
    locations: [],
    selectedLocations: [],
    preferredWorkTimezone: [],
    workType: "",
    workMode: [],

    expectedSalaryMin: "",
    expectedSalary: "",

    expectedSalaryHourly: "",
    expectedSalaryHourlyMin: "",

    resumeUrl: "",
    workAuthorization: [],
    ...DefaultExperienceExperience
}