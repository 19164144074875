import { MainHeader, Sidebar } from 'containers';
import { TalkToExpertContainer } from 'containers/TalkToExpert';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Backdrop } from "@material-ui/core";
import {
  getDetailsForCandidatebyCandidateTrackId,
} from 'store/evaluationPlatform';
import styled from 'styled-components';
import { SIDEBAR_WIDTH } from 'utilities/constants';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { RootState } from 'store';

const StyledDashboardPage = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    z-index: 100;
    position: fixed;
    width: 100%;
  }
  .main {
    padding-top: 20px;
    padding-left: 0px;
    display: flex;
    transition: 1s;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 80px);
  }
  .page-title {
    margin-left: 20px;
    color: #5b94e3;
    font-size: 20px;
    font-weight: bold;
  }
  .main-content {
    height: 100%;
  }
`;

const mapStateToProps = (state: any) => ({
  currentTrack: state.evaluationPlatform.currentTrack,
});

const mapDispatchToProps = {
  getDetailsForCandidatebyCandidateTrackId,
}

export const TalkToExpertPage: React.FC<any> = (props) => {
  const theme = useTheme();
  const [isMobileView, setIsMobileView] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isMobile) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, [isMobile])

  return (
    <StyledDashboardPage>
      <div className="content">
        <div className="main">
          <TalkToExpertContainer {...props} />
        </div>
      </div>
    </StyledDashboardPage>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TalkToExpertPage);