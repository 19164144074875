import { Country, ICity, ICountry, IState, State } from "country-state-city";
import { CANDIDATE_PROFILE, CANDIDATE_PROFILE_REQUEST, EXPERIENCE, EXPERTISE, OptionsDataType, PROJECT } from "types";
export const expertiseSkillPrefix = 'expertise-skill';
export const expertiseRatingPrefix = 'expertise-rating';
export const companyPrefix = 'experience-company';
export const startDatePrefix = 'experience-start-date';
export const endDatePrefix = 'experience-end-date';
export const currentJobPrefix = 'experience-current-job';
export const profilePrefix = 'experience-profile';
export const projectNamePrefix = 'experience-project-name';
export const projectRolePrefix = 'experience-project-role';
export const autoSaveDebounceTimeMs = 800;

export type LocationType = {
    countryName: string,
    countryId: string,
    state?: {
        stateName: string,
        stateId: string,
        city?: { cityName: string, cityId: string }
    }
}

export const workTypes = [
    { key: 'FULL_TIME', value: 'Full-Time' },
    { key: 'PART_TIME', value: 'Part-Time' },
    { key: 'NOT_LOOKING', value: 'Not Looking' },
    { key: 'BOTH_PARTTIME_AND_FULLTIME', value: 'Both Part-Time and Full-Time' },
];

export type INITIAL_VALUES = {
    fullname: string;
    email: string;
    linkedinProfile: string;
    expectedSalary: string;
    expectedSalaryHourly: string;
    workType: string;
    currency: string;
    country: string;
    timezone: string;
    expertiseList: EXPERTISE[],
    experiences: EXPERIENCE[]
};

export const defaultValues: INITIAL_VALUES = {
    fullname: '',
    email: '',
    linkedinProfile: '',
    expectedSalary: '',
    expectedSalaryHourly: '',
    workType: '',
    currency: '',
    country: '',
    timezone: '',
    expertiseList: [],
    experiences: []
};

export const defaultExpertiseValue: EXPERTISE = {
    rating: 1,
    skill: ''
};

export const defaultExperienceValue: EXPERIENCE = {
    companyName: '',
    profile: '',
    currentJob: false,
    startDate: undefined,
    endDate: undefined,
    projects: undefined,
};

export const defaultProjectValue: PROJECT = {
    impact: '',
    keyProject: ''
};

export enum steps {
    BASIC_INFORMATION,
    EXPERTISE,
    EXPERIENCE,
    REFERENCE
}

const invalidURL = "Invalid Meeting URL";
const invalidEmail = "Invalid email address";

const requiredErrorText = (field: string) => `${field} is required`;

export const validateText = (value: any, field: string) => {
    let error;
    if (!value) {
        error = requiredErrorText(field);
    }
    return error;
};

export const validateEmail = (value: any) => {
    let error;
    if (!value) {
        error = requiredErrorText('Email');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = invalidEmail;
    }
    return error;
};

export const validateURL = (value: string) => {
    let error;
    if (!value) {
        error = requiredErrorText('URL');
    } else if (!/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i.test(value)) {
        error = invalidURL;
    }
    return error;
};

export const getInitialValues = (candidateProfile: CANDIDATE_PROFILE) => {
    const { fullname, email, expertises, linkedinProfile, workType, expectedSalary, expectedSalaryHourly, currency, experiences, country, timezone } = candidateProfile;
    return {
        'fullname': fullname,
        'email': email,
        'linkedinProfile': linkedinProfile,
        'expectedSalary': expectedSalary,
        'expectedSalaryHourly': expectedSalaryHourly,
        'currency': currency,
        'country': country,
        'timezone': timezone,
        'workType': workType,
        'expertiseList': expertises,
        'experiences': experiences
    };
};

export const getInitialValsFromPayload = (payload: CANDIDATE_PROFILE_REQUEST, initialValues: INITIAL_VALUES) => {
    const newVals: any = { ...initialValues };
    newVals['linkedinProfile'] = payload.linkedinProfile;
    newVals['expectedSalary'] = payload.expectedSalary;
    newVals['expectedSalaryHourly'] = payload.expectedSalaryHourly;
    newVals['workType'] = payload.workType;
    newVals['currency'] = payload.currency;
    newVals['country'] = payload.country;
    newVals['timezone'] = payload.timezone;
    newVals['expertiseList'] = payload.expertises;
    newVals['experiences'] = payload.experiences;
    return newVals;
}

export const convertLocationToOptionDataType = (countries: { name: string, isoCode: string }[], states: IState[], cities: ICity[]) => {
    const countryOp: OptionsDataType[] = countries.map(country => ({
        element: country.name,
        elementValue: country.isoCode,
    }));
    const stateOp: OptionsDataType[] = states.map(state => {
        const country = Country.getCountryByCode(state.countryCode);
        return {
            element: `${country?.name}, ${state.name}`,
            elementValue: state.isoCode,
            parent: state.countryCode
        }
    });
    const citiesOp: OptionsDataType[] = cities.map(city => {
        const country = Country.getCountryByCode(city.countryCode);
        const state = State.getStateByCodeAndCountry(city.stateCode, city.countryCode);
        return {
            element: `${country?.name}, ${state?.name}, ${city.name}`,
            elementValue: city.name,
            groupKey: `${country?.name}-${state?.name}`,
            parent: `${city.countryCode}-${city.stateCode}`
        }
    });
    return [...countryOp, ...stateOp, ...citiesOp];
}

export const getFormatLocationForSave = (selectedOptions: OptionsDataType[]) => {
    const selectedLocations: LocationType[] = [];
    const addedIds = new Set<string>();
    for (const selectedOption of selectedOptions) {
        if (!selectedOption.parent) {
            const countryId = selectedOption.elementValue;
            if (!addedIds.has(countryId)) {
                selectedLocations.push({
                    countryName: Country.getCountryByCode(countryId)?.name!,
                    countryId: countryId,
                })
            }
        } else if (selectedOption.parent.split('-').length === 1) {
            const countryId = selectedOption.parent;
            const stateId = selectedOption.elementValue;
            const key = countryId + "-" + stateId;
            if (!addedIds.has(key)) {
                selectedLocations.push({
                    countryName: Country.getCountryByCode(countryId)?.name!,
                    countryId: countryId,
                    state: {
                        stateName: State.getStateByCodeAndCountry(stateId, countryId)?.name!,
                        stateId: stateId
                    }
                })
                addedIds.add(key);
            }
        } else if (selectedOption.parent.split('-').length === 2) {
            const [countryId, stateId] = selectedOption.parent?.split('-');
            const key = countryId + "-" + stateId + "-" + selectedOption.elementValue;
            if (!addedIds.has(key)) {
                selectedLocations.push({
                    countryName: Country.getCountryByCode(countryId)?.name!,
                    countryId: countryId,
                    state: {
                        stateName: State.getStateByCodeAndCountry(stateId, countryId)?.name!,
                        stateId: stateId,
                        city: {
                            cityName: selectedOption.element,
                            cityId: selectedOption.elementValue,
                        }
                    }
                })
                addedIds.add(key);
            }
        }
    }
    return selectedLocations;
}

export const getLocationInOptionDataFormat = (locations: LocationType[] | undefined) => {
    const countries: ICountry[] = [];
    const states: IState[] = [];
    const cities: ICity[] = [];
    for (const location of (locations ?? [])) {
        if (!location.state) {
            countries.push(Country.getCountryByCode(location.countryId)!);
        } else if (!location.state.city) {
            const state = location.state;
            states.push(State.getStateByCodeAndCountry(state.stateId, location.countryId)!);
        } else {
            const state = location.state;
            const city = location.state.city;
            cities.push({
                name: city.cityId,
                countryCode: location.countryId,
                stateCode: state.stateId,
            })
        }
    }
    return convertLocationToOptionDataType(countries, states, cities);
}