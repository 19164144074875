import React, { useEffect } from 'react';
import { AllAnswersSet } from '../AllAnswersSet';
import { AnswerSet } from '../AnswerSet';

const QuestionAnswers = (props: any) => {
    const { question, answers } = props;
    const { isSaved, questionStatusText, setIsSaved, setQuestionStatusText } = props;

    useEffect(() => {
        props.setOpenRecorder('-1')
        if (!props.isMeetingView && props.setMinimizeRecording) {
            props.setMinimizeRecording('-1')
        }
    }, [])

    return (
        <div className="question__answers">
            {props.isShowAllVersions ? (
                <AllAnswersSet communityVersion={props.communityVersion} answers={answers} capabilityId={props.capabilityId} candidate={props.candidate}></AllAnswersSet>
            ) : (
                <AnswerSet
                    isMeetingView={props.isMeetingView}
                    fullQuestion={props.question}
                    questionInfo={props.questionSet}
                    communityVersion={props.communityVersion}
                    idx={answers.length > 0 ? props.answerVersion : -1}
                    candidateTrackId={props.candidateTrackId}
                    capabilityId={props.capabilityId}
                    questionId={question._id}
                    answer={answers.length > 0 ? answers[props.answerVersion] : null}
                    handleMaximizeContent={props.handleMaximizeContent}
                    setAnswer={props.setAnswer}
                    saveResponseForQuestionOfCandidateTrack={props.saveResponseForQuestionOfCandidateTrack}
                    isSaved={isSaved}
                    setIsSaved={setIsSaved}
                    questionStatusText={questionStatusText}
                    setQuestionStatusText={setQuestionStatusText}
                    submitResponseToExpert={props.submitResponseToExpert}
                    chatWithExpert={props.chatWithExpert}
                    question={question}
                    setFeedback={props.setFeedback}
                    handleEdit={props.handleEdit}
                    answerVersion={props.answerVersion}
                    totalAnswerVersions={props.answers?.length}
                    setQuestionId={props.setQuestionId}
                    triggerFeedback={props.triggerFeedback}
                    setTriggerFeedback={props.setTriggerFeedback}
                    handleAnswerShare={props.handleAnswerShare}
                    expertName={props.expertName}
                    setMinimizeRecording={props.setMinimizeRecording}
                    minimizedModelIndex={props.minimizedModelIndex}
                    currModelIndex={props.currModelIndex}
                    openRecorder={props.openRecorder}
                    setOpenRecorder={props.setOpenRecorder}
                />
            )}
        </div>
    );
};

export default QuestionAnswers;