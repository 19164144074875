import { FiberManualRecord } from "@styled-icons/material-twotone/FiberManualRecord";
import React from "react";
import styled from 'styled-components';
import { CheckCircleOutline } from 'styled-icons/material';
import { ScoreContainer } from "./StyledContainers";


const StyledCheckCircleOutline = styled(CheckCircleOutline)`
  width: 24px;
  height: 24px;
  color: #4caf50;
`;

const StyledRadioButtonUnchecked = styled(FiberManualRecord) <{ selected: boolean }>`
  width: 24px;
  height: 24px;
  color: ${(props) => (props.selected ? 'white' : '#757575')};
`;

const StyledContainer = styled.div`
    .current-step {
        background: #325cd7;
        & span, b {
            color: #fff !important;
        }
    }
    .step {
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 0.5rem;
        border-radius: 0.5rem;
    }
    .vertical-line-container {
        display: flex;
        padding-left: 19px; /* Adjust based on width of image */
    }
    .vertical-line {
        display: block;
        border-color: rgb(189, 189, 189);
        border-left-style: dotted;
        border-left-width: 1px;
        min-height: 24px;
        margin-right: 28px; /* Adjust based on width of image */
    }
`

export type StepDetailsType = {
    label: string;
    date?: string;
    scoreText?: string;
    supportText?: string;
    completed: boolean;
}

const VerticalProgression = (props: { stepsDetails: StepDetailsType[] }) => {

    return (
        <StyledContainer>
            {
                props.stepsDetails.map((step, idx) => {
                    const currentStep = false;
                    return (
                        <React.Fragment key={idx}>
                            <div className={`step ${currentStep ? 'current-step' : ''}`}>
                                {
                                    step.completed ? <StyledCheckCircleOutline />
                                        : <StyledRadioButtonUnchecked selected={currentStep} />
                                }
                                <div>
                                    <b className="d-block">{step.label}</b>
                                    <span className="text-muted small d-block">{step.date}</span>
                                    <b className="text-muted small d-block">{step.supportText}</b>
                                </div>
                            </div>
                            <div className="vertical-line-container">
                                {idx < props.stepsDetails.length - 1 && <div className="vertical-line"></div>}
                                {step.scoreText && <ScoreContainer className="my-3">
                                    <span>{step.scoreText}</span>
                                </ScoreContainer>}
                            </div>
                        </React.Fragment>
                    )
                })
            }
        </StyledContainer>
    )
}

export default VerticalProgression