import { Button } from '@material-ui/core';
import { IconContainer } from 'components/Common/IconContainer';
import StatusText from 'components/Common/StatusText';
import { ExpertSelectionModal } from 'components/Question/QuestionField/AnswerSet/AnswerBottom/ExpertSelectionModal';
import { useGPTBot } from 'containers/GPTChatBotContainer/useGPTChatBot';
import { useLoader } from 'context/loaderDots';
import { useMessagePopup } from 'context/messagePopup';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { evaluationPlatformService } from 'services';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { RootState, useAppDispatch } from 'store';
import {
    handleEdit,
    saveCandidateLastActivity,
    setAnswer,
    setCurrentAnsVersionId,
    setQuestionId,
    setTriggerFeedback,
} from 'store/evaluationPlatform';
import { ChevronDown, ChevronUp } from 'styled-icons/boxicons-regular';
import { ISaveResponseForQuestionOfCandidateTracks, IVerifyEditing } from 'types';
import {
    DEFAULT_TOKEN,
    Expert_Login,
    PlanType,
    QUESTION_STATUS_TEXT,
    SAVING_TEXT,
    TrackEnrollType,
    answerType,
} from 'utilities/constants';
import {
    canSubmitQuestionForReview,
    getDateTimeInLocalFormat,
    getEnrollmentType,
    getExpertName,
    isPlacementTrack,
} from 'utilities/helperFunctions';
import { getQuestionStatus } from 'utilities/landingPageUtil';
import { Answer, AnswerInner, Capability, ICandidateTrackData, IQuestion } from '../types';
import { getAnswerFeedbackStatus, showExpertFeedback } from '../utils/utils';

import { useQuestions } from 'components/Question/useQuestions';
import { useSnackbar } from 'notistack';
import { setCandidateTrackId, setCurrentAnswerId, setQuestionTitle, setCurrentAnswer as setReduxCurrentAnswer, setCurrentCode as setReduxCurrentCode, setShowInterviewMeBot, setShowInterviewMeCodeBot } from 'store/splitView';
import styled from 'styled-components';

export const StyledButton = styled(Button) <{ width?: string, paddingLeft?: string }>`
    font-size: 12px !important;
    width: ${props => props.width ? props.width : 'auto'};
    &.${props => props.paddingLeft} {
        padding-left:  ${props => props.paddingLeft};
    }
`;

export type ToogleTypes = "Console" | "Feedback" | undefined | false;
interface IAnswerCTA {
    tabValue: number;
    isMeetingView?: boolean;
    questionStatusText?: string;
    isNotInterviewed?: boolean;
    submitFeedbackHandler: (expertId: string) => void;
    updateAnswer?: (data: AnswerInner) => void;
    setActiveToggleType: (toggleType: ToogleTypes) => void;
    onInterviewMe: () => void;
    onboardMode?: boolean;
    customActionBtn?: React.ReactNode[];
}

const AnswerCTA = ({
    tabValue,
    isMeetingView,
    questionStatusText = '',
    isNotInterviewed,
    submitFeedbackHandler,
    updateAnswer,
    setActiveToggleType,
    onInterviewMe,
    onboardMode,
    customActionBtn
}: IAnswerCTA) => {
    const { enqueueSnackbar } = useSnackbar();
    const { interviewMeLoading, conversation } = useSelector((state: RootState) => state.splitView.bot);
    const loader = useLoader();
    const { getExperts, getAnswersList } = useQuestions();
    const messagePopup = useMessagePopup();
    const dispatch = useAppDispatch();
    const { showBot } = useGPTBot();
    const expertLogin = !!getValueBrowserStorage(Expert_Login);

    const selectedCapabilityId = useSelector(
        (state: RootState) => state.evaluationPlatform.selectedCapabilityId
    );
    const { currentAnsVersionId, currentQuestionId } = useSelector(
        (state: RootState) => state.evaluationPlatform
    );
    const { trackPlan, trackId } = useSelector((state: RootState) => state.payment);
    const candidateTrack: ICandidateTrackData = useSelector(
        (state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack[0]
    );

    const [isShowChooseExpert, setShowChooseExpert] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState<IQuestion | undefined>(undefined);
    const [currentAnswer, setCurrentAnswer] = useState<Answer | undefined>(undefined);
    const [answerVersion, setAnswerVersion] = useState(0);
    const [tryAgainLoading, setTryAgainLoading] = useState<boolean>(false);
    const [showFeedbackToggle, setShowFeedbackToggle] = useState<boolean>(false);
    const [isCodeEditMode, setCodeEditMode] = useState<boolean>(false);
    const [statusText, setStatusText] = useState<string>(questionStatusText);
    const [toggleType, setToggleType] = useState<ToogleTypes | undefined | false>();

    const selectedCapability: Capability | undefined = candidateTrack?.capabilities?.find(
        (capability) => capability.capabilityId === selectedCapabilityId
    );

    const getChatQuestion = () => {
        let question = currentQuestion?.question.title || '';
        const answer = currentAnswer?.answer.answer || '';
        const codeAnswer = currentAnswer?.answer?.codeAnswer || '';

        if (answer || codeAnswer) {
            question += `<b>\nMy ${answer ? 'Answer' : 'Code'}:\n</b>${answer || codeAnswer}`;
        }
        return question;
    };

    const getChatPrompts = () => {
        if (currentAnswer?.answer.answer || currentAnswer?.answer.codeAnswer) {
            return ['Can you suggest ways to improve?'];
        }
        return ['Can you provide an answer for this question?'];
    };

    const handleAiChatBtn = () => {
        if (currentQuestion) {
            const chatQuestion = getChatQuestion();
            const chatPrompts = getChatPrompts();
            dispatch(
                saveCandidateLastActivity({
                    selectedCapabilityId,
                    currentQuestionId: currentQuestion?.question._id,
                })
            );
            dispatch(setQuestionId(currentQuestion?.question._id));
            showBot(currentQuestion?.question.title, false, chatPrompts, chatQuestion);
        }
    };

    const handleGetInterviewed = () => {
        if (currentAnswer && currentQuestion && currentAnswer.answer) {
            dispatch(setCandidateTrackId(candidateTrack.candidateTrackId));
            dispatch(setReduxCurrentAnswer(currentAnswer.answer.answer || ""));
            dispatch(setReduxCurrentCode(currentAnswer.answer.codeAnswer || ""));
            dispatch(setCurrentAnswerId(currentAnswer.answer._id));
            dispatch(setQuestionTitle(currentQuestion.question.title));
            if (currentQuestion?.question.answerType === answerType.CODE) {
                onInterviewMe();
                dispatch(setShowInterviewMeCodeBot(true));
            } else {
                dispatch(setShowInterviewMeBot(true));
            }
        }
    }

    const handleEditing = () => {
        dispatch(setTriggerFeedback(undefined));
        loader.showLoader();
        const capabilityIds: string[] =
            (currentAnswer?.answer && currentAnswer?.answer?.capabilityIds) ? [...new Set(currentAnswer.answer.capabilityIds)] : [selectedCapabilityId];
        const payload: IVerifyEditing = {
            token: DEFAULT_TOKEN,
            candidateTrackId: candidateTrack.candidateTrackId,
            capabilityId: selectedCapabilityId,
            questionId: currentQuestion?.question._id || '',
            questionAnswerId: currentAnswer?.answer._id || '',
        };
        evaluationPlatformService.verifyEditing(payload)
            .then((res: any) => {
                setCodeEditMode(!res.output.expertId);
                if (res.output.enableAnswer) {
                    dispatch(handleEdit({
                        ...payload,
                        capabilityIds,
                        capabilities: currentQuestion?.question?.capabilities,
                        enableAnswer: res.output.enableAnswer,
                    }));
                } else {
                    dispatch(handleEdit({
                        ...payload,
                        capabilityIds,
                        capabilities: currentQuestion?.question?.capabilities,
                        expertId: res.output.expertId,
                        enableAnswer: res.output.enableAnswer,
                    }));
                    messagePopup.fail(res.apiMessage);
                }
            })
            .catch(() => messagePopup.fail('Something went wrong, please try again.'))
            .finally(() => loader.hideLoader());
    };

    const answerStatus = currentAnswer && getAnswerFeedbackStatus(currentAnswer) || "";
    const expertName = canSubmitQuestionForReview(currentQuestion?.question) ? getExpertName(currentAnswer?.feedbacks[0]?.expertId || "") : '';
    const ansDate = currentAnswer?.answer?.updateAt && getDateTimeInLocalFormat(currentAnswer?.answer?.updateAt);
    const feedbackDate = currentAnswer?.feedbacks[0]?.feedbackAt && getDateTimeInLocalFormat(currentAnswer?.feedbacks[0]?.feedbackAt);
    const questionStatus = currentQuestion?.question && getQuestionStatus(currentQuestion?.question);

    useEffect(() => {
        const newCurrentQuestion: IQuestion | undefined = selectedCapability?.questions?.find((question: IQuestion) => question.question._id === currentQuestionId) || selectedCapability?.questions[0];
        const selectedIndex = currentAnsVersionId && newCurrentQuestion?.answers.findIndex((x: any) => x.answer._id === currentAnsVersionId);
        if (selectedIndex !== -1) {
            const newCurrentAnswer = newCurrentQuestion?.answers[selectedIndex || 0];
            setAnswerVersion(selectedIndex || 0);
            setCurrentQuestion(newCurrentQuestion);
            setCurrentAnswer(newCurrentAnswer);
        }
    }, [selectedCapability, currentQuestionId, currentAnsVersionId]);

    useEffect(() => {
        if (answerStatus === QUESTION_STATUS_TEXT.SUBMITTED_FOR_REVIEW) {
            setStatusText(`Submitted ${expertName ? `for Review to ${expertName} ` : ''} on ${ansDate} `);
        } else if (answerStatus === QUESTION_STATUS_TEXT.UNDER_REVIEW) {
            setStatusText(`Your response is under review by ${expertName}`);
        } else if (answerStatus === QUESTION_STATUS_TEXT.FEEDBACK_VIEWED) {
            setStatusText(`You responded on ${ansDate}, feedback received on ${feedbackDate}, provided by ${expertName}`);
        } else if (answerStatus === QUESTION_STATUS_TEXT.ANSWERED) {
            setStatusText(`Saved successfully at ${ansDate}`);
        }
        if (answerStatus === QUESTION_STATUS_TEXT.UNANSWERED) {
            setStatusText('');
        }
        if (currentAnswer) {
            const showFeedbackText = showExpertFeedback(currentAnswer);
            setShowFeedbackToggle(showFeedbackText);

            const type =
                showFeedbackText && !isPlacementTrack()
                    ? "Feedback"
                    : (currentQuestion?.question.answerType === answerType.CODE && toggleType === undefined)
                        ? "Console"
                        : toggleType;

            setActiveToggleType(type);
            setToggleType(type);
        }

    }, [currentAnswer]);

    useEffect(() => {
        setStatusText(questionStatusText);
    }, [questionStatusText]);

    const handleFeedbackSubmission = (expertId: string) => {
        const isCodeOrEditMode = currentQuestion?.question.answerType === answerType.CODE && (currentQuestion.question.status === QUESTION_STATUS_TEXT.ANSWERED && !currentQuestion.question.doReview || isCodeEditMode);
        submitFeedbackHandler(isCodeOrEditMode ? "" : expertId);
    };

    const handleTryAgain = () => {
        let capabilityIds: string[] = (currentAnswer?.answer && currentAnswer?.answer?.capabilityIds) ? [...new Set(currentAnswer.answer.capabilityIds)] : [selectedCapabilityId];

        const payload: ISaveResponseForQuestionOfCandidateTracks = {
            token: DEFAULT_TOKEN,
            candidateTrackId: candidateTrack.candidateTrackId,
            capabilityIds,
            questionId: currentQuestion?.question._id || "",
            answer: '',
            codeAnswer: '',
            codeType: ''
        };
        setTryAgainLoading(true);
        evaluationPlatformService
            .saveResponseForQuestionOfCandidateTrack(payload)
            .then((res: any) => {
                if (res.output) {
                    const answer = res.output;
                    const data: any = {
                        _id: answer.questionAnswerId,
                        questionId: answer.questionId,
                        answer: answer.answer,
                        codeAnswer: '',
                        codeType: '',
                        candidateTrackId: answer.candidateTrackId,
                        updateAt: new Date(answer.updateAt).toUTCString(),
                        sketchAvailable: answer.sketchAvailable
                    };
                    dispatch(setCurrentAnsVersionId({
                        questionId: answer.questionId,
                        ansVersionId: answer.questionAnswerId
                    }))
                    dispatch(setAnswer({
                        capabilities: currentQuestion?.question.capabilities || [],
                        questionId: currentQuestion?.question._id,
                        answer: data,
                        isUpdate: false,
                    }));
                    updateAnswer && updateAnswer(data)
                } else {
                }
            })
            .catch((err: any) => {
                console.log('creating new response error', err);
            })
            .finally(() => {
                setTryAgainLoading(false);
            });
    };

    const handleSelectExpert = () => {
        if (!canSubmitQuestionForReview(currentQuestion?.question)) {
            submitFeedbackHandler('');
            setShowChooseExpert(false);
            return;
        }
        if (getEnrollmentType() === TrackEnrollType.FORPLACEMENT && currentQuestion && selectedCapability) {
            let answers = getAnswersList(currentQuestion.question._id, selectedCapability.capabilityId)?.answers;
            if (answers.length > 1) {
                let lstFeedbackExpertId = answers[1].feedbacks[0].expertId;
                submitFeedbackHandler(lstFeedbackExpertId);
            } else {
                let expertList = getExperts();
                if (expertList?.length > 0) {
                    const previouseExpert = answers[0]?.answer?.lastSubmissionExpertId;
                    let randomisedExpert = previouseExpert ? previouseExpert : expertList[Math.floor(Math.random() * expertList.length)]?._id;
                    submitFeedbackHandler(randomisedExpert);
                    setShowChooseExpert(false);
                    return;
                } else {
                    enqueueSnackbar('Expert id not found.', {
                        variant: 'error',
                        autoHideDuration: 2500,
                    });
                }
            }
        }
        setShowChooseExpert(true);
    };

    return (
        <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
                {tabValue === 1 && currentQuestion && currentAnswer && (
                    <div
                        className='d-flex align-items-center'
                        onClick={() => {
                            const type = toggleType === "Console" ? false : "Console"
                            setToggleType(type);
                            setActiveToggleType(type);
                        }}
                    >
                        <span>Console</span>
                        <IconContainer height='24px' icon={toggleType === "Console" ? ChevronUp : ChevronDown} />
                    </div>
                )}
                {showFeedbackToggle && !isPlacementTrack() && (
                    <div
                        className='d-flex align-items-center'
                        onClick={() => {
                            const type = toggleType === "Feedback" ? undefined : "Feedback"
                            setToggleType(type);
                            setActiveToggleType(type);
                        }}
                    >
                        <span>Feedback</span>
                        <IconContainer height='24px' icon={toggleType === "Feedback" ? ChevronUp : ChevronDown} />
                    </div>
                )}
            </div>
            <StatusText
                questionStatusText={statusText}
                additionalText={
                    (!expertName && currentAnswer && answerStatus === QUESTION_STATUS_TEXT.SUBMITTED_FOR_REVIEW)
                        ? '(No review will be provided)'
                        : ''
                }
            />
            <div className='d-flex align-items-center'>
                {currentAnswer && !onboardMode &&
                    (answerStatus === QUESTION_STATUS_TEXT.UNANSWERED || answerStatus === QUESTION_STATUS_TEXT.ANSWERED) && (
                        <StyledButton
                            size='small'
                            variant='contained'
                            color='primary'
                            className='m-2'
                            disabled={questionStatusText === SAVING_TEXT}
                            title={'Get yourself interviewed for this question'}
                            onClick={handleGetInterviewed}
                        >
                            {interviewMeLoading && <Spinner className='mr-2' style={{ height: "1rem", width: "1rem" }} animation="border" />}
                            {"Interview Me"}
                        </StyledButton>
                    )}
                {!!conversation.length && !onboardMode && currentAnswer &&
                    (answerStatus === QUESTION_STATUS_TEXT.UNANSWERED || answerStatus === QUESTION_STATUS_TEXT.ANSWERED) && !isNotInterviewed && (
                        <StyledButton
                            size='small'
                            variant='contained'
                            color='primary'
                            className='m-2'
                            disabled={questionStatusText === SAVING_TEXT}
                            title={canSubmitQuestionForReview(currentQuestion?.question)
                                ? currentQuestion?.question.questionType === 'CONTENT'
                                    ? 'Send your question'
                                    : 'Submit for feedback'
                                : 'Submit'}
                            onClick={handleSelectExpert}
                        >
                            {canSubmitQuestionForReview(currentQuestion?.question)
                                ? currentQuestion?.question.questionType === 'CONTENT'
                                    ? 'Send'
                                    : 'Submit'
                                : 'Submit'}
                        </StyledButton>
                    )}
                {currentAnswer && !onboardMode &&
                    (answerStatus === QUESTION_STATUS_TEXT.SUBMITTED_FOR_REVIEW && !expertLogin) && (
                        <StyledButton
                            size='small'
                            variant='contained'
                            color='secondary'
                            className='m-2'
                            onClick={handleEditing}
                        >
                            {'Edit'}
                        </StyledButton>
                    )}
                {!isPlacementTrack() && !onboardMode && answerVersion === 0 && (
                    <StyledButton
                        size='small'
                        variant='contained'
                        color='primary'
                        className='m-2'
                        onClick={handleAiChatBtn}
                    >
                        {'Ask AI'}
                    </StyledButton>
                )}
                {!isMeetingView && ((!expertLogin) && trackPlan.toLowerCase() !== PlanType.Evaluation.toLowerCase()
                    && (questionStatus === QUESTION_STATUS_TEXT.FEEDBACK_VIEWED ||
                        questionStatus === QUESTION_STATUS_TEXT.LOOP_FEEDBACK_VIEWED))
                    &&
                    <StyledButton
                        size='small'
                        variant='contained'
                        color='secondary'
                        className='m-2'
                        width="6rem"
                        onClick={handleTryAgain}
                    >
                        {tryAgainLoading && <Spinner className='mr-2' style={{ height: "1rem", width: "1rem" }} animation="border" />}
                        {'Try again'}
                    </StyledButton>
                }
                <>
                    {customActionBtn?.map((btn, idx) => {
                        return <React.Fragment key={idx}>
                            {btn}
                        </React.Fragment>
                    })}
                </>
            </div>
            <ExpertSelectionModal
                isShowChooseExpert={isShowChooseExpert}
                setShowChooseExpert={setShowChooseExpert}
                questionId={currentQuestion?.question._id}
                answerId={currentAnswer?.answer._id}
                submitFeedbackToExpert={handleFeedbackSubmission}
                setExpertModel={() => { }}
            />
        </div>
    );
};

export default AnswerCTA;
