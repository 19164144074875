import { Badge } from '@material-ui/core';
import Box3d from 'components/Common/ProductBox';
import React from 'react';
import styled from 'styled-components';
import { StarFill } from '@styled-icons/bootstrap/StarFill'
import ReactTooltip from 'react-tooltip';

const StyledBox3d = styled(Box3d)`
    box-shadow: none !important;
    border-radius: 0.25rem !important;
`;

const StyledStarIcon = styled(StarFill)`
    width: 25px;
    height: 25px;
    margin-left: 5px;
`

export const ExpertTab = ({ name, badgeCount, isVolunteer, idx }: any) => (
    <>
        {isVolunteer && <ReactTooltip id={'volunteer-' + idx} type="dark" />}
        {isVolunteer &&
            <StyledStarIcon
                color='#315CD5'
                data-for={'volunteer-' + idx}
                data-tip={`${name} is a volunteer expert`}
            />}
        <Badge badgeContent={badgeCount} color="primary">
            <div className="mx-2 font-weight-bold">
                {name}
            </div>
        </Badge>
    </>
)


export const MeetingProduct = ({ prop, btnHandler, btnLabel, tag, expertServiceType }: any) => (
    <div className="wrap-flexitem flex-justify-center">
        <StyledBox3d
            className="card"
            id={prop.id}
            title={prop.displayName}
            description={prop.displayDescription}
            btnLabel={btnLabel}
            icon={'http://assets.interviewhelp.io/INTERVIEW_HELP/icons/humanLaptopDark.png'}
            clickHandler={() => btnHandler(prop.id, prop.price / 100, expertServiceType)}
            tag={tag}
            tagCss={{ fontSize: '14px', letterSpace: '1px', fontWeight: '500' }}
            isBought={prop.meetingPaidButNotScheduled}
            style={{ background: prop.meetingPaidButNotScheduled ? "#007bff17" : "#FFF", isBought: prop.meetingPaidButNotScheduled }}
        />
    </div>
)
