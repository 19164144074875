import React from 'react';
import CompactTab from '../Components/CompactTabs';
import CustomTabs from '../Components/CustomTabs';
import styled from 'styled-components';
import { RecordVoiceOver } from "@styled-icons/material-sharp/RecordVoiceOver";
import { IconContainer } from 'components/Common/IconContainer';

const CustomTabWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

interface Props {
  tabValue: number;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  isCodeAvailable: boolean;
  isDrawAvailable: boolean;
  isRTEAvailable: boolean;
  children?: React.ReactNode;
  isDrawDisabled?: boolean;
  isRecordAvailable?: boolean;
  isRecording?: boolean
  isUploadAvailable?: boolean
}

const CustomAnswerTabs: React.FC<Props> = ({
  tabValue,
  isCodeAvailable,
  isDrawAvailable,
  isRTEAvailable,
  isDrawDisabled,
  isRecordAvailable,
  handleTabChange,
  isRecording,
  children,
  isUploadAvailable,
}) => {
  return (
    <CustomTabWrapper id="answer-tabs">
      <CustomTabs tabValue={tabValue} handleTabChange={handleTabChange}>
        {isRTEAvailable && <CompactTab value={0} label="Answer" isSelected={tabValue === 0} />}
        {isCodeAvailable && <CompactTab value={1} label="Code" isSelected={tabValue === 1} />}
        {isDrawAvailable && <CompactTab disabled={isDrawDisabled} value={2} label="Draw" isSelected={tabValue === 2} />}
        {isRecordAvailable && <CompactTab icon={<div className='d-flex align-items-center'>
          <div>Record</div>
          {isRecording && <IconContainer tooltip='Pencil' icon={RecordVoiceOver} />}
        </div>} value={3} isSelected={tabValue === 3} />}
        {isUploadAvailable && <CompactTab value={4} label="Upload" isSelected={tabValue === 4} />}
      </CustomTabs>
      {children}
    </CustomTabWrapper>

  );
};

export default CustomAnswerTabs;
