import { MainHeader, Sidebar } from 'containers';
import { HiringEcosystemContainer } from 'containers/HiringEcosystemContainer';
import { useLoader } from 'context/loaderDots';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styled from 'styled-components';

const StyledHiringEcosystemPage = styled.div`
.content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    z-index: 100;
    position: fixed;
    width: 100%;
  }
}
`;

export const HiringEcosystem: React.FC<any> = (props) => {
  const loader = useLoader();

    useEffect(() => {
        loader.showLoader();
        setTimeout(() => {
            loader.hideLoader();
        }, 4000);
    }, [])



    return (
        <StyledHiringEcosystemPage>
            <div className='content'>
                <HiringEcosystemContainer history={props.history} />
            </div>
        </StyledHiringEcosystemPage>
    )
}
