import { NormalSpan, SmallSpan } from "components/Common/StyledSpans";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import styled from "styled-components";
import VerticalProgression, { StepDetailsType } from "./Components/VerticalProgression";
import { CandidateEvaluation, JobDetailsType } from "types";
import { workTypes, workingMode } from "../OnboardCandidateContants";
import { formatNumberToShortenedForm, getLocations } from "utilities/commonUtils";
import { EquityOptions } from "utilities/constants";
import { getDateTimeInLocalFormat } from "utilities/helperFunctions";
import { evaluationPlatformService } from "services";
import { useSnackbar } from "notistack";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { startCase, toLower } from "lodash";

const StyledWrapper = styled.div`
    text-align: start;
    padding: 2rem;
    height: 100%;

    .container-border {
        border: 1px solid #e6e6e6;
        border-radius: 15px;
        padding: 0.5rem;
    }

    .company-logo {
        width: 10%;
        height: auto;
    }

    .job-information {
        display: flex;
        justify-content: space-around;
        gap: 1rem;
        overflow: auto;
        padding: 1rem;
    }

    .vl {
        border-left: thin solid #bfd1ff;
    }

    .job-details-container {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        height: 70%;
    }
    .job-details-container > :nth-child(1) {
        flex-basis: 70%;
    }
    .job-details-container > :nth-child(2) {
        flex-basis: 25%;
    }
`

const JobDetails = (props: {
    jobDetail: JobDetailsType;
    handleBack: () => void;
    candidateId: string;
}) => {

    const jd = props.jobDetail;
    const currentTrack = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack?.[0]);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false);

    const [candidateProgression, setCandidateProgression] = useState<CandidateEvaluation>();

    useEffect(() => {
        setLoading(true);
        evaluationPlatformService.getCandidateEvaluationStatus(jd.jobId, props.candidateId)
            .then(res => {
                setLoading(false)
                setCandidateProgression(res.output);
            })
            .catch(() => {
                setLoading(false)
                enqueueSnackbar(`Failed to fetch job progression.`, {
                    variant: 'error',
                    autoHideDuration: 2500,
                });
            })
    }, [])

    const createProgressionStep = () => {
        const StepsDetails: StepDetailsType[] = [];

        if (candidateProgression) {
            if (candidateProgression.aiResumeEvaluation && candidateProgression.aiEvaluation) {
                const completedDate = candidateProgression.aiEvaluation.completedAt ? getDateTimeInLocalFormat(new Date(candidateProgression.aiEvaluation.completedAt).toDateString()) : undefined;
                StepsDetails.push({
                    label: 'Profile Building',
                    date: completedDate,
                    scoreText: `Job Match Score: ${candidateProgression.aiResumeEvaluation.jobMatchScore * 100}%`,
                    completed: completedDate ? true : false
                })
            }
            if (candidateProgression.candidateDetails) {
                const completedDate = candidateProgression.candidateDetails.createdAt ? getDateTimeInLocalFormat(new Date(candidateProgression.candidateDetails.createdAt).toDateString()) : undefined;
                StepsDetails.push({
                    label: 'Make a Pitch',
                    date: getDateTimeInLocalFormat(new Date(candidateProgression.candidateDetails.createdAt).toDateString()),
                    completed: completedDate ? true : false
                })
            }
            if (candidateProgression.intentEvaluation?.length > 0) {
                const intent = candidateProgression.intentEvaluation[candidateProgression.intentEvaluation.length - 1];
                const completedDate = intent.completedOn ? getDateTimeInLocalFormat(new Date(intent.completedOn).toDateString()) : undefined;
                StepsDetails.push({
                    label: 'Intent Interview',
                    date: completedDate,
                    completed: completedDate ? true : false
                })
            }
            if (candidateProgression.clientEvaluation?.length > 0) {
                for (let i in candidateProgression.clientEvaluation) {
                    const interview = candidateProgression.clientEvaluation[i];
                    const completedDate = interview.completedOn ? getDateTimeInLocalFormat(new Date(interview.completedOn).toDateString()) : undefined;
                    StepsDetails.push({
                        label: interview.title,
                        date: interview.completedOn ? getDateTimeInLocalFormat(new Date(interview.completedOn).toDateString()) : undefined,
                        completed: completedDate ? true : false
                    })
                }
            }
            if (candidateProgression.selectionStatus) {
                const completedDate = candidateProgression.selectionStatus.completedOn ? getDateTimeInLocalFormat(new Date(candidateProgression.selectionStatus.completedOn).toDateString()) : undefined;
                StepsDetails.push({
                    label: 'Selection',
                    date: completedDate,
                    completed: completedDate ? true : false,
                    supportText: candidateProgression.selectionStatus.offerStatus ? startCase(toLower(candidateProgression.selectionStatus.offerStatus)) : undefined
                })
            }
        }

        return StepsDetails;
    }

    return (
        <StyledWrapper>
            <OverlayLoader loading={loading} />
            <span className="btn btn-link pl-0" onClick={props.handleBack}>Back to All Jobs</span>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3>{currentTrack?.title}</h3>
                {jd?.companyInfo?.companyLogo && <img className="company-logo" src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg" alt="Company Logo" />}
            </div>
            <div className="job-information container-border mb-4">
                <div>
                    <SmallSpan>Company: </SmallSpan>
                    <span className="d-block">Amazon.com, Inc</span>
                </div>
                <div className="vl"></div>
                <div>
                    <SmallSpan>Job Type: </SmallSpan>
                    <span className="d-block">{workingMode.find(wm => wm.key === jd.jobType?.replace('_', ''))?.value}</span>
                </div>
                <div className="vl"></div>
                <div>
                    <SmallSpan>Location: </SmallSpan>
                    <span className="d-block">{getLocations(jd.locations)}</span>
                </div>
                <div className="vl"></div>
                <div>
                    <SmallSpan>Employment Type: </SmallSpan>
                    <span className="d-block">{workTypes.find(wt => wt.key === jd.employmentType)?.value}</span>
                </div>
                <div className="vl"></div>
                <div>
                    <SmallSpan>Compensation: </SmallSpan>
                    <span className="d-block">{jd.equity && EquityOptions[jd.equity]}</span>
                </div>
                <div className="vl"></div>
                <div>
                    <SmallSpan>Salary: </SmallSpan>
                    <span className="d-block">
                        {formatNumberToShortenedForm(jd.minSalary) + "-" + formatNumberToShortenedForm(jd.maxSalary)} ({jd.currency}) per year
                    </span>
                </div>
                <div className="vl"></div>
                <div>
                    <SmallSpan>Posted Date: </SmallSpan>
                    <span className="d-block">{jd.createdAt ? getDateTimeInLocalFormat(new Date(jd.createdAt).toDateString()) : ""}</span>
                </div>

            </div>
            <div className="job-details-container">
                <div className="container-border p-3 overflow-auto">
                    <h5>Job Description</h5>
                    {jd.jobLink && <a href={jd.jobLink} target="_blank" rel="noopener noreferrer">Job Link</a>}
                    <span dangerouslySetInnerHTML={{ __html: jd?.trackDetails?.jobDescription }} />
                </div>
                <div className="container-border overflow-auto">
                    <VerticalProgression
                        stepsDetails={createProgressionStep()}
                    />
                </div>
            </div>
        </StyledWrapper>
    )
}

export default JobDetails;