import { ChevronLeftCircle, ChevronRightCircle } from '@styled-icons/boxicons-solid';
import React, { useEffect, useRef, useState } from 'react';
import { extractNumericValue } from '../../utilities/helperFunctions';
import { StyledHorizontalSash, StyledSash } from './QuestionCodingPane.style';

type SplitDirection = 'vertical' | 'horizontal';

const toggleStyle = {
    marginLeft: '-12px',
    top: '30px',
    backgroundColor: 'white',
    cursor: 'pointer',
    minWidth: '30px',
    borderRadius: "50%"
}

interface SplitPaneProps {
    className?: string;
    sashWidth?: string;
    direction: SplitDirection;
    children: React.ReactNode[];
    marginLeft?: string;
    minSize?: string;
    height?: string;
    initialPaneSize?: string; // Add initialPaneSize prop
    maxPaneSize?: string;
    draggable?: boolean;
    showToggle?: boolean;
    hideFirstPane?: boolean;
    hideSecondPane?: boolean;
    onDrag?: () => void;
    position?: "absolute" | "fixed" | "relative" | "static" | "sticky";
    sashHeight?: string;
    sashBackgroundColor?: string;
    onPanseSizeChange?: (paneSize: string) => void;
    secondPaneOverflow?: string;
}

const Sash: React.FC<{
    onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void;
    onMouseMove: (event: MouseEvent) => void;
    onMouseUp: () => void;
    isCollapsed?: boolean;
    draggable?: boolean;
    height?: string;
    children?: React.ReactNode;
    sashWidth?: string;
    sashBackgroundColor?: string;
}> = ({ onMouseDown, onMouseMove, onMouseUp, isCollapsed, draggable, height, sashWidth, sashBackgroundColor, children }) => {
    return (
        <StyledSash
            sashWidth={sashWidth}
            isCollapsed={isCollapsed}
            onMouseDown={(e: any) => draggable && onMouseDown(e)}
            onMouseMove={(e: any) => draggable && onMouseMove(e)}
            onMouseUp={(e: any) => draggable && onMouseUp()}
            sashBackgroundColor={sashBackgroundColor}
            draggable={draggable}
            height={height}
        >
            {draggable && children}
        </StyledSash>
    );
};

const HorizontalSash: React.FC<{
    onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void;
    onMouseMove: (event: MouseEvent) => void;
    onMouseUp: () => void;
    draggable?: boolean;
    sashHeight?: string;
    sashBackgroundColor?: string;
}> = ({ onMouseDown, onMouseMove, onMouseUp, draggable, sashHeight, sashBackgroundColor }) => {
    return (
        <StyledHorizontalSash
            onMouseDown={(e: any) => draggable && onMouseDown(e)}
            onMouseMove={(e: any) => draggable && onMouseMove(e)}
            onMouseUp={(e: any) => draggable && onMouseUp()}
            draggable={draggable}
            sashHeight={sashHeight}
            sashBackgroundColor={sashBackgroundColor}
        />
    );
};

const SplitPaneWrapper: React.FC<SplitPaneProps> = ({
    className,
    direction,
    height,
    secondPaneOverflow,
    children,
    minSize,
    marginLeft,
    initialPaneSize,
    maxPaneSize,
    draggable = true,
    showToggle = true,
    position,
    onDrag,
    hideFirstPane,
    hideSecondPane,
    sashHeight,
    sashBackgroundColor,
    sashWidth,
    onPanseSizeChange,
}) => {
    const [paneSize, setPaneSize] = useState<string>(initialPaneSize || maxPaneSize || '50%');
    const containerRef = useRef<HTMLDivElement>(null);
    const resizingRef = useRef<boolean>(false);
    const prevMousePosRef = useRef<number>(0);
        const marginLeftValue = marginLeft || '0px';

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        resizingRef.current = true;
        prevMousePosRef.current = direction === 'vertical' ? event.clientX : event.clientY;
    };

    const handleMouseUp = () => {
        resizingRef.current = false;
    };

    const handleMouseMove = (event: MouseEvent) => {
        if (resizingRef.current && containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const currentMousePos = direction === 'vertical' ? event.clientX : event.clientY;

            // Calculate the difference in position
            const diff = currentMousePos - prevMousePosRef.current;

            // Update previous mouse position
            prevMousePosRef.current = currentMousePos;

            if (diff !== 0) {
                onDrag && onDrag();
                setPaneSize((prevSize) => {
                    const containerSize = direction === 'vertical' ? containerRect.width : containerRect.height;
                    const newSizePercentage = (diff / containerSize) * 100;
                    const previousValue = extractNumericValue(prevSize);
                    const totalSize = previousValue + newSizePercentage;
                    return `${Math.min(100, Math.max(0, totalSize))}%`;
                });
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    useEffect(() => {
        if (maxPaneSize || initialPaneSize) {
            setPaneSize(initialPaneSize || maxPaneSize || '50%');
        }
    }, [maxPaneSize, initialPaneSize])

    useEffect(() => {
        if (onPanseSizeChange) {
            onPanseSizeChange(paneSize);
        }
    }, [paneSize])

    const containerStyle: React.CSSProperties = {
        display: 'flex',
        width: '100%',
        position: position || 'relative',
        height: '100%',
        background: '#FFF',
        marginLeft: marginLeftValue,
    };

    const isCollapsed = extractNumericValue(paneSize) === 0;

    const firstPaneStyle: React.CSSProperties = {
        flex: 'none',
        transition: (paneSize == '0' || paneSize === "50%") ? 'width 0.3s ease-in-out' : 'none',
        width: direction === 'vertical' && children[1] ? paneSize : '100%',
        height: direction === 'horizontal' && children[1] ? paneSize : (height || '100%'),
        overflow: 'auto',
        minWidth: isCollapsed ? 'unset' : minSize
    };

    const secondPaneStyle: React.CSSProperties = {
        flex: 'auto',
        overflow: secondPaneOverflow || 'auto',
        height: height || 'inherit',
        minWidth: (isCollapsed || hideSecondPane)? 'unset' : minSize
    };


    return (
        <div className={className} style={containerStyle} ref={containerRef}>
            {direction === 'vertical' ? (
                <>
                    {!hideFirstPane && <div style={{ ...firstPaneStyle, width: hideSecondPane ? '100%' : paneSize }}>
                        {children[0]}
                    </div>}
                    {!hideFirstPane && !hideSecondPane && <Sash
                        sashWidth={sashWidth}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        draggable={draggable}
                        isCollapsed={isCollapsed}
                        sashBackgroundColor={sashBackgroundColor}
                    >
                        {showToggle && (isCollapsed ? (
                            <ChevronRightCircle
                                style={toggleStyle}
                                height={30}
                                className="position-relative text-primary"
                                onClick={() => setPaneSize(maxPaneSize || '50%')}
                            />
                        ) : (
                            <ChevronLeftCircle
                                style={toggleStyle}
                                height={30}
                                className="position-relative text-primary"
                                    onClick={() => setPaneSize('0')}
                            />
                        ))}
                    </Sash>}
                    <div style={secondPaneStyle}>{children[1]}</div>
                </>
            ) : (
                <div className='d-flex flex-column w-100'>
                    {!hideFirstPane && <div style={{ ...firstPaneStyle }}>
                        {children[0]}
                    </div>}
                    {!!children[1] && (
                        <>
                            {!hideFirstPane && !hideSecondPane && <HorizontalSash
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                draggable={draggable}
                                sashHeight={sashHeight}
                                sashBackgroundColor={sashBackgroundColor}
                            />}
                            <div style={secondPaneStyle}>{children[1]}</div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default SplitPaneWrapper;
