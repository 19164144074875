import { Microphone, MicrophoneAltSlash } from "@styled-icons/fa-solid";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from "styled-components";

const StyledChatAudioWrapper = styled.div`
  width: 4rem;
  height: 4rem;
`;

const StyledButton = styled(Button) <{ isRecognizing: boolean }>`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    border: 2px solid #007bff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation:${({ isRecognizing }: { isRecognizing: boolean }) => (isRecognizing ? 'pulse 1s infinite' : 'none')}; /* Conditionally apply animation */
  }

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      opacity: 0.6;
    }
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(0.8);
      opacity: 0.6;
    }
  }
`;

interface IChatAudio {
    onTranscriptChange: (text: string) => void;
    disabled: boolean;
    onStop: () => void;
}

const ChatAudio = forwardRef(({ disabled, onStop, onTranscriptChange }: IChatAudio, ref) => {
    useImperativeHandle(ref, () => ({
        stopAudio() {
            stopRecognition(true);
        },
        startAudio() {
            startRecognition();
        },
        isRecognizing() {
            return isRecognizing;
        }
    }));

    const handleRecognitionResult = useCallback((event: any) => {
        let fullTranscript = '';

        for (let i = 0; i < event.results.length; i++) {
            fullTranscript += event.results[i][0].transcript + ' ';
        }

        // Remove trailing space
        fullTranscript = fullTranscript.trim();

        onTranscriptChange(fullTranscript);
    }, [onTranscriptChange]);

    const createRecognitionInstance = () => {
        const recognitionInstance = new (window as any).webkitSpeechRecognition();
        recognitionInstance.continuous = true;
        recognitionInstance.interimResults = true;
        recognitionInstance.onstart = () => setIsRecognizing(true);
        recognitionInstance.onend = () => setIsRecognizing(false);
        recognitionInstance.addEventListener('result', handleRecognitionResult);
        recognitionInstance.addEventListener('audiostart', () => setIsRecognizing(true));
        recognitionInstance.addEventListener('audioend', () => setIsRecognizing(false));
        return recognitionInstance;
    };

    const recognition = useMemo(() => createRecognitionInstance(), []);

    const timerRef = useRef(null);
    const [isRecognizing, setIsRecognizing] = useState(false);

    useEffect(() => {
        return () => {
            recognition.removeEventListener('result', handleRecognitionResult);
            recognition.removeEventListener('audiostart', () => setIsRecognizing(true));
            recognition.removeEventListener('audioend', () => setIsRecognizing(false));
            recognition.stop();
        };
    }, [recognition]);

    const startRecognition = () => {
        recognition.start();
        if (timerRef.current) (timerRef.current as any).startSw();
    };

    const stopRecognition = (skipCallback?: boolean) => {
        recognition.stop();
        if (!skipCallback) {
            onStop();
        }
        if (timerRef.current) (timerRef.current as any).resetSw(undefined, false);
        setIsRecognizing(false);
    };

    const toggleRecognition = () => {
        if (isRecognizing) {
            stopRecognition();
        } else {
            startRecognition();
        }
    };

    return (
        <StyledChatAudioWrapper className="position-absolute d-flex align-items-center justify-content-center flex-column">
            <div className="d-flex align-items-center justify-content-center">
                <StyledButton
                    disabled={disabled}
                    className="start-button p-0 d-flex align-items-center justify-content-center"
                    title="Speak Answer"
                    onClick={toggleRecognition}
                    isRecognizing={isRecognizing} // Pass isRecognizing as a prop to StyledButton
                >
                    {isRecognizing ? <MicrophoneAltSlash height={'1rem'} /> : <Microphone height={'1rem'} />}
                </StyledButton>
            </div>
            {/* <Timer ref={timerRef} /> */}
        </StyledChatAudioWrapper>
    );
});

export default ChatAudio;
