import { Answer } from "../types";

export const getAnswerFeedbackStatus = (answer: Answer): string => {
    if (answer === null || (
        answer &&
        !answer?.answer?.sketchAvailable &&
        answer?.answer?.answer === '' &&
        answer?.answer?.codeAnswer === '')) {
        return 'UNANSWERED';
    } else if ((answer?.answer?.sketchAvailable || answer?.answer?.answer !== '' || answer?.answer?.codeAnswer !== '') && answer?.feedbacks?.length === 0) {
        return 'ANSWERED';
    } else if (answer?.feedbacks && answer?.feedbacks[0]?.feedbackStatus === 'RESPONSE_IS_SUBMITTED_TO_EXPERT') {
        return 'SEND_FOR_REVIEW';
    } else if (answer?.feedbacks && answer?.feedbacks[0]?.feedbackStatus === 'EXPERT_REVIEWING_RESPONSE') {
        return 'UNDER_REVIEW';
    } else if (answer?.feedbacks && answer?.feedbacks[0]?.feedbackStatus === 'EXPERT_GIVES_FEEDBACK') {
        return 'FEEDBACK_RECEIVED';
    } else if (answer?.feedbacks && answer?.feedbacks[0]?.feedbackStatus === 'FEEDBACK_VIEWED_BY_CANDIDATE') {
        return 'FEEDBACK_VIEWED_BY_CANDIDATE';
    } else if (answer?.feedbacks && answer?.feedbacks[0]?.feedbackStatus === 'RESPONSE_IS_COMPLETED_AND_SUBMITTED') {
        return 'SEND_FOR_REVIEW';
    }
    return 'UNANSWERED';
};

export const answerType = {
    RICH_TEXT: 'RICH_TEXT',
    CODE: 'CODE',
    DRAWING: 'DRAWING',
    AUDIO: 'AUDIO',
    VIDEO: 'VIDEO'
}

export const showExpertFeedback = (answer?: Answer): boolean => {
    if (!answer) {
        return false;
    }

    const { answer: ans, feedbacks } = answer;

    if (
        !ans ||
        (!ans.sketchAvailable && ans.answer === '' && ans.codeAnswer === '')
    ) {
        return false;
    }

    if (!feedbacks || feedbacks.length === 0) {
        return false;
    }

    const feedbackStatus = feedbacks[0].feedbackStatus;

    return (
        feedbackStatus === 'EXPERT_GIVES_FEEDBACK' ||
        feedbackStatus === 'FEEDBACK_VIEWED_BY_CANDIDATE'
    );
};
