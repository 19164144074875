import styled from 'styled-components';
import { SIDEBAR_WIDTH } from 'utilities/constants';

export const ProfilePage = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    z-index: 100;
    position: fixed;
    width: 100%;
  }
  .main {
    padding-top: 20px;
    display: flex;
    transition: 1s;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 80px);
    width: 80%;
  }
  
  .page-title {
    margin-left: 20px;
    color: #5b94e3;
    font-size: 20px;
    font-weight: bold;
  }
  .main-content {
    height: 100%;
  }
`;

export const Step1Container = styled.div`
`;

export const ProfileFieldContainer = styled.div`
  display: flex;
  margin-bottom: 8px;

  >:first-child {
    flex-basis: 47%;
    justify-content: flex-end;
    display: flex;
    font-weight: 500;
  }

  >:nth-child(2) {
    flex-basis: 47%;
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
  }
`;

export const FieldLabel = styled.label`
  font-size: 1.5rem; 
`;

export const FieldValue = styled.div`
  font-size: 1.2rem;
  display: flex;
`;

export const SectionDivider = styled.div`
  width: 80%;
  height: 2px;
  opacity: 0.4;
  background: grey;
  margin: auto;
  margin-top: 40px;
`;

export const ExpertiseTable = styled.table`
  width: 40%;

  td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    border: 1px solid #ddd;
    padding: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
  }

  tr:nth-child(even){
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }
`;