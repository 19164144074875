import { css } from '@emotion/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #f5f8fa;
    padding: 1rem;
    .card {
        border: 0;
        box-shadow: 0 0 20px 0 rgb(76 87 125 / 2%);
        height: 18rem;
    }
    .border-hover-primary: hover {
        border-color: #009ef7!important;
    }
    .symbol {
        display: inline-block;
        flex-shrink: 0;
        position: relative;
        border-radius: 0.475rem;
    }
    .symbol > img {
        width: 50px;
        height: 50px;
    }
    .w-50px {
        width: 50px!important;
        background-color: rgba(245,248,250,1)!important
    }
    .card-toolbar {
        display: flex;
        align-items: center;
        margin: 0.5rem 0;
        flex-wrap: wrap;
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        min-height: 70px;
        padding: 0 2.25rem;
        background-color: transparent;
    }
    .img-title {
        width: 50px;
        height: 50px;
    }
    .p-9 {
        padding: 2.25rem!important;
    }
    .pt-9 {
        padding-top: 2.25rem!important;
    }
    .title {
        font-weight: 600!important;
        font-size: 1.35rem!important;
        color: #181c32!important;
    }
    .card-text {
        height: 5rem;
        overflow: auto;
    }
    .welcome__text {
        margin-top: 24px;
        text-align: left;
        font-family: Khula;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        color: #161616;
    }
`;

export const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  display: block;
`;