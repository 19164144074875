import GPTChatBot from 'components/GPTChatBot';
import React, { useEffect, useState } from 'react';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { Candidate_Id } from 'utilities/constants';
import { ChatGptUserType, CustomElementsName, useGPTBot } from './useGPTChatBot';
import { evaluationPlatformService } from 'services';

interface IProps {
    chatWithGPT: Function,
    questionId: string
    currentCandidateId: string,
}

const GPTChatBotContainer = (props: IProps) => {

    const [message, setMessage] = useState("");
    const [clearBotMessage, setClearBotMessage] = useState("");
    const [freqAskedQuestion, setFreqAskedQuestion] = useState<{ question: string, frequency: number }[]>([]);
    const { addTextMsgToBot, setLoadingResponse, chatGPTBotState, addCustomElementToChat } = useGPTBot();

    useEffect(() => {
        evaluationPlatformService.getFreqAskedQuestionFromGPT({
            candidateId: props.currentCandidateId,
            questionId: props.questionId
        })
            .then((res) => {
                const temp = res.chatGPTFaqs?.length !== 0 ? res.chatGPTFaqs : []
                setFreqAskedQuestion(temp);
            })
            .catch(() => setFreqAskedQuestion([]))
    }, [props.questionId])

    useEffect(() => {
        addCustomElementToChat(
            ChatGptUserType.BOT,
            CustomElementsName.ConfirmButton,
            { prompts: chatGPTBotState.prompts }
        );
    }, [chatGPTBotState.prompts])

    const onUserInputChange = (message: string) => {
        setMessage(message);
    }

    const onKeyPressInInput = (e: any) => {
        const code = e.keyCode || e.which;
        if (code === 13 && !e.shiftKey && !chatGPTBotState.loadingBotResponse) {
            onSendBtnClicked()
        }
    }

    const onSendBtnClicked = () => {
        const userQuestion = message;
        if (userQuestion?.trim()) {
            setMessage("");
            setClearBotMessage(Date.now().toString(36));
            addTextMsgToBot(ChatGptUserType.USER, userQuestion);
            getGPTResponce(userQuestion, true);
        }
    }

    const getGPTResponce = (userQuestion: string, canBeFaq: boolean) => {
        chatWithGPT(userQuestion, canBeFaq, (res: any) => {
            addTextMsgToBot(ChatGptUserType.BOT, res.payload.output);
            addCustomElementToChat(ChatGptUserType.BOT, CustomElementsName.FeedbackSystem, {
                answer: res.payload.output,
                question: userQuestion,
                faqId: res.payload.faqId
            });
        }, () => {
            addTextMsgToBot(ChatGptUserType.BOT, "Oops some error occured. Please try again");
        })
    }

    const chatWithGPT = (message: any, canBeFaq: boolean, success: Function, failed: Function) => {
        const id = getValueBrowserStorage(Candidate_Id);
        const payload = {
            token: "123",
            candidateId: id,
            query: message,
            questionId: props.questionId,
            canBeFaq
        };
        setLoadingResponse(true);
        props.chatWithGPT({ payload, id })
            .then((res: any) => success(res))
            .catch((err: any) => failed(err))
            .finally(() => setLoadingResponse(false))
    }

    return (
        <GPTChatBot
            userInputValue={message}
            onUserInputChange={onUserInputChange}
            onKeyPressInInput={onKeyPressInInput}
            onSendBtnClicked={onSendBtnClicked}
            getGPTResponce={getGPTResponce}
            clearBotMessage={clearBotMessage}
            freqAskedQuestion={freqAskedQuestion}
        />
    )
}

export default GPTChatBotContainer;