import React, { useState } from 'react'
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { evaluationPlatformService } from 'services';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { Candidate_Id } from 'utilities/constants';
import { useSnackbar } from 'notistack';

interface Iprops {
    question: string,
    answer: string
    faqId: string,
}

const labels: { [index: string]: string } = {
    1: 'Useless',
    2: 'Poor',
    3: 'Ok',
    4: 'Good',
    5: 'Excellent',
};

const useStyles = makeStyles({
    root: {
        width: 'max-content',
        textAlign: 'start',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
    },
    rateThisAnswer: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '18px',
        color: '#333333c2',
    },
    rateThisAnswerLabel: {
        fontWeight: 'bold',
        marginBottom: '0.3rem'
    },
    submitButton: {
        height: '2rem',
        background: '#ff9c31',
        '&:hover': {
            background: '#EF7C00',
        }
    }
});

const FeedbackSystem = (props: Iprops) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const currentCandidateId = getValueBrowserStorage(Candidate_Id);
    const [rating, setRating] = useState<number>(3);
    const [hover, setHover] = useState(-1);
    const [disableRating, setDisableRating] = useState<boolean>(false);

    const [submitButtonState, setSubmitButtonState] = useState<{
        displayText: 'Submit Feedback' | 'Saving your response...' | 'Feedback Received!',
        disabled: boolean,
    }>({
        displayText: 'Submit Feedback',
        disabled: false,
    });

    const handleSubmitFeedback = () => {
        setSubmitButtonState({
            displayText: 'Saving your response...',
            disabled: true,
        });
        evaluationPlatformService.rateGPTAnswer({
            candidateId: currentCandidateId!,
            faqId: props.faqId,
            rating
        })
            .then(() => {
                setDisableRating(true);
                setSubmitButtonState((prev) => ({ disabled: true, displayText: 'Feedback Received!' }))
            })
            .catch(() => {
                enqueueSnackbar(`Could not save your rating. Please try again.`, {
                    variant: 'error',
                    autoHideDuration: 2500,
                });
                setSubmitButtonState((prev) => ({ disabled: false, displayText: 'Submit Feedback' }))
            })
    }

    return (
        <>
            <div className={classes.rateThisAnswer}>
                <p className={classes.rateThisAnswerLabel}>Rate this answer :</p>
            </div>
            <div className={classes.root}>
                <div>
                    <Rating
                        value={rating}
                        precision={1}
                        disabled={disableRating}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setRating(newValue);
                            }
                        }}
                        onChangeActive={(event, newHover) => {
                            setHover(newHover);
                        }}
                    />
                    {rating !== null && <Box textAlign={'center'}>{labels[hover !== -1 ? hover : rating]}</Box>}
                </div>
                <Button
                    className={classes.submitButton}
                    variant="contained"
                    color="primary"
                    disabled={submitButtonState.disabled}
                    onClick={handleSubmitFeedback}
                >
                    {submitButtonState.displayText}
                </Button>
            </div>
        </>
    )
}

export default FeedbackSystem;