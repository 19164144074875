import { MetricsField } from 'components';
import RichTextEditor from 'components/Common/Editors/RichTextEditor';
import React, { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import SketchingPane from '../Coding/SketchingPane';
import { Answer, IAnswerFeedback, IQuestion } from '../types';
import CustomFeedbackTabs from './CustomFeedbackTabs';
interface IFeedbackComponent {
    feedback: IAnswerFeedback;
    currentQuestion?: IQuestion;
    currentAnswer?: Answer;
    answerVersion?: number;
}

const CONTAINER_ID = "feedback-container";

const FeedbackComponent = forwardRef(({ answerVersion, feedback, currentQuestion, currentAnswer }: IFeedbackComponent, ref) => {
    const selectedCapabilityId = useSelector((state: RootState) => state.evaluationPlatform.selectedCapabilityId)
    const [tabValue, setTabValue] = useState<number>(0);

    const handleTabChange = (_: any, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <div className='p-2 h-100' id={CONTAINER_ID}>
            <CustomFeedbackTabs
                tabValue={tabValue}
                handleTabChange={handleTabChange}
                isDrawAvailable={true}
            />
            <div>
                {tabValue === 0 && (
                    <RichTextEditor
                        disabled={true}
                        customStyles={{ height: '100%', boxShadow: 'none' }}
                        id={feedback?._id}
                        value={feedback?.feedback}
                    />
                )}
                {tabValue === 1 && (
                    <MetricsField
                        feedback={feedback}
                        capabilityId={selectedCapabilityId}
                        splitView={true}
                    />
                )}
                {tabValue === 2 && currentQuestion && currentAnswer && (
                    <SketchingPane
                        ref={ref}
                        containerId={CONTAINER_ID}
                        currentQuestion={currentQuestion}
                        currentAnswer={currentAnswer}
                        isReadOnly={true}
                        answerVersion={answerVersion}
                        feedbackId={feedback._id}
                    />
                )}
            </div>
        </div>
    );
})

export default FeedbackComponent;
