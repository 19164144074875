import styled from 'styled-components';

interface IStyledSash {
    isCollapsed?: boolean;
    height?: string;
    sashWidth?: string
    draggable?: boolean;
    sashBackgroundColor?: string;
}

interface IHorizontalStyledSash {
    draggable?: boolean;
    sashHeight?: string;
    sashBackgroundColor?: string;
}

export const StyledSash = styled.div<IStyledSash>`
    margin-left: ${(props: IStyledSash) => props.isCollapsed ? '10px' : 0};
    width: ${(props: IStyledSash) => props.sashWidth ? props.sashWidth : '5px'};
    height: 100%;
    background-color:  ${(props: IStyledSash) => props.sashBackgroundColor ? props.sashBackgroundColor : "#eeeeee"};
    height: ${(props: IStyledSash) => props.height ? props.height : 'auto'};
    &:hover {
    background-color: #315cd5;
    }
    cursor:  ${(props: IStyledSash) => props.draggable ? 'col-resize' : 'auto'};
    z-index: 10;
    user-select: none;
`;

export const StyledHorizontalSash = styled.div<IHorizontalStyledSash>`
    margin-bottom: ${(props: IStyledSash) => props.isCollapsed ? '10px' : 0};
    height:  ${(props: IHorizontalStyledSash) => props.sashHeight ? props.sashHeight : '5px'};
    width: 100%;
    background-color:  ${(props: IHorizontalStyledSash) => props.sashBackgroundColor ? props.sashBackgroundColor : "#eeeeee"};
    &:hover {
    background-color: ${(props: IHorizontalStyledSash) => props.draggable ? '#315cd5' : '#eeeeee'};
    }
    cursor:  ${(props: IHorizontalStyledSash) => props.draggable ? 'row-resize' : 'auto'};
    z-index: 1;
    user-select: none;
`;

export const QuestionTitle = styled.div`
  text-align: start;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  padding: 10px 0;
`;

export const StyledQuestionDescription = styled.div`
    padding: 10px;
`;