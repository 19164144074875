import React, { useEffect } from 'react';
import styled from 'styled-components';
import InterviewHelpLandbot from './InterviewHelpLandbot';
import { Close } from '@styled-icons/evil/Close';
import { IconContainer } from 'components/Common/IconContainer';
import { setLandBotVisibility } from 'store/evaluationPlatform';
import { connect } from 'react-redux';
import { SIDEBAR_WIDTH } from 'utilities/constants';

const StyledBoatContainer = styled.div`
  display: flex;
  background: white;
  width: 100%
  transition: 1s;
  flex-direction: column;
  padding: 44px 40px;
  align-items: center;
  height: calc(100vh - 57px);
  position: relative;
`;

const CloseButton = styled.div`
    position: absolute;
    right: 40px;
    top: 15px;
    background-color: white;
    cursor: pointer;
    display: flex;
    padding: 5px 10px 5px 5px;
    border: 2px solid #e7e7e7;
    border-radius: 25px;
    :hover {
        background-color: #e7e7e7;
    }
    :active {
        background-color: #b5b5b5;
        border: 2px solid #b5b5b5;
    }
`

function ChatBot(props: { url: string, setBotVisibility?: Function, setBotInfo: Function }) {
    const handleCloseClick = () => {
        if (!props.setBotVisibility) return;
        props.setBotVisibility(false);
    }

    return (
        <StyledBoatContainer>
            <CloseButton className='chalt_bot' onClick={handleCloseClick}>
                <IconContainer icon={Close} style={{ display: 'inline-block', marginTop: '-2px' }} />
                <span>Close</span>
            </CloseButton>
            {props.url ? <InterviewHelpLandbot url={props.url} /> : null}
        </StyledBoatContainer>
    )
}

const mapDispatchToProps = {
    setBotVisibility: setLandBotVisibility
};

export default connect(null, mapDispatchToProps)(React.memo(ChatBot));