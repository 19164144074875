import { Robot as Bot } from '@styled-icons/fa-solid/Robot';
import React from 'react';
import { useDispatch } from "react-redux";
import Tooltip from 'react-tooltip-lite';
import { setLandBotVisibility } from "store/evaluationPlatform";
import styled from 'styled-components';

const StyledCapabilityTitle = styled.div`
    font-size: 1em;
    font-weight: bold;
    margin-top: 10px;
`;

const CapabilityTitle = ({ capabilityName, currentCapability }: { capabilityName: string, currentCapability: any }) => {
    const dispatch = useDispatch();

    const botSelectedForCapability = () => {
        dispatch(setLandBotVisibility(true));
    }

    return (
        <StyledCapabilityTitle>
            {capabilityName}
            {(currentCapability?.boat === true) && <Tooltip content={"Chat with Bot"} className="inline-block">
                <Bot className='botIcon ml-2' onClick={botSelectedForCapability} />
            </Tooltip>}
        </StyledCapabilityTitle>
    )
};

export default CapabilityTitle;