import React from 'react';
import styled from 'styled-components';
import { LocationOn, AccessTime } from '@styled-icons/material';
import { LinearProgress } from '@material-ui/core';
import { JobDetailsType } from 'types';
import { EquityOptions } from 'utilities/constants';
import { workTypes } from 'pages/OnboardCandidate/OnboardCandidateContants';
import { formatNumberToShortenedForm, getLocations } from 'utilities/commonUtils';
import { round } from 'lodash';

const Card = styled.div`
    min-width: 55rem;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    text-align: start;
    cursor: pointer;

    .card-container {
        padding: 2rem;
    }

    .job-card-footer {
        background-color: #f9fbff;
        padding: 1rem 2rem;
        border-radius: inherit;
    }

    .company-logo {
        width: 15%;
        height: auto;
    }

    .job-title {
        font-weight: bold;
        margin: 8px 0;
    }

    .job-details {
        color: #6d6d6d;
        margin-bottom: 8px;
    }

    .detail-row {
        display: flex;
        align-items: center;
        margin-top: 8px;
    }

    .detail-icon {
        margin-right: 8px;
        color: #6d6d6d;
    }

    .match-score-text {
        color: #6d6d6d;
        margin-right: 8px;
    }

    .match-score-value {
        font-weight: bold;
    }

    .progress-container {
        /* margin-top: 16px; */
    }

    .evaluation-text {
        text-align: right;
        margin-top: 8px;
    }

`;

export const ScoreContainer = {

}

const AppliedJobCard = (props: {
    jobDetail: JobDetailsType,
    handleClick: () => void,
    capabilitiesCounts: {
        numberOfQuestions: number;
        noOfQuestionAttempted: number;
        sendForReview: number;
        feedBackRecevied: number;
    }
}) => {

    const jobDetail = props.jobDetail;

    let jobState = "";
    if (props.capabilitiesCounts.numberOfQuestions === props.capabilitiesCounts.feedBackRecevied) {
        jobState = "Evaluation Completed...";
    } else if (props.capabilitiesCounts.numberOfQuestions === props.capabilitiesCounts.sendForReview) {
        jobState = "Under Review...";
    } else if (props.capabilitiesCounts.numberOfQuestions === props.capabilitiesCounts.noOfQuestionAttempted) {
        jobState = "Send For Review...";
    } else {
        jobState = "Evaluation Pending...";
    }

    return (
        <Card onClick={props.handleClick}>
            <div className='card-container'>
                <div className='d-flex align-items-center justify-content-between mb-4'>
                    <div>
                        {jobDetail?.companyInfo?.companyLogo && <img className="company-logo" src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg" alt="Company Logo" />}
                        <h2 className="job-title">{jobDetail.title}</h2>
                    </div>
                    <div className="">
                        <div className="match-score-text">Job Match Score</div>
                        <div className="match-score-value">{round(jobDetail?.jobMatchingScore ?? 0) * 100}</div>
                    </div>
                </div>

                <div className="job-details mb-4">
                    {jobDetail.equity && EquityOptions[jobDetail.equity]} <br />
                    {formatNumberToShortenedForm(jobDetail.minSalary) + "-" + formatNumberToShortenedForm(jobDetail.maxSalary)} ({jobDetail.currency}) per year
                </div>

                <div className='d-flex align-items-center'>
                    <div className="detail-row mr-3">
                        <span className="detail-icon">
                            <LocationOn size="20" />
                        </span>
                        {getLocations(jobDetail.locations)}
                    </div>

                    <div className="detail-row">
                        <span className="detail-icon">
                            <AccessTime size="20" />
                        </span>
                        {workTypes.find((wt) => wt.key === jobDetail.employmentType)?.value}
                    </div>
                </div>

            </div>
            <div className='job-card-footer'>
                <div className="progress-container">
                    {/* <LinearProgress variant="determinate" value={44} /> */}
                </div>
                <div className="evaluation-text">
                    {jobState}
                </div>
            </div>
        </Card>
    );
};

export default AppliedJobCard;
