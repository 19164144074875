import React from 'react';
import ReactDOM from 'react-dom';

interface IPortalContainer {
    children: React.ReactNode;
    portalRootId: string;
}

const PortalContainer = ({ children, portalRootId }: IPortalContainer) => {
    const portalRoot = document.getElementById(portalRootId);
    return portalRoot ? ReactDOM.createPortal(children, portalRoot) : null;
};

export default PortalContainer;
