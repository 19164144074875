import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { evaluationPlatformService } from "services";
import { getValueBrowserStorage } from "services/browserStorageService";
import { RootState } from "store";
import { getCandidateProfile, getDetailsForCandidatebyCandidateTrackId, setOnboardCandidateProfile } from "store/evaluationPlatform";
import styled from "styled-components";
import { CandidateExperienceExpertiseType, CandidateProfileType, ResumeData } from "types/OnboardCandidate";
import { Candidate_Id, Candidate_Track_Id, DEFAULT_TOKEN } from "utilities/constants";
import CandidateProfile from "./CandidateProfile";
import CandidateExperienceExpertise from "./ExperienceExpertise/CandidateExperienceExpertise";
import JobStepsContainer from "./JobStepsContainer";
import OnboardHeader from "./OnboardHeader";
import ResumeUpload from "./ResumeUpload";
import { getFormatLocationForSave } from "pages/ProfileReview/ProfileReview.util";
import AudioPermission from "./Pitch/AudioPermission";
import CandidateQAndA from "./Pitch/CandidateQAndA";
import JobStatus from "./JobDetails.tsx/JobStatus";
import JobDetails from "./JobDetails.tsx/JobDetails";
import { JobDetailsType } from "types";
import { hasAudioPermission } from "utilities/commonUtils";

const StyledWrapper = styled.div`
    height: 100vh;

    .content-container {
        overflow: auto;
        margin-top: 72px;
        height: calc(100% - 72px);
    }
`

const OnboardCandidate = () => {

    const dispatch = useDispatch();
    const candidateProfileData = useSelector((state: RootState) => state.evaluationPlatform.candidateProfileOnboard);
    const loadingCandidateActivity = useSelector((state: RootState) => state.evaluationPlatform.loading);
    let capabilities = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack?.[0]?.capabilities);
    const conversationType = useSelector((state: RootState) => state.evaluationPlatform?.candidate?.lastCandidateSavedSetting?.candidateTrackLastActivity?.value5);

    const { enqueueSnackbar } = useSnackbar();

    const candidateId = getValueBrowserStorage(Candidate_Id) ?? "";
    const candidateTrackId = getValueBrowserStorage(Candidate_Track_Id) ?? "";

    const candidateProfileSubmitBtnRef = useRef<HTMLInputElement | null>(null);
    const candidateExpertiseSubmitBtnRef = useRef<HTMLInputElement | null>(null);
    const firstTimeStepAutoMoved = useRef<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(0);      // Always show this page once, usually click on complete job, then move into the step, which is in do it tomorrow
    const [completedStep, setCompletedStep] = useState<number>(-1);
    const [useMicrophoneForPitch, setUseMicrophoneForPitch] = useState<boolean>(true);
    const [selectedJob, setSelectedJob] = useState<JobDetailsType>();

    const handleResumeSelect = (value: ResumeData) => {
        handleProfileDataChange({ ...candidateProfileData, resumeUrl: value.url });
        setLoading(true);
        let maxCallCount = 15;
        evaluationPlatformService.updateCandidateResume(value.url, candidateId, 'PROFILE_DETAILS')
            .then((res) => {
                const intervalId = setInterval(() => {
                    evaluationPlatformService.getResumeParsingStatus(candidateId)
                        .then((res) => {
                            const handleAfterResumeParse = () => {
                                setCompletedStep((prev) => Math.max(currentStep, prev));
                                setCurrentStep(1);
                                setLoading(false);
                            }
                            if (maxCallCount === 0) {
                                handleAfterResumeParse();
                            } else if (res.output.resumeParsingCompleted) {
                                clearInterval(intervalId);
                                evaluationPlatformService.getCandidateProfile(candidateId)
                                    .then(res => {
                                        handleProfileDataChange({ ...candidateProfileData, ...res.output });
                                        handleAfterResumeParse();
                                    })
                                    .catch((e) => {
                                        enqueueSnackbar('Error occurred while parsing the resume please try again!', { variant: 'error', autoHideDuration: 2500 });
                                        setLoading(false);
                                    })
                            }
                            maxCallCount--;
                        })
                        .catch((e) => {
                            enqueueSnackbar('Error occurred while parsing the resume please try again!', { variant: 'error', autoHideDuration: 2500 });
                            setLoading(false);
                        })
                }, 5000);
            })
            .catch((e) => {
                enqueueSnackbar('Error occurred while parsing the resume please try again!', { variant: 'error', autoHideDuration: 2500 });
                setLoading(false);
            })
    }

    const handleNextClick = () => {
        firstTimeStepAutoMoved.current = true;      // do not allow step change automatically once step was changed with btn
        if (currentStep === 1) {
            candidateProfileSubmitBtnRef.current?.click();
        } else if (currentStep === 2) {
            candidateExpertiseSubmitBtnRef.current?.click();
        } else if (currentStep === 3) {
            handleAudioPreferenceSelection();
        } else if (currentStep === 4) {
            setCurrentStep((prev) => prev + 1);
        } else if (currentStep === 5) {
            setCurrentStep((prev) => prev + 1);
        }
    }

    const handlePrevClick = () => {
        firstTimeStepAutoMoved.current = true;      // do not allow step change automatically once step was changed with btn
        setCurrentStep((prev) => prev - 1);
    }

    const handleAudioPreferenceSelection = () => {
        handleProfileDataChange({
            ...candidateProfileData,
            profileUpdateState: 'QUESTION_AND_ANSWER'
        });
        saveCurrentStepDetails({
            ...candidateProfileData,
            profileUpdateState: 'QUESTION_AND_ANSWER'
        }, () => {
            setCompletedStep((prev) => Math.max(currentStep, prev));
            setCurrentStep(4);
        })
    }

    const handleSubmitCandidateProfile = (value: CandidateProfileType, moveToNext: boolean) => {
        const status = moveToNext ? 'EXPERIENCE_AND_EXPERTISE' : "PROFILE_DETAILS";
        handleProfileDataChange({
            ...candidateProfileData,
            ...value,
            profileUpdateState: status
        });
        saveCurrentStepDetails({
            ...candidateProfileData,
            ...value,
            profileUpdateState: status
        }, () => {
            if (moveToNext) {
                setCompletedStep((prev) => Math.max(currentStep, prev));
                setCurrentStep(2);
            }
        })
    }

    const handleSubmitExp = (value: CandidateExperienceExpertiseType, moveToNext: boolean) => {
        const status = moveToNext ? 'MAKE_A_PITCH' : "EXPERIENCE_AND_EXPERTISE";
        handleProfileDataChange({
            ...candidateProfileData,
            ...value,
            profileUpdateState: status
        })
        saveCurrentStepDetails({
            ...candidateProfileData,
            ...value,
            profileUpdateState: status
        }, () => {
            if (moveToNext) {
                setCompletedStep((prev) => Math.max(currentStep, prev));
                setCurrentStep(3);
            }
        })
    }

    const handleProfileDataChange = (value: CandidateProfileType | CandidateExperienceExpertiseType) => {
        dispatch(setOnboardCandidateProfile({ ...candidateProfileData, ...value }));
    }

    const saveCurrentStepDetails = (value: CandidateProfileType, cb: Function) => {
        setLoading(true);
        const candidateProfile: CandidateProfileType = {
            ...candidateProfileData,
            ...value,
            candidateId,
        }
        candidateProfile['locations'] = getFormatLocationForSave(candidateProfile.selectedLocations);
        evaluationPlatformService.updateCandidateProfilePlacement(candidateProfile)
            .then((res) => {
                cb();
            })
            .catch(() => {
                enqueueSnackbar('Error occurred while saving your profile. please try again!', { variant: 'error', autoHideDuration: 2500 });
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleJobSelect = (job: JobDetailsType) => {
        setSelectedJob(job);
        handleNextClick();
    }

    const getCurrentStepDetails = () => {
        if(!candidateProfileData || !capabilities) {
            return null;
        }
        if (currentStep === 0) {
            return <ResumeUpload
                handleSubmit={handleResumeSelect}
                loading={loading}
            />
        }
        if (currentStep === 1) {
            return <JobStepsContainer
                handleNextClick={handleNextClick}
                handlePrevClick={handlePrevClick}
                currentStep={currentStep}
                completedStep={completedStep}
            >
                <CandidateProfile
                    handleSubmit={handleSubmitCandidateProfile}
                    candidateProfileSubmitBtnRef={candidateProfileSubmitBtnRef}
                    initialValue={candidateProfileData}
                    handleFormDataChange={handleProfileDataChange}
                />
            </JobStepsContainer>
        }
        if (currentStep === 2) {
            return <JobStepsContainer
                handleNextClick={handleNextClick}
                handlePrevClick={handlePrevClick}
                currentStep={currentStep}
                completedStep={completedStep}
            >
                <CandidateExperienceExpertise
                    initialValue={{
                        experiences: candidateProfileData.experiences,
                        expertises: candidateProfileData.expertises,
                        certifications: candidateProfileData.certifications,
                        licenses: candidateProfileData.licenses,
                    }}
                    handleSubmit={handleSubmitExp}
                    candidateExpertiseSubmitBtnRef={candidateExpertiseSubmitBtnRef}
                    handleFormDataChange={handleProfileDataChange}
                />
            </JobStepsContainer>
        }
        if (currentStep === 3) {
            return <JobStepsContainer
                handleNextClick={handleNextClick}
                handlePrevClick={handlePrevClick}
                currentStep={currentStep}
                completedStep={completedStep}
            >
                <AudioPermission
                    useMicrophone={useMicrophoneForPitch}
                    setUseMicrophoneForPitch={(useMicrophone) => setUseMicrophoneForPitch(useMicrophone)}
                />
            </JobStepsContainer>
        }
        if (currentStep === 4) {
            return <CandidateQAndA
                onlyUseMicrophone={useMicrophoneForPitch}
                handleQAndAComplete={handleNextClick}
            />
        }
        if (currentStep === 5) {
            return <JobStatus
                handleJobSelect={handleJobSelect}
            />
        }
        if (currentStep === 6 && selectedJob) {
            return <JobDetails
                jobDetail={selectedJob}
                handleBack={handlePrevClick}
                candidateId={candidateId}
            />
        }
    }

    useEffect(() => {
        if (!capabilities && candidateTrackId) {
            dispatch(getDetailsForCandidatebyCandidateTrackId({
                token: DEFAULT_TOKEN,
                candidateTrackId: candidateTrackId,
            }))
        }
    }, [capabilities])

    useEffect(() => {
        dispatch(getCandidateProfile(candidateId));
    }, [])

    useEffect(() => {
        hasAudioPermission().then((res) => {
            if (!res) {
                setUseMicrophoneForPitch(false)
            }
        }).catch(() => { })
    }, [useMicrophoneForPitch])

    useEffect(() => {
        if (candidateProfileData.profileUpdateState && !firstTimeStepAutoMoved.current) {
            let stepIndex = 0;
            if (candidateProfileData.profileUpdateState === 'RESUMEUPLOAD') {
                stepIndex = 0;
            }
            if (candidateProfileData.profileUpdateState === 'PROFILE_DETAILS') {
                stepIndex = 1;
            }
            if (candidateProfileData.profileUpdateState === 'EXPERIENCE_AND_EXPERTISE') {
                stepIndex = 2;
            }
            if (candidateProfileData.profileUpdateState === 'MAKE_A_PITCH') {
                stepIndex = 3;
            }
            if (candidateProfileData.profileUpdateState === 'QUESTION_AND_ANSWER') {
                stepIndex = 4;
            }
            setCurrentStep(stepIndex);
            setCompletedStep(prev => Math.max(prev, stepIndex));
            firstTimeStepAutoMoved.current = true;
        }
    }, [candidateProfileData])

    useEffect(() => {
        if (conversationType) {
            setUseMicrophoneForPitch(conversationType === "AUDIO");
        }
    }, [conversationType])

    return (
        <StyledWrapper>
            <OverlayLoader loading={loadingCandidateActivity || (loading && currentStep !== 0)} disableOverlay={true} />
            <OnboardHeader
                profileImgUrl={candidateProfileData.profileImgUrl}
                name={candidateProfileData.fullname}
                email={candidateProfileData.email}
            />
            <div className="content-container">
                {getCurrentStepDetails()}
            </div>
        </StyledWrapper>
    )
}

export default OnboardCandidate;