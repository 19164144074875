
import { Ace } from 'ace-builds';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-mysql';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/theme-monokai';
import 'brace/ext/language_tools'; // For autocompletion
import 'brace/ext/searchbox'; // For search box
import { useActiveElementContext, withSecurityFeatures } from 'components/HigherOrderComponents/withSecurityFeatures';
import _ from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import AceEditor from 'react-ace';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store';
import { setCode } from 'store/splitView';
interface ILanguageOption {
    language: string;
    code: string;
    icon: React.ReactElement; // Icon component
}

interface CompileOutput {
    message: string;
    type: 'error' | 'warning',
    column: number,
    row: number,
}

interface IAceEditorComponentProps {
    initialCode?: string;
    onChange: (value: string) => void;
}

const AceEditorComponent = forwardRef(({
    initialCode,
    onChange,
}: IAceEditorComponentProps, ref) => {
    const { theme, language, output } = useSelector((state: RootState) => state.splitView.coding);
    const [script, setScript] = useState<string>(initialCode || "");
    const [codeEditorHeight, setCodeEditorHeight] = useState<string>("500px");
    const [annotations, setAnnotations] = useState<Ace.Annotation[]>([]);
    const dispatch = useAppDispatch();

    const debouncedSetCode = useRef(
        _.debounce((value: string) => {
            dispatch(setCode((value)));
        }, 200)
    ).current;

    const handleCodeChange = (value: string) => {
        onChange(value);
        setScript(value);
        debouncedSetCode(value)
    }

    const calculateCodeEditorHeight = () => {
        const codeEditorElement = document.getElementById("code-editor-container");
        if (codeEditorElement && codeEditorElement.parentElement) {
            const parentElement = codeEditorElement.parentElement;
            const height = parentElement.getBoundingClientRect().height;
            setCodeEditorHeight(`${height}px`);
        }
    }

    function convertJDoodleErrorToAnnotation(jdoodleError: string, language: string) {
        const errorAnnotations: Ace.Annotation[] = [];
        const defaultRow = 0;
        const defaultColumn = 0;

        const parts = jdoodleError.split(`.${language}:`);

        if (parts.length > 1) {
            const errorParts = parts.slice(1);

            errorParts.forEach((errorPart) => {
                const lineErrorParts = errorPart.trim().split(':');

                if (lineErrorParts.length >= 2) {
                    const potentialLineNumber = lineErrorParts[0].trim();

                    const lineNumber = parseInt(potentialLineNumber);

                    if (!isNaN(lineNumber)) {
                        const message = lineErrorParts.slice(1).join(':').trim();

                        if (message.toLowerCase().includes('error')) {
                            errorAnnotations.push({
                                row: lineNumber - 1,
                                column: defaultColumn,
                                text: message,
                                type: 'error',
                            });
                        } else if (message.toLowerCase().includes('error')) {
                            errorAnnotations.push({
                                row: defaultRow,
                                column: defaultColumn,
                                text: jdoodleError,
                                type: 'error',
                            });
                        }
                    }
                }
            });
        } else if (jdoodleError.toLowerCase().includes('error')) {
            errorAnnotations.push({
                row: defaultRow,
                column: defaultColumn,
                text: jdoodleError,
                type: 'error',
            });
        }

        return errorAnnotations;
    }

    const validateCode = (output: string) => {
        const annotation = convertJDoodleErrorToAnnotation(output, language);
        setAnnotations(annotation);
    };

    const { setActiveElement } = useActiveElementContext();

    const handleLoad = (editor: Ace.Editor) => {
        setActiveElement(editor.container);
    }

    useImperativeHandle(ref, () => ({
        onDrag: () => {
            calculateCodeEditorHeight();
        },
        getCurrentVal: () => {
            return script;
        },
        updateVal: (val: string) => {
            handleCodeChange(val);
        }
    }));

    useEffect(() => {
        calculateCodeEditorHeight();
    }, [])

    useEffect(() => {
        validateCode(output)
    }, [output])

    return (
        <div>
            <AceEditor
                onLoad={handleLoad}
                fontSize={10}
                annotations={annotations}
                mode={language}
                theme={theme}
                value={script}
                onChange={handleCodeChange}
                width="100%"
                height={codeEditorHeight}
                enableBasicAutocompletion={true}
                enableLiveAutocompletion={true}
                editorProps={{ $blockScrolling: Infinity }}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    tabSize: 4,
                    showLineNumbers: true,
                    useWorker: true,
                }}
            />
        </div>
    );
});

export default withSecurityFeatures(AceEditorComponent);
