import ExcalidrawMinComponent from 'components/ExcalidrawComponent/ExcalidrawMin';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { evaluationPlatformService } from 'services';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { RootState } from 'store';
import { IAddSketchAnswer, IGetSketchExpertAnswer } from 'types';
import { DEFAULT_TOKEN, Expert_Login, QUESTION_STATUS_TEXT } from 'utilities/constants';
import { Answer, ICandidateTrackData, IQuestion } from '../types';

export interface ISketchUpdate { questionAnswerId: string, sketchData: string, updateAt: string };

interface ISketchingPane {
    containerId?: string;
    currentAnswer?: Answer;
    currentQuestion: IQuestion;
    answerVersion?: number;
    onSketchUpdate?: (data: ISketchUpdate) => void;
    isReadOnly?: boolean;
    feedbackId?: string;
}

const SketchingPane = forwardRef(({
    containerId,
    currentAnswer,
    currentQuestion,
    onSketchUpdate,
    isReadOnly,
    answerVersion,
    feedbackId,
}: ISketchingPane, ref) => {
    const answerRef = useRef<Answer | undefined>(currentAnswer);
    const isExpertLogin = getValueBrowserStorage(Expert_Login) !== null;
    const [saving, setSaving] = useState<boolean>(false);
    const candidateTrack: ICandidateTrackData = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack[0])
    const selectedCapabilityId = useSelector((state: RootState) => state.evaluationPlatform.selectedCapabilityId);
    const [drawHeight, setdrawHeight] = useState<string>("660px");
    const [showDrawing, setShowDrawing] = useState<boolean>(false);

    const isDisabled =
        isExpertLogin || isReadOnly || (answerVersion !== undefined && answerVersion > 0) ||
        (currentAnswer &&
            ((currentQuestion.question.status !== QUESTION_STATUS_TEXT.UNANSWERED ||
                currentAnswer?.answer?.version > 0) &&
                ((currentQuestion.question.status !== QUESTION_STATUS_TEXT.UNANSWERED &&
                    currentQuestion.question.status !== QUESTION_STATUS_TEXT.ANSWERED) ||
                    currentAnswer?.answer?.version > 0)));

    const getSketch = () => {
        if (currentAnswer?.answer?._id) {
            if (feedbackId) {
                return evaluationPlatformService.getExpertSketchAnswer({
                    token: DEFAULT_TOKEN,
                    candidateTrackId: currentAnswer?.answer?.candidateTrackId || candidateTrack.candidateTrackId,
                    feedbackId
                } as IGetSketchExpertAnswer)
            }
            return evaluationPlatformService.getUserSketchAnswer({
                candidateTrackId: currentAnswer?.answer?.candidateTrackId || candidateTrack.candidateTrackId,
                capabilityIds: currentQuestion.question.capabilities?.map(c => c.capabilityId) || ((currentAnswer?.answer && currentAnswer?.answer?.capabilityIds) ? [...new Set(currentAnswer.answer.capabilityIds)] : [selectedCapabilityId]),
                questionAnswerId: currentAnswer?.answer?._id,
                questionId: currentQuestion.question._id,
                token: DEFAULT_TOKEN,
            })
        }
    }

    const calculateDrawHeight = () => {
        const codeEditorElement = document.getElementById(containerId || "answer-container");
        if (codeEditorElement && codeEditorElement.parentElement) {
            const parentElement = codeEditorElement.parentElement;
            const height = parentElement.getBoundingClientRect().height;
            setdrawHeight(`${height}px`);
        }
    }

    const handleSketchSave = async (sketchData: string) => {
        if (isDisabled) return;
        const payload: IAddSketchAnswer = {
            candidateTrackId: answerRef.current?.answer?.candidateTrackId || candidateTrack.candidateTrackId,
            capabilityIds: currentQuestion.question.capabilities?.map(c => c.capabilityId) || ((answerRef.current?.answer && answerRef.current?.answer?.capabilityIds) ? [...new Set(answerRef.current.answer.capabilityIds)] : [selectedCapabilityId]),
            questionAnswerId: answerRef.current?.answer?._id,
            questionId: currentQuestion.question._id,
            token: DEFAULT_TOKEN,
            sketchData
        }
        setSaving(true);
        try {
            const data = await evaluationPlatformService.addUserSketchAnswer(payload);
            onSketchUpdate && onSketchUpdate(data.output)
        }
        catch (error: any) {
            console.log(error);
        }
        finally {
            setSaving(false);
        }
    }

    useEffect(() => {
        calculateDrawHeight();
    }, [])

    useEffect(() => {
        answerRef.current = currentAnswer;
    }, [currentAnswer])

    useEffect(() => {
        setShowDrawing(false);
        setTimeout(() => {
            setShowDrawing(true);
        }, 0);
    }, [answerVersion])

    useImperativeHandle(ref, () => ({
        onDrag: () => {
            calculateDrawHeight();
        },
    }));

    return <div>
        {!(currentAnswer?.answer?.sketchAvailable || currentAnswer?.answer?.sketchData) && isDisabled ?
            <div className='mx-2 my-4 text-muted text-dark small'>No Drawings Available</div>
            :
            showDrawing && <ExcalidrawMinComponent
                disabled={isDisabled}
                isSketchAvailable={currentAnswer?.answer?.sketchAvailable || !!currentAnswer?.answer?.sketchData}
                handleSketchSave={handleSketchSave}
                getSketch={getSketch}
                saving={saving}
                sketchData={currentAnswer?.answer?.sketchData}
                excalidrawHeight={drawHeight}
            />}
        {saving && <Spinner size='sm' animation='border' variant='primary' />}
    </div>
})

export default SketchingPane;