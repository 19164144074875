import React, { useEffect, useState } from 'react';
import AnswerTabContainer from "../Components/AnswerTabContainer";
import { Answer, IQuestion } from '../types';
import { ToogleTypes } from '../Coding/AnswerCTA';
import "./Question.css";

interface ISharedAnswerComponent {
    currentQuestion: IQuestion;
    sharedAnswers: Answer[];
    candidateId?: string;
}

const SharedAnswerComponent = ({ currentQuestion, sharedAnswers, candidateId }: ISharedAnswerComponent) => {
    const [tabValue, setTabValue] = useState(0);
    const [answerVersion, setAnswerVersion] = useState(0);
    const [toggleType, setToggleType] = useState<ToogleTypes>(undefined);

    const handleTabChange = (_: any, newValue: number) => {
        setTabValue(newValue);
    };

    const handlePreviousVersion = () => {
        setAnswerVersion(answerVersion - 1);
        setTabValue(0);
    }

    const handleNextVersion = () => {
        setAnswerVersion(answerVersion + 1);
        setTabValue(0);
    }

    useEffect(() => {
        if (tabValue === 1) {
            setToggleType("Console")
        } else {
            setToggleType("Feedback")
        }
    }, [tabValue])

    return <div id='shared-answer-container'>
        <AnswerTabContainer
        tabValue={tabValue}
        handleTabChange={handleTabChange}
        currentQuestion={{ question: currentQuestion.question, answers: sharedAnswers }}
        currentAnswer={sharedAnswers[answerVersion]}
        answerVersion={answerVersion}
        toggleType={toggleType}
        handlePreviousVersion={handlePreviousVersion}
        handleNextVersion={handleNextVersion}
        isReadOnly={true}
        candidateId={candidateId}
    />
    </div>
}

export default SharedAnswerComponent;