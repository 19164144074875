import React from 'react';

interface IAnsStatusTxtProps {
    questionStatusText: string;
    additionalText?: string;
}

const StatusText = (props: IAnsStatusTxtProps) => {
    return (
        <div title={props.questionStatusText}
            className="small text-muted answer-statusarea text-nowrap overflow-hidden"
            data-testid="answerfield-statustext">
            {
                `${props.questionStatusText}`
            }&nbsp;
            <b>{props.additionalText && props.additionalText}</b>
        </div>
    );
};

export default StatusText