import React from 'react';
import CompactTab from '../Components/CompactTabs';
import CustomTabs from '../Components/CustomTabs';

interface Props {
    tabValue: number;
    handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
    isDrawAvailable: boolean;
}

const CustomFeedbackTabs: React.FC<Props> = ({
    tabValue,
    isDrawAvailable,
    handleTabChange,
}) => {
    return (
        <CustomTabs tabValue={tabValue} handleTabChange={handleTabChange}>
            <CompactTab value={0} label="Feedback" isSelected={tabValue === 0} />
            <CompactTab value={1} label="Evaluation" isSelected={tabValue === 1} />
            {isDrawAvailable && <CompactTab value={2} label="Draw" isSelected={tabValue === 2} />}
        </CustomTabs>
    );
};

export default CustomFeedbackTabs;
