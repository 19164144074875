import { createSlice } from '@reduxjs/toolkit';
import { getValueBrowserStorage, setValueBrowserStorage } from 'services/browserStorageService';
import { Track_Id } from 'utilities/constants';

interface IInitialState {
    loading: boolean;
    trackId: string;
    trackPlan: string;
    trackName: string;
    planState: string;
}

const initialState: IInitialState = {
    loading: false,
    trackId: '',
    trackPlan: '',
    trackName: '',
    planState: ''
};

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setTrackInfoForPayment(state, action) {
            let tempTrackId = action.payload.trackId ? action.payload.trackId : state.trackId;
            if (!tempTrackId) { tempTrackId = getValueBrowserStorage(Track_Id) }
            setValueBrowserStorage(Track_Id, tempTrackId);
            state.trackId = tempTrackId;
            state.trackPlan = action.payload.trackPlan ? action.payload.trackPlan : state.trackPlan;
            state.trackName = action.payload.trackName ? action.payload.trackName : state.trackName;
            state.planState = action.payload.planState ? action.payload.planState : state.planState;
        },
    },
});

export const {
    setTrackInfoForPayment
} = paymentSlice.actions;
export default paymentSlice.reducer;
