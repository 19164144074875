import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const InterviewHelpLandbotContainer = styled.div`
    min-height: 0;
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    margin: 20px;
    width: 100%;
`

function InterviewHelpLandbot(props: { url: string }) {
    const containerRef = useRef(null);
    const { url } = props;
    useEffect(() => {
        const Landbot = (window as any).Landbot;
        let _landbot = new Landbot.Container({
            container: containerRef.current,
            configUrl: url
        });
        return () => {
            _landbot?.destroy();
        };
    }, [url, containerRef]);

    return <InterviewHelpLandbotContainer ref={containerRef} />;
}

export default React.memo(InterviewHelpLandbot)