import React from 'react';
import InterviewMeWrapper from './InterviewMeWrapper';
import { answerType } from 'utilities/constants';

interface IInterviewMe {
    type: string;
    onClose: () => void;
}

const InterviewMe = ({ type, onClose }: IInterviewMe) => {
    return <InterviewMeWrapper onClose={onClose}>
        {type == answerType.CODE && <div style={{ height: "100%" }} id='coding-pane-root'></div>}
    </InterviewMeWrapper>
}

export default InterviewMe;