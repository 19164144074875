import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { HintsAndSampleSolutionService } from 'services/hintsAndSampleSolutions';
import { DEFAULT_TOKEN } from 'utilities/constants';
import { IHint } from '../types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ChevronDown, ChevronUp } from "@styled-icons/boxicons-regular";
import RichTextEditor from 'components/Common/Editors/RichTextEditor';
import { Spinner } from 'react-bootstrap';

const accordionStyle = {
    backgroundColor: "#f8f8f8",
    boxShadow: "none",
};

interface IQuestionHint {
    questionId: string;
}

const QuestionHint = ({ questionId }: IQuestionHint) => {
    const [questionHints, setQuestionHints] = useState<IHint[]>([]);
    const [expandedHints, setExpandedHints] = useState<boolean[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getPayload = () => {
        const trackId = getValueBrowserStorage('candidateTrackId');
        if (trackId && questionId) {
            return {
                token: DEFAULT_TOKEN,
                candidateTrackId: trackId,
                questionId,
            };
        } else return null;
    };

    const showHints = () => {
        const payload = getPayload();
        if (payload) {
            setIsLoading(true);
            HintsAndSampleSolutionService.getHintsByQuestion(payload).then((res) => {
                const initialExpandedState = Array(res.output.length).fill(false);
                initialExpandedState[0] = true; // Set the first hint to expanded
                setExpandedHints(initialExpandedState);
                setQuestionHints(res.output);
            }).catch((error: any) => console.log(error)).finally(() => {
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        showHints();
    }, []);

    const handleExpandCollapse = (index: number) => {
        setExpandedHints((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            newExpanded[index] = !newExpanded[index];
            return newExpanded;
        });
    };

    return (
        <div>
            {isLoading && <Spinner animation='border'/>}
            {questionHints.map((hint, index) => (
                <Box margin={2} key={index}>
                    <Accordion style={accordionStyle} expanded={expandedHints[index]} onChange={() => handleExpandCollapse(index)}>
                        <AccordionSummary
                            expandIcon={
                                expandedHints[index] ? (
                                    <ChevronUp size={20} />
                                ) : (
                                    <ChevronDown size={20} />
                                )
                            }
                            aria-controls={`panel${index + 1}a-content`}
                            id={`panel${index + 1}a-header`}
                        >
                            <Typography variant="h6">{hint?.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <RichTextEditor
                                    doNotAllowCopy={true}
                                    disabled={true}
                                    id={`hintsRTE-${index}`}
                                    value={hint?.description}
                                    customStyles={{ height: 'auto', resize: 'none', boxShadow: 'none' }}
                                />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            ))}
        </div>
    );
};

export default QuestionHint;
