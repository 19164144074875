import { Button, InputBase, Tab, Tabs } from "@material-ui/core";
import { WhiteInterviewHelpLogoIcon } from "assets";
import { Logo } from "components";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { evaluationPlatformService } from "services";
import { setValueBrowserStorage } from "services/browserStorageService";
import styled from "styled-components";
import { Authorization_Token, Candidate_Id, Candidate_Track_Id, DEFAULT_TOKEN, FLOW_TYPE, Market_Logo, Market_Name } from "utilities/constants";
import { getMarketIdAsPerDomain } from "utilities/helperFunctions";
import * as Yup from 'yup';
import queryString from 'query-string'
import { handleLandingPageSignupSuccess } from "containers/Signup/landingPageSignup";
import { useLoader } from "context/loaderDots";
import { useStripePayment } from "components/Common/customHooks/stipePayment";
import { useTalkToExperts } from "containers/TalkToExpert/useTalkToExpert";
import { createCandidateTrackForCandidate, createNewCandidateForMarketAction, login } from "store/evaluationPlatform";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { setTrackInfoForPayment } from "store/payment";

const StyledWrapper = styled.div`
    display: flex;
    height: 100vh;

    .partner-info-container {
        display: flex;
        flex-basis: 30%;
        background: #325cd7;
        padding: 3rem;
    }
    .partner-info-container > div {
        margin: auto;
    }
    .inputs-container {
        margin: auto;
        flex-basis: 70%;
        padding: 3rem;
    }
    .inputs-container > div {
        width: 100%;
        min-height: 30rem;
        max-width: 50rem;
        margin: auto;
    }
    .inputs-container .MuiButtonBase-root.MuiTab-root {
        min-width: unset;
        padding: 0;
        margin-right: 1rem;
    }
    
`

type SignUpDataType = {
    name: string,
    email: string,
    password: string,
    confirmPassword: string
}
type LoginDataType = {
    email: string,
    password: string,
}

type AuthMode = 'Login' | 'Signup'

const _AuthPage = (props: {
    setTrackInfoForPayment: Function,
    login: any,
    createNewCandidateForMarketAction: Function,
    createCandidateTrackForCandidate: Function
}) => {

    const loader = useLoader();
    const { search } = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [authMode, setAuthMode] = useState<AuthMode>('Signup');
    const { enqueueSnackbar } = useSnackbar();
    const _queryParams: any = queryString.parse(search);
    const { startCheckout } = useStripePayment();
    const { continueCheckout } = useTalkToExperts();
    const [marketList, setMarketList] = useState<Array<any>>([]);

    const handleModeChange = (newMode: AuthMode) => {
        setAuthMode(newMode)
    }

    const onLoginSuccess = (res: any, trackInfo: any, evalLogin: boolean) => {
        if (res.payload?.apiStatus === 'SUCCESS') {
            enqueueSnackbar('Login Successful!', { variant: 'success', autoHideDuration: 2500 });
            setValueBrowserStorage(Candidate_Id, res.payload.output.candidateId);
            setValueBrowserStorage(Authorization_Token, res.payload.output.authorizationToken);
            setValueBrowserStorage(Market_Name, res.payload?.output?.marketName);
            setValueBrowserStorage(Market_Logo, res.payload?.output?.marketLogo);
            setValueBrowserStorage(Candidate_Track_Id, trackInfo?.candidateTrackId ?? res.payload?.output?.lastCandidateSavedSetting?.lastCandidateTrackIDWorkedOn);
            props.setTrackInfoForPayment({
                trackId: trackInfo?.trackId ?? res.payload.output.lastCandidateSavedSetting.trackId,
                trackPlan: trackInfo?.displayPlanName ?? res.payload.output.lastCandidateSavedSetting.displayPlanName,
                trackName: trackInfo?.title ?? trackInfo?.trackTitle ?? res.payload.output.lastCandidateSavedSetting.lastCandidateTrackWorkedOn,
                planState: trackInfo?.planState ?? res.payload.output.lastCandidateSavedSetting.planState
            });
            history.replace(`/onboard-candidate${search}`);
        } else {
            enqueueSnackbar(res.error?.message, { variant: 'error', autoHideDuration: 2500 });
        }
    }

    const handleLoginSuccess = (value: LoginDataType) => {
        const payload = {
            token: DEFAULT_TOKEN,
            email: value.email,
            marketId: getMarketIdAsPerDomain(marketList),
            socialMediaAuthenticated: false,
            password: value.password,
            trackId: _queryParams.lptrackid,
        };
        props
            .login(payload)
            .then((res: any) => {
                if (!res.error) {
                    if (!res?.payload.output?.candidateTrackDetails?.candidateTrackId) {
                        setValueBrowserStorage(Candidate_Id, res.payload.output.candidateId);
                        setValueBrowserStorage(Authorization_Token, res.payload.output.authorizationToken);
                        handleLandingPageSignupSuccess({
                            props,
                            loader,
                            startCheckout,
                            enqueueSnackbar,
                            continueCheckout,
                            fallbackFunction: (trackInfo: any) => {
                                onLoginSuccess(res, trackInfo, true);
                            },
                        });
                    } else {
                        onLoginSuccess(res, res?.payload.output?.candidateTrackDetails?.candidateTrackId, true);
                    }
                } else {
                    enqueueSnackbar(res.error.message, { variant: 'error', autoHideDuration: 2500 });
                }
            })
            .catch((err: any) => {
                enqueueSnackbar(err?.message, {
                    variant: 'error',
                    autoHideDuration: 2500,
                });
            });
    }

    const handleSignUpSuccess = (value: SignUpDataType) => {
        const payload = {
            token: '123',
            marketId: getMarketIdAsPerDomain(marketList ?? []),
            fullname: value.name,
            email: value.email,
            password: value.password,
        }
        setLoading(true);
        evaluationPlatformService.createNewCandidateForMarket(payload)
            .then(async (res: any) => {
                if (res.apiStatus === 'SUCCESS') {
                    enqueueSnackbar('Successfully created!', { variant: 'success', autoHideDuration: 2500 });
                    setValueBrowserStorage(Candidate_Id, res.output.candidateId);
                    setValueBrowserStorage(Authorization_Token, res.output.authorizationToken);
                    setValueBrowserStorage(Market_Name, res?.output?.marketName);
                    setValueBrowserStorage(Market_Logo, res?.output?.marketLogo);
                    if (_queryParams.lptrackid) {
                        handleLandingPageSignupSuccess({
                            props,
                            loader,
                            startCheckout,
                            enqueueSnackbar,
                            continueCheckout,
                            fallbackFunction: () => history.replace(`/onboard-candidate${search}`),
                        });
                    }
                } else {
                    enqueueSnackbar(res.error?.message, { variant: 'error', autoHideDuration: 2500 });
                }
            }).catch((err: any) => {
                enqueueSnackbar(err.message, { variant: 'error', autoHideDuration: 2500 });
            }).finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (marketList.length === 0) {
            setLoading(true);
            evaluationPlatformService.getMarkets().then((res) => {
                setMarketList(res.output);
            }).finally(() => setLoading(false))
        }
    }, [])

    useEffect(() => {
        if (_queryParams?.lpflowtype !== FLOW_TYPE.placement) {
            const queryParams = search;
            history.push(`/login${queryParams}`);
        }
    }, [])

    return (
        <StyledWrapper>
            <OverlayLoader loading={loading} disableOverlay={false} />
            <div className="partner-info-container text-left">
                <div>
                    <div className="mb-4">
                        <Logo
                            className="ml-2"
                            logoImage={WhiteInterviewHelpLogoIcon}
                            imageSize="35px"
                            logoText={"InterviewHelp Placement Partner"}
                            fontWeight="bold"
                            textSize="20px"
                            color={'white'}
                        />
                    </div>
                    <h1 className="mb-4 text-white">Your co-pilot<br /> to landing your <br /> dream job</h1>
                    <h4 className="text-white">Jumpstart your career with our <br /> streamlined hiring process.</h4>
                </div>
            </div>
            <div className="inputs-container">
                <div>
                    <Tabs
                        value={authMode}
                        onChange={(e, value) => handleModeChange(value)}
                        textColor="primary"
                        indicatorColor="primary"
                        className="mb-4"
                    >
                        <Tab value="Signup" label="SIGN UP" />
                        <Tab value="Login" label="SIGN IN" />
                    </Tabs>
                    {authMode === "Signup" ? <SignUpForm
                        handleAuthSuccess={handleSignUpSuccess}
                    /> : <LoginForm
                        handleAuthSuccess={handleLoginSuccess}
                    />}
                </div>
            </div>
        </StyledWrapper>
    )
}

const SignUpSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm password is required'),
});

const SignUpForm = (props: {
    handleAuthSuccess: (value: SignUpDataType) => void;
}) => {

    const handleSubmit = (value: SignUpDataType) => {
        props.handleAuthSuccess(value);
    }

    return (
        <Formik
            initialValues={{ name: '', email: '', password: '', confirmPassword: '' }}
            validationSchema={SignUpSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, errors, submitCount }) => (
                <Form className="text-left w-100">
                    <div className="mb-4">
                        <label>Name</label>
                        <InputBase
                            placeholder="Enter job profile"
                            name='name'
                            className="input-element"
                            value={values.name}
                            onChange={(e) => setFieldValue('name', e.target.value)}
                        />
                        {errors.name && submitCount > 0 && <span className="text-danger error-font">{errors.name}</span>}
                    </div>
                    <div className="mb-4">
                        <label>Email</label>
                        <InputBase
                            placeholder="Enter job profile"
                            name='email'
                            className="input-element"
                            value={values.email}
                            onChange={(e) => setFieldValue('email', e.target.value)}
                        />
                        {errors.email && submitCount > 0 && <span className="text-danger error-font">{errors.email}</span>}
                    </div>
                    <div className="mb-4">
                        <label>Password</label>
                        <InputBase
                            placeholder="Enter job profile"
                            name='password'
                            className="input-element"
                            value={values.password}
                            onChange={(e) => setFieldValue('password', e.target.value)}
                            type="password"
                        />
                        {errors.password && submitCount > 0 && <span className="text-danger error-font">{errors.password}</span>}
                    </div>
                    <div className="mb-4">
                        <label>Confirm Password</label>
                        <InputBase
                            placeholder="Enter job profile"
                            name='confirmPassword'
                            className="input-element"
                            value={values.confirmPassword}
                            onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
                            type="password"
                        />
                        {errors.confirmPassword && submitCount > 0 && <span className="text-danger error-font">{errors.confirmPassword}</span>}
                    </div>

                    <Button variant="contained" type="submit" color="primary">Create Account</Button>
                </Form>
            )}
        </Formik>
    )
}

const LoginSchema = Yup.object({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
});
const LoginForm = (props: {
    handleAuthSuccess: (value: LoginDataType) => void;
}) => {

    const handleSubmit = (value: LoginDataType) => {
        props.handleAuthSuccess(value);
    }

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={LoginSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, errors, submitCount }) => (
                <Form className="text-left w-100">
                    <div className="mb-4">
                        <label>Email</label>
                        <InputBase
                            placeholder="Enter job profile"
                            name='email'
                            className="input-element"
                            value={values.email}
                            onChange={(e) => setFieldValue('email', e.target.value)}
                        />
                        {errors.email && submitCount > 0 && <span className="text-danger error-font">{errors.email}</span>}
                    </div>
                    <div className="mb-4">
                        <label>Password</label>
                        <InputBase
                            placeholder="Enter job profile"
                            name='password'
                            className="input-element"
                            value={values.password}
                            onChange={(e) => setFieldValue('password', e.target.value)}
                            type="password"
                        />
                        {errors.password && submitCount > 0 && <span className="text-danger error-font">{errors.password}</span>}
                    </div>

                    <Button variant="contained" type="submit" color="primary">Sign In</Button>
                </Form>
            )}
        </Formik>
    )
}

const mapDispatchToProps = {
    login,
    createNewCandidateForMarketAction,
    createCandidateTrackForCandidate,
    setTrackInfoForPayment
};

const mapStateToProps = (state: any) => ({
});

const AuthPage = connect(mapStateToProps, mapDispatchToProps)(_AuthPage);

export default AuthPage;