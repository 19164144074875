import { Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import { ChevronDown, ChevronUp } from "@styled-icons/boxicons-regular";
import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { HintsAndSampleSolutionService } from 'services/hintsAndSampleSolutions';
import { DEFAULT_TOKEN } from 'utilities/constants';
import { ICapEvaluation } from '../types';


const accordionStyle = {
    backgroundColor: "#f8f8f8",
    boxShadow: "none"
};

interface IQuestionEvaluation {
    questionId: string;
}

const QuestionEvaluation = ({ questionId }: IQuestionEvaluation) => {
    const [capEvaluations, setCapEvaluations] = useState<ICapEvaluation[]>([]);
    const [expandedEvals, setExpandedEvals] = useState<boolean[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getPayload = () => {
        const trackId = getValueBrowserStorage('candidateTrackId');
        if (trackId && questionId) {
            return {
                token: DEFAULT_TOKEN,
                candidateTrackId: trackId,
                questionId: questionId
            }
        }
        else return null;
    }

    const showEvaluaions = () => {
        const payload = getPayload();
        if (payload) {
            setIsLoading(true);
            HintsAndSampleSolutionService.getEvaluations(payload)
                .then(res => {
                    const capEval = res.output?.capabilities.filter((cEval: any) => cEval.evaluations.length > 0) || [];
                    const initialExpandedState = Array(res.output.length).fill(false);
                    initialExpandedState[0] = true;
                    setExpandedEvals(initialExpandedState);
                    setCapEvaluations(capEval);
                }).catch((error: any) => console.log(error)).finally(() => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        showEvaluaions();
    }, [])

    const handleExpandCollapse = (index: number) => {
        setExpandedEvals((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            newExpanded[index] = !newExpanded[index];
            return newExpanded;
        });
    };

    return <>
        {isLoading && <Spinner animation='border' />}
        {capEvaluations.map((evaluation, index) => (
            <Box margin={2} key={index}>
                <Accordion style={accordionStyle} expanded={expandedEvals[index]} onChange={() => handleExpandCollapse(index)}>
                    <AccordionSummary
                        expandIcon={
                            expandedEvals[index] ? (
                                <ChevronUp size={20} />
                            ) : (
                                <ChevronDown size={20} />
                            )
                        }
                        aria-controls={`panel${index + 1}a-content`}
                        id={`panel${index + 1}a-header`}
                    >
                        <Typography variant="h6">{evaluation?.capabilityText}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Row className={'mb-2'}>
                            <Col style={{ letterSpacing: '0.32px' }} className={'h6 small'}>
                                <ul className='evals'>
                                    {evaluation?.evaluations.map((el: any) => el.evalText && <li key={el.evalText}>{el.evalText}</li>)}
                                </ul>
                            </Col>
                        </Row>
                    </AccordionDetails>
                </Accordion>
            </Box>
        ))}
    </>
}

export default QuestionEvaluation;