import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent } from "ag-grid-community";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { RowModel } from 'cp-react-tree-table';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getValueBrowserStorage } from 'services/browserStorageService';
import { evaluationPlatformService } from 'services/evaluationPlatform';
import { saveCandidateLastActivity } from 'store/evaluationPlatform';
import styled from 'styled-components';
import { ITODOLIST } from 'types';
import { IStatusScore } from 'types/ScorePage';
import { Candidate_Id } from 'utilities/constants';
import { Score } from "./Score";
import _ from 'lodash'


const GridContiner = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    .toolbar {
        flex: 2;
    }
    .duration{
        margin-right: 0.5rem;
    }
`;

const Durationp = styled.div`
position: absolute;
top: 42px;
width: 100%;
`

class DatePicker {
    private eInput: HTMLInputElement | undefined;
    init(params: { value: any; }) {
        // create the cell
        this.eInput = document.createElement('input');
        this.eInput.value = params.value;
        this.eInput.classList.add('ag-input');
        this.eInput.style.height = '100%';
        this.eInput.type = 'date';
        this.eInput.select = () => {
            this.eInput?.focus()
        };

    }
    getGui() {
        return this.eInput;
    }
    afterGuiAttached() {
        // @ts-ignore
        this.eInput.focus();
        // @ts-ignore
        this.eInput.select();
    }

    getValue() {
        // @ts-ignore
        return this.eInput.value;
    }

    destroy() {
    }

    isPopup() {
        return false;
    }
}

interface IScorePageProps {
    data: {
        statusScore: IStatusScore;
        setCapability: Function;
        setQuestionId: Function;
    }
}

export const CustomPlan = (props: IScorePageProps) => {
    const status = ['', 'In Process', 'Done'];
    const dispatch = useDispatch();
    const history = useHistory();
    const gridApi: any = useRef();
    let columnApi: ColumnApi;
    const candidateTrackId = getValueBrowserStorage('candidateTrackId');
    const candidateId = getValueBrowserStorage(Candidate_Id);
    const [rowData, setRowData] = useState<any>([]);
    const [selectRow, setSelectRow] = useState<any>("");
    const [isDuration, setIsDuration] = useState<any>(false);
    const [isModalEdit, setIsModalEdit] = useState<any>(false);
    const gridRef: any = useRef();

    function isValidHttpUrl(string: any) {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    };

    const onBtNFocusedCell = useCallback(() => {
        gridRef.current.api.clearFocusedCell();
    }, []);

    const taskCellRender = (props?: any) => {
        if (isValidHttpUrl(props?.value)) {
            return <a title={props?.value} href={props?.value} target="_blank">{props?.value}</a>
        } else if (props?.data.capabilityId) {
            return <a title={props?.value} onClick={(e) => handleNavigation(e, props?.data.capabilityId, props?.data.field)} href={props?.value}>{props?.data.field?.charAt(0).toUpperCase() + props?.data.field?.slice(1)} : {props?.value}</a>
        } else {
            return <>{props?.value}</>
        }
    }

    const [columnDefs] = useState<(ColDef | ColGroupDef)[]>([
        {
            headerName: "Sequence", field: 'sequence', editable: true, rowDrag: true, resizable: true,
            cellClass: params => {
                if (_.isString(params?.value) && params?.value?.toLocaleLowerCase().includes("week")) {
                    return "Fontsize";
                } else {
                    return "textalign";
                }
            }
        },  // rowGroup: true, hide: true, enableRowGroup: true
        {
            headerName: "Task", field: 'task', editable: true, cellStyle: { 'textOverflow': 'ellipsis', 'whiteSpace': 'nowrap' },
            resizable: true,
            cellRenderer: taskCellRender,
        },
        {
            headerName: "Duration", field: 'duration', editable: true, resizable: true,
            cellClass: params => {
                if (_.isString(params?.data?.sequence) && params?.data?.sequence?.toLocaleLowerCase().includes("week")) {
                    return "Fontsize";
                } else {
                    return null;
                }
            }
        },
        {
            headerName: "Start Date", field: 'startDate', editable: true, resizable: true, cellEditor: DatePicker, cellEditorPopup: true,
            cellClass: params => {
                if (_.isString(params?.data?.sequence) && params?.data?.sequence?.toLocaleLowerCase().includes("week")) {
                    return "Fontsize";
                } else {
                    return null;
                }
            }
        },
        {
            headerName: "End Date", field: 'endDate', editable: true, resizable: true, cellEditor: DatePicker, cellEditorPopup: true,
            cellClass: params => {
                if (_.isString(params?.data?.sequence) && params?.data?.sequence?.toLocaleLowerCase().includes("week")) {
                    return "Fontsize";
                } else {
                    return null;
                }
            }
        },
        {
            headerName: "Status", field: 'status', editable: true, resizable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: { values: status },
            cellClass: params => {
                if (_.isString(params?.data?.sequence) && params?.data?.sequence?.toLocaleLowerCase().includes("week")) {
                    return "Fontsize";
                } else {
                    return null;
                }
            }
        },
        {
            headerName: "Remarks(If any)", field: 'remarks', editable: true, resizable: true,
            cellClass: params => {
                if (_.isString(params?.data?.sequence) && params?.data?.sequence?.toLocaleLowerCase().includes("week")) {
                    return "Fontsize";
                } else {
                    return null;
                }
            }
        },
    ]);

    const getTodoList = async (candidateTrackId: string) => {
        const input: ITODOLIST = {
            candidateTrackId,
            toDoListType: "CANDIDATE_TO_DO_LIST"
        }
        try {
            const output = await evaluationPlatformService.getToDoList(input);
            setRowData(output.output.candidateToDoList);
        } catch (e: any) {
            console.log('no todo list found for the candidate')
        }
    };

    const handleNavigation = (e: any, row: any, field: any) => {
        e.preventDefault();
        props.data.setCapability(row);
        if (field === 'questionTitle') {
            props.data.setQuestionId(row)
        }

        dispatch(saveCandidateLastActivity({
            selectedCapabilityId: row,
            currentQuestionId: field === 'questionTitle' ? row : ''
        }))
        history.push('/question')
    };

    const addRow = () => {
        if (gridApi?.current) {
            const gridData = (gridApi?.current as any)?.getRenderedNodes();
            const selectedRow = (gridApi?.current as any)?.getSelectedNodes().reduce((acc: any, cur: any) => {
                return acc?.rowIndex > cur.rowIndex ? acc : cur;
            }, { rowIndex: -1 });

            (gridApi?.current as any)?.applyTransaction({
                add: [{ sequenceNo: (gridData.length + 1).toString() }],
                addIndex: selectedRow?.rowIndex > -1 ? selectedRow?.rowIndex + 1 : gridData.length,
            });
        }
    };

    const removeRow = () => {
        if (gridApi) {
            const selectedNotes = getSelectedRowData();
            if (selectedNotes) {
                (gridApi?.current as any)?.applyTransaction({
                    remove: selectedNotes
                });
            }
        }
    };

    const getSelectedRowData = () => {
        if (gridApi) {
            let selectedNodes = (gridApi?.current as any)?.getSelectedNodes();
            let selectedData = selectedNodes.map((node: any) => node.data);
            return selectedData;
        }
    };


    const cellClickedListener = useCallback((e) => {
        setSelectRow(e.data);
    }, []);

    const showDuration = () => {
        onBtNFocusedCell()
        setIsDuration(true)
    };

    const handleGetLink = (value: any, row: RowModel, field: string, allData: any) => {
        setIsModalEdit(true)
        let apiUpdate: any[] = [];
        let mainValue = "";
        gridApi?.current?.forEachNodeAfterFilterAndSort(function (rowNode: any, index: any) {
            if (rowNode.data.sequenceNo !== selectRow.sequenceNo) {
                apiUpdate.push(rowNode.data);
                return;
            }
            allData.map((cateData: any) => {
                cateData.subCategories.map((subData: any) => {
                    subData.capabilities.map((CapData: any) => {
                        CapData.questions.map((que: any) => {
                            if (cateData?.categoryTitle == value) {
                                mainValue = cateData?.subCategories[0]?.capabilities[0]?.capabilityId;
                            } else if (subData?.subCategoryTitle == value) {
                                mainValue = cateData?.subCategories[0]?.capabilities[0]?.capabilityId;
                            } else if (CapData?.capabilityTitle == value) {
                                mainValue = CapData?.capabilityId;
                            } else if (que?.title == value) {
                                mainValue = cateData?.subCategories[0]?.capabilities[0]?.capabilityId;
                            }
                        })
                    })
                })
            })
            var data = rowNode.data;
            data.task = value;
            data.capabilityId = mainValue;
            data.field = field;
            apiUpdate.push(data);
        });

        (gridApi?.current as any)?.applyTransaction({
            update: apiUpdate,
        });
        setIsDuration(false);
    };

    const onCellValueChanged = (event: any) => {
        setIsModalEdit(true)
        const sequanceNumber = event.node.childIndex;
        var rowNode = gridApi.current.getRowNode(sequanceNumber);
        if (event.colDef.headerName == "Task") {
            delete rowNode?.data?.capabilityId;
            const data = rowNode?.data;
            rowNode.setData(data);
        }
    }

    const renderTable = useMemo(() => {
        const onGridReady = (params: GridReadyEvent) => {
            if (gridApi)
                gridApi.current = params.api as any;
            columnApi = params.columnApi;
            params.api.sizeColumnsToFit();
        };
        return <AgGridReact
            ref={gridRef}
            onCellClicked={cellClickedListener}
            headerHeight={40}
            rowHeight={40}
            onGridReady={onGridReady}
            rowData={rowData}
            rowGroupPanelShow='always'
            rowSelection="multiple"
            columnDefs={columnDefs}
            enableRangeSelection={true}
            rowDragManaged={true}
            suppressMoveWhenRowDragging={true}
            animateRows={true}
            onCellValueChanged={onCellValueChanged}
        >
        </AgGridReact>
    }, [rowData, columnDefs]);

    const saveData = async () => {
        if (candidateTrackId && gridApi && candidateId) {
            const gridData = (gridApi?.current as any)?.getRenderedNodes();
            const input = {
                candidateTrackId,
                updatedBy: "CANDIDATE",
                updateById: candidateId,
                data: gridData?.map((d: any) => d?.data),
                toDoListType: "CANDIDATE_TO_DO_LIST",
            }
            await evaluationPlatformService.setToDoList(input);
        }
        setIsModalEdit(false);
    };

    useEffect(() => {
        if (candidateTrackId) {
            getTodoList(candidateTrackId);
        }
    }, [candidateTrackId]);

    useEffect(() => {
        return () => {
            if (history.location.pathname !== "/dashboard" && isModalEdit) {
                saveData();
            }
        }
    }, [isModalEdit]);

    return (
        <>
            {
                <GridContiner className="ag-theme-alpine">
                    <Col xs={12} className="toolbar py-2 d-flex justify-content-end">
                        <div>
                            {
                                selectRow &&
                                <Button variant="success" className="duration" onClick={showDuration}>{'+ Add Task'}</Button>
                            }
                            <Button variant="success" onClick={addRow}>{'+ Add'}</Button>
                            <Button variant="danger" className="ml-2" onClick={removeRow}>{'- Remove'}</Button>
                        </div>
                        <Button variant="primary" className="ml-2" onClick={saveData}>{'Save'}</Button>
                    </Col>
                    <Col xs={12} style={{ textAlign: 'left' }}>
                        {renderTable}
                        <Durationp>
                            {
                                isDuration &&
                                <Modal
                                    className='add-task-modal'
                                    show={isDuration}
                                    onHide={() => setIsDuration(false)}
                                    centered
                                >
                                    <Modal.Header closeButton>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Score
                                            setQuestionId={props.data.setQuestionId}
                                            setCapability={props.data.setCapability}
                                            statusScore={props.data.statusScore}
                                            handleonClick={handleGetLink} />
                                    </Modal.Body>
                                </Modal>
                            }
                        </Durationp>
                    </Col>
                </GridContiner>
            }
        </>
    )
}
